const BASE_URL = process.env.REACT_APP_API_V2_URL;

export const ORDERS_V1 = BASE_URL + '/v1/orders';
export const PRODUCTS_V1 = BASE_URL + 'v1/products';
export const UNIVERSAL_BARCODE = PRODUCTS_V1 + '/universal-barcodes';
export const BULK_PRODUCT_V1 = PRODUCTS_V1 + '/bulk'
export const PRODUCTS_V1_1 = BASE_URL + 'v1.1/products';
export const BULK_PRODUCT_V1_1 = PRODUCTS_V1_1 + '/bulk';
export const AUTH_V1 = BASE_URL + '/v1/auth'
export const PROFILE_V1 = BASE_URL + '/v1/auth/profile';
export const PRODUCT_CATEGORIES_V1 = BASE_URL + '/v1/products/categories';
export const PRODUCT_CONVERSION_V1 = BASE_URL + '/v1/products/product-conversion';
export const PRODUCT_BY_SKU_V1 = PRODUCTS_V1 + '/seo-page';
export const PRODUCT_SEO_RESET = (params) => PRODUCT_BY_SKU_V1 + '/reset/' + params;

export const PRODUCTS_BUNDLES_V1 = BASE_URL + 'v1/product-bundle';
export const PRODUCTS_BUNDLES_BY_FILTER = PRODUCTS_BUNDLES_V1 + '/filter';
export const DOWNLOAD_PRODUCTS_BUNDLES_BY_FILTER = PRODUCTS_BUNDLES_V1 + '/download';

export const ORDERS_V1_1 = BASE_URL + '/v1.1/orders';
export const ORDER_BY_ORDER_ID_V1_1 = (orderId) => `${ORDERS_V1_1}/${orderId}`;

export const ORDER_BULK_UPLOAD_FAILED_DELIVERY = ORDERS_V1 + '/bulk-upload-failed-delivery'

export const ORDERS_COUNT_V1 = ORDERS_V1 + '/count';
export const ORDER_MARK_AS_READ_V1 = ORDERS_V1 + '/mark-as-read';
export const ORDER_COVERAGE = ORDERS_V1 + '/coverage';
export const ORDER_DELIVERY_DATE_OPTIONS = (agentUniqueLink) => ORDERS_V1 + `/delivery-date/${agentUniqueLink}/options`;
export const ORDER_SAME_DAY_DELIVERY_VARIANT = ORDERS_V1 + '/same-day-delivery-variant';

const ORDER_PRODUCT_SCANS_V1 = BASE_URL + 'v1/order-product-scans';
const ORDER_PRODUCT_SCANS_V2 = BASE_URL + 'v2/order-product-scans';
export const ORDER_PRODUCT_SCANS_SUMMARY_V2 = ORDER_PRODUCT_SCANS_V2 + '/summary';
export const ORDER_PRODUCT_SCANS_SUMMARY_OVERVIEW_V2 = ORDER_PRODUCT_SCANS_V2 + '/summary-overview';
export const ORDER_PRODUCT_SCANS_SUMMARY_V2_DETAIL = ({ id }) => ORDER_PRODUCT_SCANS_SUMMARY_V2 + `/${id}`;
export const ORDER_PRODUCT_SCANS_WORKER = ORDER_PRODUCT_SCANS_V1 + '/workers';
export const ORDER_PRODUCT_SCANS_LOGS = ORDER_PRODUCT_SCANS_V1 + '/logs';
export const ORDER_PRODUCT_SCANS_LOG = (filename) => ORDER_PRODUCT_SCANS_V1 + '/log/' + filename;

export const RECIPE_V1 = BASE_URL + 'v1/recipes';
export const RECIPE_V1_ID = (id) => RECIPE_V1 + '/' + id;
export const RECIPE_V1_DELETE = (id) => RECIPE_V1 + `/delete/${id}`;
export const RECIPE_ITEM_PRODUCT_V1_GET = (id) => RECIPE_V1 + `/${id}/itemsWithNoPrice`;
export const RECIPE_STEPS_V1 = RECIPE_V1 + '/recipe-steps';
export const RECIPE_MEDIAS_V1_GET = (id) => RECIPE_V1 + `/${id}/medias`;
export const RECIPE_STEPS_V1_ID = (id) => RECIPE_STEPS_V1 + `/${id}`;
export const RECIPE_V1_CURATED = RECIPE_V1 + '/curated';
export const RECIPE_V1_DETAIL = RECIPE_V1 + '/detail';
export const RECIPE_V1_SUGGESTIONS = RECIPE_V1 + '/suggestions';
export const RECIPE_V1_SUGGESTIONS_DOWNLOAD = RECIPE_V1_SUGGESTIONS + '/download';
export const RECIPE_STEPS_BULK_ADD_V1 = RECIPE_STEPS_V1 + '/bulk-add-upload';

export const RECIPE_TAG_V1 = BASE_URL + 'v1/recipe-tags';
export const RECIPE_TAG_V1_PAGINATION = RECIPE_TAG_V1 + '/pagination';
export const RECIPE_TAG_V1_ID = (id) => RECIPE_TAG_V1 + `/${id}`;
export const RECIPE_TAG_V1_DELETE = (id) => RECIPE_TAG_V1 + `/${id}/delete`;
export const RECIPE_TAG_BULK_ADD_V1 = RECIPE_TAG_V1 + '/bulk-add-upload';

export const LANDING_PAGE_V1 = BASE_URL + 'v1/landing-page';
export const LANDING_PAGE_V1_ID_NAME = LANDING_PAGE_V1 + '/id-name';

export const AGENT_COMMISSIONS_V1 = BASE_URL + 'v1/agent-commissions';
export const AGENT_COMMISSION_GROUP_V1 = AGENT_COMMISSIONS_V1 + '/commission-groups';
export const AGENT_COMMISSION_GROUP_UPDATE = AGENT_COMMISSION_GROUP_V1 + '/update';
export const AGENT_SALES_SUMMARY_V1 = BASE_URL + 'v1/agents/sales-summary'

export const AGENT_PRICE_GROUPS_V1 = BASE_URL + '/v1/agent-price-groups';
export const AGENT_PRICE_GROUPS_V1_ID = (id) => BASE_URL + '/v1/agent-price-groups' + `/${id}`;

export const AGENT_DETAIL_COMMISSIONS = ({ id }) => BASE_URL + 'v1/agents/' + id + '/commissions';
export const AGENT_COMMISSION_DAILY_RECOMMENDATION_V1 = AGENT_COMMISSIONS_V1 + '/daily-recommendations';

export const GET_AGENTS_V1 = BASE_URL + 'v1/agents';
export const GET_AGENTS_PUBLIC_V1 = BASE_URL + 'v1/agents/public';
export const CREATE_AGENT_V1 = BASE_URL + 'v1/agents';
export const UPDATE_AGENT_V1 = ({ id }) => BASE_URL + 'v1/agents/' + id;

export const AGENT_DROPDOWN_V1 = GET_AGENTS_V1 + '/dropdown';

export const AGENT_TYPES_V1 = BASE_URL + 'v1/agent-types';
export const AGENT_SALES_PICS_V1 = GET_AGENTS_V1 + '/agent-sales-pics';
export const AGENT_SUMMARY_INVOICE_V1 = BASE_URL + '/v1/agent-summary-invoice-notes';

export const ORDER_DETAIL_V1 = ({ id }) => BASE_URL + 'v1/orders/' + id;


export const ORDER_DETAIL_V1_EXPANDED = ({ id }) => ORDER_DETAIL_V1({ id }) + '/details';

export const ORDER_DETAIL_V1_CUSTOMER_REFRESH_PAYMENT = ({ orderId }) => BASE_URL + 'v1/orders/customer/' + orderId + '/refresh-payment';

export const ORDER_DETAIL_V1_NOTIFY_ORDER_RESCUE = ({ id }) => ORDER_DETAIL_V1({ id }) + '/notify-order-rescue'

export const ORDER_SIBLINGS_V1 = ({ id }) => ORDER_DETAIL_V1({ id }) + '/siblings';

export const ORDER_PRODUCTS_V1 = ({ id }) => ORDER_DETAIL_V1({ id }) + '/order-products';

export const ORDER_PRODUCT_V1 = BASE_URL + 'v1/order-products';

export const ORDER_PRODUCT_BULK_ADD_V1 = BASE_URL + 'v1/order-products/bulk-add';

export const ORDER_PRODUCT_BULK_DELETE_V1 = BASE_URL + 'v1/order-products/bulk-delete';

export const ORDER_PRODUCT_BULK_ADD_UPLOAD_V1 = BASE_URL + 'v1/order-products/bulk-add-upload';

export const DELETE_ORDER_PRODUCTS_V1 = ({ id }) => BASE_URL + 'v1/order-products/' + id;

export const ORDER_PRODUCT_GET_ALL_REFUND_REASON_OPTIONS = ORDER_PRODUCT_V1 + '/refund-reason-options';

export const ORDER_REFUND_V1 = ({ id }) => ORDER_DETAIL_V1({ id }) + '/refund';

export const ORDER_RESCUE_V1 = ({ id }) => ORDER_DETAIL_V1({ id }) + '/rescue';

export const ORDER_MARK_REFUND_V1 = ({ id }) => ORDER_DETAIL_V1({ id }) + '/mark-refund';

export const ORDER_CHANGE_DELIVERY_SERVICE_TYPE_V1 = ({ id }) => ORDER_DETAIL_V1({ id }) + '/change-delivery-service-type';

export const ORDER_CANCEL_UNPAID_V1 = ORDERS_V1 + '/cancel-unpaid-orders';

export const ORDER_PRODUCT_V1_ID = (id) => BASE_URL + 'v1/order-products' + `/${id}`;

export const ORDER_NOTIFY_OUT_OF_STOCK_V1 = ORDERS_V1 + '/notify-out-of-stock';
export const ORDER_NOTIFY_OUT_OF_STOCK_V2 = ORDERS_V1 + '/notify-out-of-stock-v2';

export const ORDER_FETCH_MANUAL_OUT_OF_STOCK_V1 = ({ id }) => ORDER_DETAIL_V1({ id }) + '/fetch-manual-out-of-stock';
export const ORDER_FETCH_AUTO_OUT_OF_STOCK_V1 = ORDERS_V1 + '/fetch-auto-out-of-stock';
export const ORDER_FETCH_ORDER_EXISTING_REFUND_V1 = ORDERS_V1 + '/fetch-order-existing-refund';

export const ORDER_CONFIRM_AUTO_OUT_OF_STOCK_V1 = ORDERS_V1 + '/confirm-auto-out-of-stock';

export const ORDER_UPDATE_CX_VERIFIED_V1 = ORDERS_V1 + '/update-cx-verified';
export const ORDER_UPDATE_CX_VERIFIED_CSV_V1 = ORDER_UPDATE_CX_VERIFIED_V1 + '/csv';

export const ORDER_UPDATE_SPECIAL_TREATMENT = ({ id }) => BASE_URL + 'v1/orders/' + id + '/special-treatment';

export const ORDER_BULK_REFUND = ORDERS_V1 + '/bulk-refund';

// External Order
export const EXTERNAL_CHANNEL_MANUAL_ORDER = ORDERS_V1 + '/external-channel-manual';

export const PRODUCT_PRICES_V_1 = PRODUCTS_V1_1 + '/price';
export const PRODUCT_WITH_PURCHASING_PRODUCT = PRODUCTS_V1 + '/with-purchasing-product';
export const PRODUCT_DROPDOWN_V1 = PRODUCTS_V1 + '/dropdown';
export const PRODUCT_DROPDOWN_V1_1 = PRODUCTS_V1_1 + '/dropdown';
export const PRODUCT_BY_SKU = (sku) => PRODUCTS_V1 + `/by/sku/${sku}`;
export const PRODUCT_LIST_ITOOLS = PRODUCTS_V1 + '/itools';
export const PRODUCT_ORDER_LIMITS_V1 = BASE_URL + '/v1/product-order-limits';
export const PRODUCT_ORDER_LIMITS_V1_ID = ({ id }) => PRODUCT_ORDER_LIMITS_V1 + `/${id}`;
export const PRODUCT_ORDER_LIMIT_USAGE_V1 = (id) => PRODUCT_ORDER_LIMITS_V1 + `/usage/${id}`;
export const PRODUCT_ORDER_LIMIT_OOS_OVERRIDE_V1 = (id) => PRODUCT_ORDER_LIMITS_V1 + `/${id}/oos_override`;
export const PRODUCT_ORDER_LIMITS_BULK_OOS_OVERRIDE_V1 = `${PRODUCT_ORDER_LIMITS_V1}/oos-override`;
export const PRODUCT_ORDER_LIMITS_BULK_DELETE_V1 = `${PRODUCT_ORDER_LIMITS_V1}/delete`;
export const PRODUCT_ORDER_LIMITS_BULK_CREATE_UPDATE_DELETE_PREVIEW = `${PRODUCT_ORDER_LIMITS_V1}/bulk-preview`;
export const PRODUCT_ORDER_LIMITS_BULK_CREATE_UPDATE_DELETE = `${PRODUCT_ORDER_LIMITS_V1}/bulk`;
export const PRODUCT_ORDER_LIMIT_REMOVABLES_V1 = `${PRODUCT_ORDER_LIMITS_V1}/removable`;
export const PRODUCT_ORDER_LIMIT_WAREHOUSE_TRANSFER_V1 = `${PRODUCT_ORDER_LIMITS_V1}/warehouse-transfer`;
export const PRODUCT_BRANDS = PRODUCTS_V1_1 + '/brands';
export const EXTERNAL_CHANNEL_PRODUCTS = (externalChannelId) => `${PRODUCTS_V1_1}/info-and-price/${externalChannelId}`;

// Product Labels
export const PROMO_QUOTA_V1 = BASE_URL + 'v1/promo-quotas';
export const PROMO_QUOTA_V1_ID = (id) => PROMO_QUOTA_V1 + `/${id}`;
export const PROMO_QUOTA_V1_PRODUCT_ID = (id) => PROMO_QUOTA_V1 + `/product/${id}`;
export const PROMO_QUOTA_V1_DELETE_TYPE_LEVEL = PROMO_QUOTA_V1 + '/delete-type-level';
export const PROMO_QUOTA_V1_BULK_UPLOAD = PROMO_QUOTA_V1 + '/bulk-upload';
export const PROMO_QUOTA_V1_UPDATE_BUY_MORE_SETTING = PROMO_QUOTA_V1 + '/update-buy-more-setting'

export const PRODUCT_KEYWORDS_V1 = BASE_URL + '/v1/product-keywords';
export const PRODUCT_KEYWORD_LOGS_V1 = PRODUCT_KEYWORDS_V1 + '/log'
export const PRODUCT_KEYWORDS_V1_KEYWORD_ID = ({ id }) => PRODUCT_KEYWORDS_V1 + `/keyword/${id}`;
export const PRODUCT_KEYWORDS_V1_BULK_CREATE = PRODUCT_KEYWORDS_V1 + '/bulk-create';
export const PRODUCT_KEYWORDS_V1_BULK_DELETE = ({ keyword }) => PRODUCT_KEYWORDS_V1 + `/bulk-delete/${keyword}`

export const AGENT_PRICE_GROUP_ID_PRODUCTS = ({ id }) => BASE_URL + '/v1/agent-price-groups/' + id +
    '/agent-price-group-products';

export const USERS_V1 = BASE_URL + '/v1/users';
export const USER_SDD_COURIER_FEE_RULE_CONFIG_V1 = USERS_V1 + '/fee-rules-config-users';
export const ROLES_V1 = BASE_URL + '/v1/roles';
export const USERS_PROFILE = USERS_V1 + '/profile';
export const ROLES_V1_ID = ({ id }) => ROLES_V1 + `/${id}`;
export const CREATE_ROLES_V1 = ROLES_V1 + '/create';
export const DELETE_ROLES_V1 = ({ roleId }) => ROLES_V1 + `/${roleId}`;
export const ROLE_BY_DIRECTORIES_V1 = ROLES_V1 + '/by-directories';
export const ROLE_DIRECTORIES_V1 = ROLES_V1 + '/directories';

export const RESOURCES_V1 = BASE_URL + 'v1/resources';
export const SCOPES_V1 = BASE_URL + 'v1/scopes';
export const DEACTIVATE_USER = (targetUserId) => USERS_V1 + `/deactivate/${targetUserId}`;
export const ACTIVATE_USER = (targetUserId) => USERS_V1 + `/activate/${targetUserId}`;


export const SUPPLIERS_V1 = BASE_URL + 'v1/suppliers';
export const SUPPLIERS_V1_ID = ({ id }) => BASE_URL + 'v1/suppliers' + `/${id}`;
export const SUPPLIERS_DOWNLOAD_V1 = SUPPLIERS_V1 + '/download';

export const SUPPLIERS_ONBOARDING_V1 = BASE_URL + 'v1/supplier-onboard/dashboard';
export const SUPPLIERS_ONBOARDING_V1_ID = ({ supplierOnboardId }) => SUPPLIERS_ONBOARDING_V1 + `/${supplierOnboardId}`;
export const SUPPLIERS_ONBOARDING_FILTER_V1 = SUPPLIERS_ONBOARDING_V1 + '/filter';
export const SUPPLIERS_ONBOARDING_UPDATE_REGISTRATION_STATUS = ({ supplierOnboardId }) =>
    SUPPLIERS_ONBOARDING_V1 + `/update-registration/${supplierOnboardId}`
export const SUPPLIERS_ONBOARDING_UPDATE_FINAL_STATUS = ({ supplierOnboardId }) =>
    SUPPLIERS_ONBOARDING_V1 + `/update-final/${supplierOnboardId}`

export const USER_BY_ID_V1 = (id) => BASE_URL + 'v1/users/' + id;
export const LOGIN_URL = AUTH_V1 + '/login'

export const ME_URL = AUTH_V1 + '/me'

export const WAREHOUSE_V1 = BASE_URL + '/warehouses/v1';
export const WAREHOUSE_GROUPS_V1 = BASE_URL + '/warehouse-groups/v1';

export const ORDER_PIPELINE_V1 = BASE_URL + 'v1/warehouse-order-processing';
export const ORDER_PIPELINE_LOG_V1 = ORDER_PIPELINE_V1 + '/logs';
export const ORDER_PIPELINE_GENERATE_INVOICE_URL_V1 = ORDER_PIPELINE_V1 + '/generate-invoice-url';
export const ORDER_PIPELINE_STOP_PROCESS_V1 = ORDER_PIPELINE_V1 + '/stop';
export const ORDER_PIPELINE_BULK_STOP_PROCESS_V1 = ORDER_PIPELINE_V1 + '/bulk-stop';
export const ORDER_PIPELINE_GET_ORDER_PROFILE_V1 = ({ orderId }) => ORDER_PIPELINE_V1 + `/order-profile/${orderId}`;
export const ORDER_PIPELINE_CHECKPOINT_V1 = ORDER_PIPELINE_V1 + '/checkpoint'
export const ORDER_PIPELINE_RECALCULATE_V1 = ORDER_PIPELINE_V1 + '/recalculate-order-pipelines'
export const PACKAGE_TYPES_V1 = ORDER_PIPELINE_V1 + '/package-types';
export const ORDER_PIPELINE_PACKAGE_V1 = ({ orderPipelineId }) => ORDER_PIPELINE_V1 + `/order-pipelines/${orderPipelineId}/packages`;
export const CREATE_UPDATE_PACKAGE_V1 = ORDER_PIPELINE_V1 + '/packages'
export const ORDER_PIPELINE_COUNT_V1 = ORDER_PIPELINE_V1 + '/count';

export const ORDER_PIPELINE_BASE_V2 = BASE_URL + 'v2/order-pipelines';
export const ORDER_PIPELINE_V2 = BASE_URL + 'v2/order-pipelines/dashboard';
export const ORDER_PIPELINE_DOWNLOAD_V2 = BASE_URL + 'v2/order-pipelines/dashboard/download';
export const ORDER_PIPELINE_OVERVIEW_V2 = BASE_URL + 'v2/order-pipelines/dashboard-overview';
export const ORDER_PIPELINE_LOG_V2 = ORDER_PIPELINE_BASE_V2 + '/logs';
export const ORDER_PIPELINE_LOG_OVERVIEW_V2 = ORDER_PIPELINE_BASE_V2 + '/logs-overview';
export const ORDER_PIPELINE__LOG_DOWNLOAD_V2 = ORDER_PIPELINE_BASE_V2 + '/logs/download';
export const ORDER_PIPELINE_PACKAGE_PIN_V2 = ORDER_PIPELINE_BASE_V2 + '/packages-pin';

export const INVOICE_LINKS_V1 = BASE_URL + 'v1/invoice-links';

// Internal Shipment
export const INTERNAL_SHIPMENT_V1 = BASE_URL + 'v1/internal-shipments';
export const INTERNAL_SHIPMENT_V1_ID = ({ id }) => BASE_URL + 'v1/internal-shipments' + `?purchaseOrderId=${id}`;
export const INTERNAL_SHIPMENT_PURCHASE_RETURN_V1_ID = ({ id }) => BASE_URL + 'v1/internal-shipments' + `?purchaseReturnId=${id}`;
export const INTERNAL_SHIPMENT_GROUP_V1 = INTERNAL_SHIPMENT_V1 + '/group';
// End Internal Shipment

export const PURCHASE_ORDER_V1 = BASE_URL + 'v1/purchase-orders';

export const PURCHASE_ORDER_V1_1 = BASE_URL + 'v1.1/purchase-orders';

export const PURCHASE_ORDER_V2 = BASE_URL + 'v2/purchase-orders';

export const PURCHASE_ORDER_V1_1_ID = ({ id }) => BASE_URL + 'v1.1/purchase-orders' + `/${id}`;

export const PURCHASE_ORDER_V1_1_ID_ITEMS = ({ id }) => BASE_URL + 'v1.1/purchase-orders' + `/${id}` + '/items';

export const PURCHASE_ORDER_V2_ID = ({ id }) => PURCHASE_ORDER_V2 + `/${id}`;

export const PURCHASE_ORDER_UPLOAD_V1 = PURCHASE_ORDER_V1 + '/upload'

export const PURCHASE_ORDER_DOWNLOAD_V1 = ({ id }) => PURCHASE_ORDER_V1 + `/${id}/download`

export const PURCHASE_ORDER_V1_ID = ({ id }) => PURCHASE_ORDER_V1 + `/${id}`

export const PURCHASE_ORDER_V2_BULK_CREATE = PURCHASE_ORDER_V2 + '/bulk-create';

export const PURCHASE_ORDER_V2_SEND_PO_PDF_EMAIL = PURCHASE_ORDER_V2 + '/send-po-pdf-email';

export const PURCHASE_ORDER_DOWNLOAD_ZIP = BASE_URL + '/web/inventory/0/receipt/purchase-order.zip';

export const INBOUND_V1 = BASE_URL + 'v1/inbounds';

export const INBOUND_V1_ID = ({ id }) => BASE_URL + 'v1/inbounds' + `/${id}`;

export const INBOUND_V1_EXPECTATION_ID = ({ id }) => BASE_URL + 'v1/inbounds' + `/${id}` + "/expectation";

export const INBOUND_V1_STATUS_ID = ({ id }) => BASE_URL + 'v1/inbounds' + `/${id}` + "/status";

export const INBOUND_V1_INCOMING = BASE_URL + 'v1/inbounds' + '/incoming';

export const INBOUND_V1_INCOMING_ID = ({ id }) => BASE_URL + 'v1/inbounds' + `/${id}` + '/incoming';

export const INBOUND_V1_EXTRA = BASE_URL + 'v1/inbounds' + '/extra';

export const INBOUND_V1_DOWNLOAD = INBOUND_V1 + '/download/inbounds.csv';

export const INBOUND_V1_IMAGE_UPLOAD = INBOUND_V1 + '/image/upload';

export const INBOUND_V1_CANCEL_PO_INBOUND = ({ id }) => INBOUND_V1 + `/${id}/cancel`;

export const INBOUND_V1_COMPLETE_PO_INBOUND = ({ id }) => INBOUND_V1 + `/${id}/complete`;

export const OUTBOUND_V1 = BASE_URL + 'v1/outbounds';

export const OUTBOUND_V1_ID = ({ id }) => OUTBOUND_V1 + `/${id}`;

export const OUTBOUND_V1_STATUS = ({ id }) => OUTBOUND_V1_ID({ id }) + '/status';

export const OUTBOUND_V1_AVAILABLE_STATUS = ({ id }) => OUTBOUND_V1_ID({ id }) + '/available-status';

export const OUTBOUND_EXPECTATION_V1 = OUTBOUND_V1 + '/expectation';

export const OUTBOUND_V1_DOWNLOAD = OUTBOUND_V1 + '/download/outbounds.csv';
export const OUTBOUND_TYPES = `${OUTBOUND_V1}/types`

export const OUTBOUND_BULK_V1 = OUTBOUND_V1 + '/bulk';

export const OUTBOUND_VERIFY_BULK_V1 = OUTBOUND_V1 + '/verify-bulk';

export const QUALITY_CONTROL_METRICS_V1 = BASE_URL + 'v1/quality-control-metrics'

export const QUALITY_CONTROL_METRICS_V1_DOWNLOAD = QUALITY_CONTROL_METRICS_V1 + '/download/defect.csv';

export const QUALITY_CONTROL_METRICS_BULK_CREATE = QUALITY_CONTROL_METRICS_V1 + '/create-bulk';

export const QUALITY_CONTROL_METRICS_V1_ID = ({ id }) => QUALITY_CONTROL_METRICS_V1 + `/${id}`

export const QUALITY_CONTROL_METRICS_V1_DETAILS = ({ id }) => QUALITY_CONTROL_METRICS_V1_ID({ id }) + '/details'

export const QUALITY_CONTROL_METRICS_V1_STATUS = ({ id }) => QUALITY_CONTROL_METRICS_V1_ID({ id }) + '/status'

export const PURCHASING_ANALYTICS_METRICS_V1 = BASE_URL + 'v1/purchasing-analytics-metrics'

export const PURCHASING_ANALYTICS_METRICS_V1_DOWNLOAD = PURCHASING_ANALYTICS_METRICS_V1 + '/download/purchasing-analytics.csv';

export const INVENTORY_CHECK_V1 = BASE_URL + 'v1/inventory-checks';

export const INVENTORY_CHECK_UPLOAD_V1 = INVENTORY_CHECK_V1 + '/upload'

export const INVENTORY_CHECK_DOWNLOAD_V1 = ({ id }) => INVENTORY_CHECK_V1 + `/${id}/download`

export const INVENTORY_CHECK_V1_ID = ({ id }) => INVENTORY_CHECK_V1 + `/${id}`

export const PURCHASING_PRODUCT_V1 = BASE_URL + 'v1/purchasing-products';
export const PURCHASING_PRODUCT_BULK_CREATE_V1 = PURCHASING_PRODUCT_V1 + '/create/bulk'
export const PURCHASING_PRODUCT_BULK_UPDATE_V1 = PURCHASING_PRODUCT_V1 + '/update/bulk'

export const PRODUCT_PURCHASING_PRODUCT_V1 = BASE_URL + 'v1/product-purchasing-products';

export const PRODUCT_PURCHASING_PRODUCT_V1_DELETE = BASE_URL + 'v1/product-purchasing-products/delete';

// Product Tags
export const PRODUCT_TAG_V1 = BASE_URL + 'v1/product-tags';
export const PRODUCT_TAG_V1_PAGINATION = BASE_URL + 'v1/product-tags/pagination';
export const PRODUCT_TAG_V1_PAGINATION_WITH_PRODUCT_TAG_PRODUCT = BASE_URL + 'v1/product-tags/pagination-product-tag-product';
export const PRODUCT_TAG_DETAIL_V1 = BASE_URL + 'v1/product-tags/details';
export const PRODUCT_TAG_PRODUCTS = (id) => PRODUCT_TAG_V1 + `/${id}` + "/products/pagination";
export const PRODUCT_TAG_PRODUCTS_LIST = (id) => PRODUCT_TAG_V1 + `/${id}` + "/products";
export const PRODUCT_TAG_LOG = (id) => PRODUCT_TAG_V1 + `/${id}` + "/log";
export const PRODUCT_TAG_SIMPLE_WITH_WH = PRODUCT_TAG_V1 + '/simple';
export const PRODUCT_TAG_OPTIONS = PRODUCT_TAG_V1 + '/options'
export const PRODUCT_TAG_TYPE_OPTIONS_V1 = PRODUCT_TAG_V1 + '/type-options';
// End Product Tags

// Product Labels
export const PRODUCT_LABEL_V1 = BASE_URL + 'v1/product-frontend-labels';
export const PRODUCT_LABEL_V1_ID = (id) => PRODUCT_LABEL_V1 + `/${id}`;
export const PRODUCT_LABEL_LOGS_V1 = PRODUCT_LABEL_V1 + '/logs';
export const PRODUCT_LABEL_PRODUCTS_EXPORT_V1 = (id) => PRODUCT_LABEL_V1 + `/${id}` + '/product_label_products.csv';
// End Product Labels

// Frontend Subcategories
export const FRONTEND_SUBCATEGORY_V1 = BASE_URL + 'v1/frontend-subcategories';
export const FRONTEND_SUBCATEGORY_DETAIL_V1 = BASE_URL + 'v1/frontend-subcategories/details';
export const FRONTEND_SUBCATEGORY_SEO_V1 = FRONTEND_SUBCATEGORY_V1 + '/seo-page'
export const FRONTEND_SUBCATEGORY_RESET_SEO_V1 = (id) => FRONTEND_SUBCATEGORY_SEO_V1 + '/reset/' + id;
export const FRONTEND_SUBCATEGORY_LOGS_V1 = BASE_URL + 'v1/frontend-subcategories/logs';
// End Frontend Subcategories

// Frontend Categories
export const FRONTEND_CATEGORY_V1 = BASE_URL + 'v1/frontend-categories';
export const FRONTEND_CATEGORY_DETAIL_V1 = BASE_URL + 'v1/frontend-categories/details';
export const FRONTEND_CATEGORY_SEO_V1 = FRONTEND_CATEGORY_V1 + '/seo-page'
export const FRONTEND_CATEGORY_RESET_SEO_V1 = (id) => FRONTEND_CATEGORY_SEO_V1 + '/reset/' + id;
// End Frontend Categories

// Frontend Category Sortings
export const FRONTEND_CATEGORY_SORTINGS_V1 = BASE_URL + 'v1/frontend-category-sortings';
// End Frontend Category Sortings

// Frontend Categories Group
export const FRONTEND_CATEGORY_GROUP_V1 = BASE_URL + 'v1/frontend-category-groups';
// End Frontend Categories Group

export const FEATURE_FLAG_V1 = BASE_URL + 'v1/feature-flags';

export const FEATURE_FLAG_V1_ID = ({ id }) => FEATURE_FLAG_V1 + `/${id}`;

export const FEATURE_FLAG_V1_AGENTS = ({ id }) => FEATURE_FLAG_V1_ID({ id }) + '/agents';

export const FEATURE_FLAG_V1_ONE_AGENT = ({ id, agentId }) => FEATURE_FLAG_V1_AGENTS({ id }) + `/${agentId}`;

export const FEATURE_FLAG_v1_SHIPPING_POINT = ({
    id,
    shippingPointId
}) => FEATURE_FLAG_V1_ID({ id }) + `/shipping-points/${shippingPointId}`;

export const AGENT_INVOICE_URL = ({ agentId }) => BASE_URL + `/web/agents/${agentId}/invoices/agent-summary.pdf`

export const CUSTOMER_INVOICE_URL = ({ agentId }) => BASE_URL + `/web/agents/${agentId}/invoices/customers.pdf`

export const ORDER_STATUSES_V1 = BASE_URL + 'v1/order-statuses'

export const ORDER_CANCEL_REASONS_V1 = BASE_URL + 'v1/order-cancel-reasons'

export const INVOICE_DELIVERY_NOTIFY = BASE_URL + 'invoices/notify-delivery';

export const INVOICE_QUALITY_SURVEY = BASE_URL + 'invoices/quality-survey';

export const INVOICE_LIST = BASE_URL + 'invoices/list'

export const WHATSAPP_TEMPLATES_V1 = BASE_URL + 'v1/whatsapp/templates';

export const WHATSAPP_BLAST_V1 = BASE_URL + 'v1/whatsapp/blast';

export const RESOURCE_V1 = BASE_URL + 'v1/resources';

export const WHATSAPP_OUTBOUND_LOG_V1 = BASE_URL + 'v1/whatsapp/outbound-log';

export const AGENT_WORKDAYS_V1 = BASE_URL + 'v1/agent-workdays';

export const AGENT_WORKDAYS_GLOBAL_WEEKLY_V1 = AGENT_WORKDAYS_V1 + '/global-weekly';

export const AGENT_WORKDAYS_AGENT_WEEKLY_V1 = AGENT_WORKDAYS_V1 + '/agent-weekly';

export const AGENT_WORKDAYS_ALL_AGENT_WEEKLY_V1 = AGENT_WORKDAYS_V1 + '/agent-weekly-all';
export const AGENT_WORKDAYS_ALL_AGENT_WEEKLY_DROPDOWN_V1 = AGENT_WORKDAYS_V1 + '/agent-weekly-all-dropdown';

export const AGENT_WORKDAYS_CHECK_WORKDAY_V1 = AGENT_WORKDAYS_V1 + '/check-workday';

export const AGENT_WORKDAYS_GLOBAL_DATE_V1 = AGENT_WORKDAYS_V1 + '/global-date';

// Agent Price Group Products
export const AGENT_PRICE_GROUP_PRODUCTS = BASE_URL + 'v1/agent-price-group-products';
export const SORTED_AGENT_PRICE_GROUP_PRODUCTS = BASE_URL + 'v1/agent-price-group-products?sort=orderPriority,desc&sort=id,asc';
export const UPDATE_PRICE_GROUP_PRODUCTS = ({ id }) => AGENT_PRICE_GROUP_PRODUCTS + `/${id}`;
export const DELETE_PRICE_GROUP_PRODUCTS = ({ id }) => AGENT_PRICE_GROUP_PRODUCTS + `/delete/${id}`;
export const AGENT_PRICE_GROUP_PRODUCTS_ID = (id) => BASE_URL + 'v1/agent-price-group-products' + `/${id}`;
export const AGENT_PRICE_GROUP_PRODUCTS_DELETE_ID = (id) => BASE_URL + 'v1/agent-price-group-products/delete' + `/${id}`;
export const AGENT_PRICE_GROUP_PRODUCTS_BULK_UPDATE = AGENT_PRICE_GROUP_PRODUCTS + '/bulk-update-upload';
export const AGENT_PRICE_GROUP_PRODUCTS_BULK_CREATE = AGENT_PRICE_GROUP_PRODUCTS + '/bulk-create-upload';
export const AGENT_PRICE_GROUP_PRODUCTS_BULK_DELETE = AGENT_PRICE_GROUP_PRODUCTS + '/bulk-delete-upload';
export const AGENT_PRICE_GROUP_PRODUCTS_PRICING_SCHEDULER = AGENT_PRICE_GROUP_PRODUCTS + '/pricing-scheduler';
export const AGENT_PRICE_GROUP_PRODUCTS_PRICING_SCHEDULER_ID = (id) => AGENT_PRICE_GROUP_PRODUCTS + '/pricing-scheduler' + `/${id}`;
export const AGENT_PRICE_GROUP_PRODUCTS_V1_1 = BASE_URL + 'v1.1/agent-price-group-products';
export const AGENT_PRICE_GROUP_PRODUCTS_V1_CREATE_OR_UPDATE = AGENT_PRICE_GROUP_PRODUCTS + "/create-or-update";
export const AGENT_PRICE_GROUP_PRODUCTS_V1_DELETES = AGENT_PRICE_GROUP_PRODUCTS + "/deletes";
export const AGENT_PRICE_GROUP_PRODUCTS_V1_APGS_PRODUCTS = AGENT_PRICE_GROUP_PRODUCTS + "/apgs-products";

// End Agent Price Group Products

export const WAREHOUSE_TRANSFERS_V1 = BASE_URL + 'v1/warehouse-transfers';

export const WAREHOUSE_TRANSFER_BY_ID_V1 = ({ id }) => WAREHOUSE_TRANSFERS_V1 + `/${id}`;
export const WAREHOUSE_TRANSFER_BY_ID_V1_PDF = ({ id }) => `${BASE_URL}web/warehouse-transfers/${id}.pdf`;
export const WAREHOUSE_TRANSFER_STATUS = ({ id }) => WAREHOUSE_TRANSFER_BY_ID_V1({ id }) + '/status';
export const WAREHOUSE_TRANSFER_MATCH_ITEM_V1 = WAREHOUSE_TRANSFERS_V1 + '/match-item';
export const WAREHOUSE_TRANSFER_TYPES = `${WAREHOUSE_TRANSFERS_V1}/types`;
export const CATEGORIES_FOR_WAREHOUSE_TRANSFER = `${WAREHOUSE_TRANSFERS_V1}/item/categories`;
export const WAREHOUSE_TRANSFER_CHECKER = `${WAREHOUSE_TRANSFERS_V1}/checker`;
export const WAREHOUSE_TRANSFER_CALCULATE = `${WAREHOUSE_TRANSFERS_V1}/calculate`;
export const WAREHOUSE_TRANSFER_REFERENCE = `${WAREHOUSE_TRANSFERS_V1}/reference`;
export const WAREHOUSE_TRANSFER_EXPORT = `${WAREHOUSE_TRANSFERS_V1}/export`;
export const WAREHOUSE_TRANSFER_RAW_MATERIAL = `${WAREHOUSE_TRANSFERS_V1}/create-rm`;
export const WAREHOUSE_TRANSFER_ALLOCATOR_REFERENCE = `${WAREHOUSE_TRANSFERS_V1}/allocator/references`;
export const WAREHOUSE_TRANSFER_ALLOCATOR_CHECKER = `${WAREHOUSE_TRANSFERS_V1}/allocator/checker`;
export const WAREHOUSE_TRANSFERS_UPDATE_DOCUMENT = (id) => `${WAREHOUSE_TRANSFERS_V1}/document/${id}`

export const WAREHOUSE_TRANSFERS_V1_1 = BASE_URL + 'v1.1/warehouse-transfers';
export const WAREHOUSE_TRANSFERS_INBOUND_STATE = `${INBOUND_V1}/warehouse-transfer/status`;
export const WAREHOUSE_TRANSFERS_OUTBOUND_STATE = `${OUTBOUND_V1}/warehouse-transfers/status`;

export const GR_PAYMENT_V1 = BASE_URL + 'v1/gr-payments';

export const GR_PAYMENT_BULK_V1 = GR_PAYMENT_V1 + '/bulk';

export const GR_PAYMENT_ID_V1 = ({ id }) => BASE_URL + 'v1/gr-payments' + `/${id}`;

export const GR_PAYMENT_READY_TO_PAY_ID_V1 = ({ id }) => BASE_URL + 'v1/gr-payments' + `/${id}/ready-to-pay`;

export const GR_PAYMENT_UPDATE_INBOUND_STATUS_V1 = BASE_URL + 'v1/gr-payments/update-status';

export const PURCHASE_ORDER_RECEIPT = ({ id }) => BASE_URL + 'web/inventory' + `/${id}` + '/receipt/purchase-order.pdf';

export const GOODS_RECEIPT_OF_PURCHASE_ORDER = ({ id }) => BASE_URL + 'web/inventory' + `/${id}` + '/receipt/goods-receipt-of-purchase-order.pdf';
export const IMAGE_PDF_OF_PURCHASE_ORDER = ({ id }) => BASE_URL + 'web/inventory' + `/${id}` + '/receipt/inbound-image-of-purchase-order.pdf';

export const INVENTORY_DASHBOARD_V1 = BASE_URL + 'v1/inventory';
export const INVENTORY_DASHBOARD_V22 = INVENTORY_DASHBOARD_V1 + '/v22';
export const INVENTORY_DASHBOARD_V22_EXPORT = INVENTORY_DASHBOARD_V22 + '/download/inventory-dashboard.xlsx'
export const INVENTORY_DASHBOARD_PLANOGRAM_V22 = INVENTORY_DASHBOARD_V1 + '/v22-planogram';
export const INVENTORY_DASHBOARD_PLANOGRAM_V22_EXPORT = INVENTORY_DASHBOARD_PLANOGRAM_V22 + '/download/planogram-dashboard.xlsx';
export const INVENTORY_DASHBOARD_POKEMON_V3_EXPORT = INVENTORY_DASHBOARD_PLANOGRAM_V22 + '/download/pokemon-dashboard.xlsx';
export const INVENTORY_DASHBOARD_ACCURACY_EXPORT = INVENTORY_DASHBOARD_PLANOGRAM_V22 + '/download/accuracy-dashboard.xlsx';
export const INVENTORY_DASHBOARD_BASIC_V22 = INVENTORY_DASHBOARD_V1 + '/v22-basic';
export const INVENTORY_DASHBOARD_TRANSACTION_V22 = INVENTORY_DASHBOARD_V1 + '/v22-transactions';
export const INVENTORY_DASHBOARD_LOG_HISTORY_V22 = INVENTORY_DASHBOARD_V1 + '/v22-log/history';
export const INVENTORY_DASHBOARD_PLANOGRAM_V23 = INVENTORY_DASHBOARD_V1 + '/v23-planogram';
export const INVENTORY_DASHBOARD_PLANOGRAM_LOG_V23 = INVENTORY_DASHBOARD_PLANOGRAM_V23 + '/log';
export const INVENTORY_DASHBOARD_PLANOGRAM_V23_EXPORT = INVENTORY_DASHBOARD_PLANOGRAM_V23 + '/download/planogram-dashboard.xlsx';
export const INVENTORY_DASHBOARD_ACCURACY_V23_EXPORT = INVENTORY_DASHBOARD_PLANOGRAM_V23 + '/download/accuracy-dashboard.xlsx';

export const INVENTORY_ADJUSTMENT_V1 = BASE_URL + '/v1/inventory-adjustment';
export const INVENTORY_ADJUSTMENT_V1_ID = (id) => INVENTORY_ADJUSTMENT_V1 + `/${id}`;
export const INVENTORY_ADJUSTMENT_V1_ID_STATUS = (id) => INVENTORY_ADJUSTMENT_V1_ID(id) + '/status';

export const INVENTORY_ADJUSTMENT_V1_DOCUMENT = BASE_URL + 'v1/inventory-adjustment-documents';
export const INVENTORY_ADJUSTMENT_V1_DOCUMENT_STATUS = INVENTORY_ADJUSTMENT_V1_DOCUMENT + '/get-by-status';
export const INVENTORY_ADJUSTMENT_V1_DOCUMENT_CLOSE = INVENTORY_ADJUSTMENT_V1_DOCUMENT + '/close';
export const INVENTORY_ADJUSTMENT_V1_DOCUMENT_CANCEL = INVENTORY_ADJUSTMENT_V1_DOCUMENT + '/cancel';
export const INVENTORY_ADJUSTMENT_V1_DOCUMENT_CLOSE_ID = (id) => INVENTORY_ADJUSTMENT_V1_DOCUMENT_CLOSE + `/${id}`;
export const INVENTORY_ADJUSTMENT_V1_DOCUMENT_CANCEL_ID = (id) => INVENTORY_ADJUSTMENT_V1_DOCUMENT_CANCEL + `/${id}`;

export const INVENTORY_ADJUSTMENT_V1_DOCUMENT_ITEM = BASE_URL + 'v1/inventory-adjustment-documents-items';
export const INVENTORY_ADJUSTMENT_V1_DOCUMENT_ITEM_BULK = INVENTORY_ADJUSTMENT_V1_DOCUMENT_ITEM + '/bulk';
export const INVENTORY_ADJUSTMENT_V1_DOCUMENT_ITEM_DOCUMENT = INVENTORY_ADJUSTMENT_V1_DOCUMENT_ITEM + '/documents';
export const INVENTORY_ADJUSTMENT_V1_DOCUMENT_ITEM_SECRET_KEY = INVENTORY_ADJUSTMENT_V1_DOCUMENT_ITEM + '/secret-key';
export const INVENTORY_ADJUSTMENT_V1_DOCUMENT_ITEM_DOCUMENT_ID = (id) => INVENTORY_ADJUSTMENT_V1_DOCUMENT_ITEM_DOCUMENT + `/${id}`;

export const INVENTORY_ADJUSTMENT_DOCUMENT_SCHEDULERS_V1 = BASE_URL + 'v1/inventory-adjustment-document-schedulers';
export const INVENTORY_ADJUSTMENT_DOCUMENT_SCHEDULERS_V1_CANCEL = INVENTORY_ADJUSTMENT_DOCUMENT_SCHEDULERS_V1 + '/cancel';
export const INVENTORY_ADJUSTMENT_DOCUMENT_AUTOMATION_V1 = BASE_URL + 'v1/inventory-adjustment-document-automation';
export const INVENTORY_ADJUSTMENT_DOCUMENT_AUTOMATION_V1_ID = (id) => INVENTORY_ADJUSTMENT_DOCUMENT_AUTOMATION_V1 + `/${id}`;
export const INVENTORY_ADJUSTMENT_DOCUMENT_AUTOMATION_V1_CANCEL = (id) => INVENTORY_ADJUSTMENT_DOCUMENT_AUTOMATION_V1 + `/${id}/cancel`;

export const INVENTORY_ADJUSTMENT_SCHEDULER_V1 = BASE_URL + 'v1/inventory-adjustment-scheduler';
export const INVENTORY_ADJUSTMENT_SCHEDULER_V1_ID = (id) => INVENTORY_ADJUSTMENT_SCHEDULER_V1 + `/${id}`;
export const INVENTORY_ADJUSTMENT_SCHEDULER_V1_CANCEL = (id) => INVENTORY_ADJUSTMENT_SCHEDULER_V1 + `/${id}/cancel`;
export const INVENTORY_ADJUSTMENT_SCHEDULER_V1_LOGS = (id) => INVENTORY_ADJUSTMENT_SCHEDULER_V1 + `/logs/${id}`;

export const SHIPPING_POINTS_V1 = BASE_URL + 'v1/shipping-points';
export const SHIPPING_POINTS_GEO_MEASUREMENT_V1 = SHIPPING_POINTS_V1 + '/geometry';
export const SHIPPING_POINTS_ACTIVE = SHIPPING_POINTS_V1 + '/active';
export const SHIPPING_POINTS_ACTIVE_GEO_MEASUREMENT = SHIPPING_POINTS_ACTIVE + '/geometry-measurement';
export const SHIPPING_POINTS_ACTIVE_NDD = SHIPPING_POINTS_ACTIVE + '/ndd';
export const SHIPPING_POINTS_ACTIVE_NDD_SCHEDULED = SHIPPING_POINTS_ACTIVE + '/ndd-scheduled';
export const SHIPPING_POINTS_ACTIVE_SDD = SHIPPING_POINTS_ACTIVE + '/sdd';
export const SHIPPING_POINTS_ACTIVE_SDD_SCHEDULED = SHIPPING_POINTS_ACTIVE + '/sdd-scheduled';
export const SHIPPING_POINTS_ACTIVE_SDD_PRIORITY = SHIPPING_POINTS_ACTIVE + '/sdd-priority';
export const SHIPPING_POINTS_ACTIVE_EXTENSION = SHIPPING_POINTS_ACTIVE + '/extension';
export const SHIPPING_POINTS_COVERING = SHIPPING_POINTS_V1 + '/covering';
export const SHIPPING_POINTS_GEOFENCING = SHIPPING_POINTS_V1 + '/geofencing';
export const SHIPPING_POINTS_SDD_OTHER_CONTACTS = SHIPPING_POINTS_V1 + '/sdd-other-contacts';
export const SHIPPING_POINTS_SDD_SP = SHIPPING_POINTS_V1 + '/sdd';
export const SHIPPING_POINTS_SDD_TRIAL_SP = SHIPPING_POINTS_V1 + '/sdd-trial';
export const SHIPPING_POINTS_SDD_TRIAL_SP_CONFIG = SHIPPING_POINTS_V1 + '/sdd-trial-config';
export const SHIPPING_POINTS_SDD_UNFILLED_AUTO_ASSIGN_CONFIG = SHIPPING_POINTS_V1 + '/sdd-shipping-points/unfilled-auto-assign-config';
export const SHIPPING_POINTS_GOMART_MANUAL = SHIPPING_POINTS_V1 + '/external-channels';

export const AGENT_SLUGS_V1 = BASE_URL + 'v1/agents/slugs';
export const AGENT_SLUGS_V1_ID = (id) => BASE_URL + 'v1/agents/slugs' + `/${id}`;

export const AGENT_V1_ID = ({ id }) => BASE_URL + 'v1/agents' + `/${id}`;
export const PROMO_V1 = BASE_URL + 'v1/promos';

export const PROMO_V1_ID = (id) => BASE_URL + 'v1/promos' + `/${id}`;
export const PROMO_V1_BLACKLIST = PROMO_V1 + "/blacklist";
export const PROMO_V1_BLACKLIST_ID = (id) => PROMO_V1_BLACKLIST + `/${id}`;
export const PROMO_V1_BY_CODE = PROMO_V1 + "/by-code";
export const PROMO_V1_BY_CODE_NO_PIN = PROMO_V1 + "/by-code-no-pin";
export const PROMO_V1_GET_DUPLICATE_BY_CODE = PROMO_V1 + "/get-duplicate-by-code";
export const PROMO_V1_ID_ORDER_PROMO_LIGHTWEIGHT = PROMO_V1 + "/order-promo-lightweight";
export const PROMO_UPLOAD_IMAGE_V1 = PROMO_V1 + "/upload-image";

export const REFERRAL_V1 = `${PROMO_V1}/referral`;
// CREATE CONST API FOR REFERRAL HERE
export const REFEREE_CONFIGS_VALUES = `${REFERRAL_V1}/configs/referee`;
export const REFERRER_CONFIGS_VALUES = `${REFERRAL_V1}/configs/referrer`;
export const REFERRAL_BANNERS = `${REFERRAL_V1}/banner`;
export const REFERRAL_BANNER = ({ id }) => REFERRAL_V1 + `/banner/${id}`;
export const REFERRAL_PROGRAM_BANNERS = `${REFERRAL_V1}/program-banner`;
export const REFERRAL_PROGRAM_BANNER = ({ id }) => REFERRAL_V1 + `/program-banner/${id}`;
export const REFERRAL_PROGRAM_BANNER_CHANGE_STATUS = REFERRAL_V1 + `/program-banner/change-status`;
export const REFERRAL_VALIDATE_DATA = REFERRAL_V1 + `/validate-data`;
export const REFERRAL_DISBURSE_VOUCHER = REFERRAL_V1 + `/disburse-voucher`;
export const REFERRAL_DATA_LOGS = REFERRAL_V1 + `/logs`;
export const REFERRAL_SHOW_DATA = REFERRAL_V1 + `/show-data`;

export const PROMO_COMPLIMENTARY_VOUCHER_V1 = PROMO_V1 + '/complimentary/voucher-code';
export const PROMO_COMPLIMENTARY_VOUCHER_V1_ID = (id) => PROMO_V1 + `/complimentary/${id}`;
export const PROMO_BULK_COMPLIMENTARY_VOUCHER_V1 = PROMO_COMPLIMENTARY_VOUCHER_V1 + '/bulk';

export const CATEGORIES_FOR_INV_DASHBOARD = INVENTORY_DASHBOARD_V1 + '/categories';

export const AGENT_TRANSACTION_V1 = BASE_URL + 'v1/agent-transactions';

export const AGENT_TRANSACTION_V1_ID = (id) => AGENT_TRANSACTION_V1 + `/${id}`;

export const AGENT_TRANSACTION_V1_BULK_ADD = AGENT_TRANSACTION_V1 + '/bulk-add-upload';
export const AGENT_TRANSACTION_V1_REFRESH_BALANCE_ID = (id) => AGENT_TRANSACTION_V1_ID(id) + '/refresh-balance';

export const FREEBIE_PRODUCT_V1 = BASE_URL + 'v1/freebie-products';
export const FREEBIE_PRODUCT_V1_DELETE = (id) => FREEBIE_PRODUCT_V1 + `/${id}`;
export const FREEBIE_PRODUCT_V1_GET_BY_CODE = (code) => FREEBIE_PRODUCT_V1 + `/${code}`;

export const FREEBIE_PROMO_V1 = `${BASE_URL}v1/freebie-promos`;
export const FREEBIE_PROMO_V1_USE_ID = (id) => `${FREEBIE_PROMO_V1}/${id}`;
export const FREEBIE_PROMO_V1_SEARCH = `${FREEBIE_PROMO_V1}/search`;
export const FREEBIE_PROMO_V1_LIGHTWEIGHT = `${FREEBIE_PROMO_V1}/lightweight`;
export const FREEBIE_PROMO_V1_BULK_UPLOAD_BLOCKED_LIST = `${FREEBIE_PROMO_V1}/bulk-blocked-list`;
export const FREEBIE_PROMO_V1_BULK_UPLOAD_ALLOWED_LIST = `${FREEBIE_PROMO_V1}/bulk-allowed-list`;

export const PROMO_EVENT_V1 = BASE_URL + "v1/promo-events";

export const DISTRICTS_V1_URL = BASE_URL + '/v1/districts';

export const DISTRICT_SLA_V1 = BASE_URL + '/v1/district-slas';
export const DISTRICT_SLA_ID_V1 = (id) => `${DISTRICT_SLA_V1}/${id}`;
export const DISTRICT_SLA_UPLOAD_V1 = `${DISTRICT_SLA_V1}/upload`;
export const DISTRICT_SLA_DOWNLOAD_V1 = `${DISTRICT_SLA_V1}/export`;

const AGENT_DASHBOARD_V1_URL = BASE_URL + '/v1/agent-dashboard';
const AGENT_DASHBOARD_V1_URL_ID = (agentId) => AGENT_DASHBOARD_V1_URL + `/${agentId}`;
export const AGENT_DASHBOARD_PAYMENT_PAYABLE = (agentId) => AGENT_DASHBOARD_V1_URL_ID(agentId) + '/payments/payable';
export const AGENT_DASHBOARD_PAYMENT_RECEIVABLE = (agentId) => AGENT_DASHBOARD_V1_URL_ID(agentId) + '/payments/receivable';

export const COURIERS_V1 = BASE_URL + 'v1/couriers';
export const COURIERS_DROPDOWN_V1 = COURIERS_V1 + '/dropdown';
export const COURIERS_ME_V1 = COURIERS_V1 + '/me';
export const COURIERS_ID_V1 = (id) => COURIERS_V1 + `/${id}`;
export const COURIERS_CODE_V1 = COURIERS_V1 + '/courier-codes';
export const COURIERS_TYPES_V1 = COURIERS_V1 + '/types';
export const COURIERS_FEE_V1 = COURIERS_V1 + '/fees';
export const COURIERS_FEE_MULTIPLE_V1 = COURIERS_FEE_V1 + '/multiple';
export const COURIERS_ID_FEE_V1 = (id) => COURIERS_ID_V1(id) + '/fees';
export const COURIERS_ME_FEE_V1 = COURIERS_V1 + '/me/fees';
export const COURIERS_FEE_BULK_V1 = COURIERS_FEE_V1 + '/bulk';
export const COURIERS_FEE_DOWNLOAD_V1 = COURIERS_FEE_V1 + '/download';
export const COURIERS_FEE_TYPE_V1 = COURIERS_FEE_V1 + '/types';
export const COURIERS_ID_FEE_TYPE_V1 = (id) => COURIERS_ID_FEE_V1(id) + '/types';
export const COURIERS_FEE_ID_V1 = (id) => COURIERS_FEE_V1 + `/${id}`;
export const COURIERS_FEE_DELETE_V1 = COURIERS_FEE_V1 + '/delete';
export const COURIERS_CHECK_V1 = COURIERS_V1 + '/check';
export const COURIERS_BULK_CREATE_V1 = COURIERS_V1 + '/bulk-create';
export const COURIERS_BULK_UPDATE_V1 = COURIERS_V1 + '/bulk-update';
export const COURIERS_EXPORT_V1 = COURIERS_V1 + '/export';
export const COURIERS_UPDATE_HISTORY_V1 = (id) => COURIERS_ID_V1(id) + '/history';
export const COURIERS_EXPORT_BULK_CREATE_TEMPLATE_V1 = COURIERS_V1 + '/export-bulk-create-template';
export const COURIERS_BULK_UPDATE_ROLE_V1 = COURIERS_V1 + '/bulk-update/roles';
export const COURIERS_FEE_BULK_EDIT_V1 = COURIERS_FEE_V1 + '/bulk-edit';
export const COURIERS_FEE_HISTORY_V1 = (id) => COURIERS_FEE_V1 + `/${id}/history`;

export const COURIERS_PUNISHMENT_V1 = BASE_URL + 'v1/courier-punishments';
export const COURIERS_PUNISHMENT_UPDATE_V1 = (id) => COURIERS_PUNISHMENT_V1 + `/${id}`;
export const COURIERS_PUNISHMENT_SOFT_DELETE_V1 = (id) => COURIERS_PUNISHMENT_V1 + `/${id}`;
export const COURIERS_PUNISHMENT_BULK_UPLOAD_V1 = COURIERS_PUNISHMENT_V1 + '/bulk-upload';
export const COURIERS_PUNISHMENT_TYPE_V1 = COURIERS_PUNISHMENT_V1 + '/types';
export const COURIERS_PUNISHMENT_TYPE_UPDATE_V1 = (id) => COURIERS_PUNISHMENT_TYPE_V1 + `/${id}`;
export const COURIERS_PUNISHMENT_TYPE_DROPDOWN_V1 = COURIERS_PUNISHMENT_TYPE_V1 + '/dropdown';

export const CONVERSION_V1 = BASE_URL + "v1/conversions";
export const CONVERSION_V1_CREATE_BULK = BASE_URL + "v1/conversions/create-bulk";
export const CONVERSION_UPDATE_V1 = CONVERSION_V1 + `/wh-app`;
export const CONVERSION_GET_DETAIL_V1 = CONVERSION_UPDATE_V1;
export const CONVERSION_UPDATE_STATUS_V1 = ({ id }) => CONVERSION_UPDATE_V1({ id }) + '/status';
export const CONVERSION_EXPORT_V1 = CONVERSION_V1 + '/download/conversion.csv'
export const CONVERSION_GET_LIST_USER_V1 = CONVERSION_V1 + '/list/users';
export const CONVERSION_V2 = BASE_URL + "v2/conversions";
export const CONVERSION_EXPORT_V2 = CONVERSION_V2 + '/download/conversion.csv'
export const CONVERSION_GET_DETAIL_V2 = ({ id }) => CONVERSION_V2 + `/${id}`;

export const CONVERSION_TEMPLATE_V1 = BASE_URL + "v1/conversion-templates";
export const CONVERSION_TEMPLATE_UPDATE_V1 = ({ id }) => CONVERSION_TEMPLATE_V1 + `/${id}`;

export const ORDER_LIMIT_V1 = BASE_URL + "v1/order-limits";
export const ORDER_LIMIT_SDD_V1 = ORDER_LIMIT_V1 + "/sdd";
export const ORDER_LIMIT_OVERVIEW_V1 = ORDER_LIMIT_V1 + "/overview";
export const ORDER_LIMIT_BY_SP_V1 = ORDER_LIMIT_V1 + "/shipping-points";
export const ORDER_LIMIT_BY_WAREHOUSE_V1 = ORDER_LIMIT_V1 + "/warehouses";
export const ORDER_LIMIT_GLOBAL_V1 = ORDER_LIMIT_V1 + "/global";
export const ORDER_LIMIT_WEEKLY_V1 = ORDER_LIMIT_V1 + "/weekly";
export const ORDER_LIMIT_DATE_V1 = ORDER_LIMIT_V1 + "/date";
export const ORDER_LIMIT_TIME_RANGE_V1 = ORDER_LIMIT_V1 + "/time-range";
export const ORDER_LIMIT_BULK_DATE_V1 = ORDER_LIMIT_V1 + "/bulk-date";
export const ORDER_LIMIT_BULK_SDD_V1 = ORDER_LIMIT_V1 + "/bulk-sdd";
export const ORDER_LIMIT_BULK_SDD_PREVIEW_V1 = ORDER_LIMIT_BULK_SDD_V1 + "/preview";

export const TOTAL_PRICE_WHITELIST_V1 = BASE_URL + 'v1/total-price-limit-whitelists';
export const TOTAL_PRICE_WHITELIST_V1_BY_ID = (id) => TOTAL_PRICE_WHITELIST_V1 + `/${id}`;

export const ORDER_DELIVERIES_V1 = `${BASE_URL}/v1/order-deliveries`;
export const ORDER_DELIVERIES_ID_V1 = (id) => `${ORDER_DELIVERIES_V1}/${id}`;
export const ORDER_DELIVERIES_UPLOAD_V1 = `${ORDER_DELIVERIES_V1}/upload`;
export const ORDER_DELIVERIES_OVERVIEW_V1 = `${ORDER_DELIVERIES_V1}/overview`;
export const ORDER_DELIVERIES_REASSIGN_ID_V1 = (id) => `${ORDER_DELIVERIES_V1}/reassign/${id}`;
export const ORDER_DELIVERIES_REASSIGN_BATCH_V1 = `${ORDER_DELIVERIES_V1}/reassign/batch`;
export const ORDER_DELIVERIES_LOCK_ID_V1 = (id) => `${ORDER_DELIVERIES_V1}/lock/${id}`;
export const ORDER_DELIVERIES_LOCK_BATCH_V1 = `${ORDER_DELIVERIES_V1}/lock/batch`;
export const ORDER_DELIVERIES_EXPORT_V1 = `${ORDER_DELIVERIES_V1}/export`;
export const ORDER_DELIVERIES_DELETE_V1 = `${ORDER_DELIVERIES_V1}/delete`;
export const ORDER_DELIVERIES_UPDATE_3PL_LAST_STATUS = `${ORDER_DELIVERIES_V1}/3pl-last-statuses`;
export const ORDER_DELIVERIES_BULK_UPDATE_3PL_LAST_STATUS = `${ORDER_DELIVERIES_V1}/bulk-3pl-last-statuses`;
export const ORDER_DELIVERIES_CANCEL_3PL_AND_REASSIGN = `${ORDER_DELIVERIES_V1}/3pl-cancel-and-reassign`;
export const ORDER_DELIVERIES_BULK_CANCEL_3PL_AND_REASSIGN = `${ORDER_DELIVERIES_V1}/bulk-3pl-cancel-and-reassign`;
export const ORDER_DELIVERIES_BULK_LINK_3PL = `${ORDER_DELIVERIES_V1}/bulk-link-3pl`;
export const ORDER_DELIVERIES_LOGISTIC_NAME = `${ORDER_DELIVERIES_V1}/logistic-names`;
export const ORDER_DELIVERIES_BULK_UPLOAD_REASSIGN = `${ORDER_DELIVERIES_V1}/bulk-upload-reassign`;
export const ORDER_DELIVERIES_DROP_ORDER_CONFIG = ORDER_DELIVERIES_V1 + '/drop-order-config';
export const ORDER_DELIVERIES_DROP_ORDER_CONFIG_HISTORY = ORDER_DELIVERIES_DROP_ORDER_CONFIG + '/history';

export const ORDER_DELIVERIES_LIVE_TRACKING = `${BASE_URL}/v1/order-live-tracking`;

export const SDD_ORDER_DELIVERIES_V1 = `${BASE_URL}/v1/sdd-order-deliveries`;
export const SDD_ORDER_DELIVERIES_EDIT_V1 = (id) => `${SDD_ORDER_DELIVERIES_V1}/${id}`;
export const SDD_ORDER_DELIVERIES_EXPORT_V1 = `${SDD_ORDER_DELIVERIES_V1}/export`;
export const SDD_ORDER_DELIVERIES_GET_3PL = `${SDD_ORDER_DELIVERIES_V1}/3pl`;
export const SDD_ORDER_DELIVERIES_FINISH_3PL = (id) => `${SDD_ORDER_DELIVERIES_V1}/3pl/${id}`;
export const SDD_ORDER_DELIVERIES_LOGISTIC_NAME = `${SDD_ORDER_DELIVERIES_V1}/logistic-names`;

export const ORDER_DELIVERIES_V1_1 = `${BASE_URL}/v1.1/order-deliveries`;
export const ORDER_DELIVERIES_OVERVIEW_V1_1 = `${ORDER_DELIVERIES_V1_1}/overview`;
export const ORDER_DELIVERIES_LOCK_BATCH_V1_1 = `${ORDER_DELIVERIES_V1_1}/lock/batch`;
export const ORDER_DELIVERIES_EXPORT_V1_1 = `${ORDER_DELIVERIES_V1_1}/export`;

export const FLASH_SALE_V1 = BASE_URL + "v1/auto-flash-sale";
export const FLASH_SALE_V1_DETAILS = (id) => BASE_URL + `v1/auto-flash-sale/${id}`;
export const FLASH_SALE_V1_SCHEMA = FLASH_SALE_V1 + "/schema";
export const FLASH_SALE_V1_SCHEMA_DROPDOWN = FLASH_SALE_V1_SCHEMA + '/dropdown';
export const FLASH_SALE_V1_SCHEMA_USER_DROPDOWN = FLASH_SALE_V1_SCHEMA + '/user-dropdown';
export const FLASH_SALE_V1_SKU = FLASH_SALE_V1 + "/sku";
export const FLASH_SALE_V1_BULK = FLASH_SALE_V1 + "/bulk";
export const FLASH_SALE_V1_BULK_SETTINGS = FLASH_SALE_V1 + '/bulk-setting';
export const FLASH_SALE_V1_UPDATE_AFS_FEATURE = FLASH_SALE_V1 + '/update-afs-feature';
export const FLASH_SALE_V1_ON_GOING_PRODUCTS = FLASH_SALE_V1 + '/on-going-products';

export const SHOPEEPAY_TRANSACTIONS_BY_REFERENCE_ID = BASE_URL + '/v1/shopeepay/transactions-by-reference-id';

export const ADDRESS_VERIFICATION_V1 = BASE_URL + "v1/orders/address-verification";
export const ADDRESS_VERIFICATION_ID_V1 = (orderId) => ADDRESS_VERIFICATION_V1 + `/${orderId}`;
export const ADDRESS_VERIFICATION_UPLOAD_V1 = ADDRESS_VERIFICATION_V1 + "/upload";
export const ADDRESS_VERIFICATION_EXPORT_V1 = ADDRESS_VERIFICATION_V1 + "/export";
export const ADDRESS_VERIFICATION_OVERVIEW_V1 = ADDRESS_VERIFICATION_V1 + "/overview";

export const REFERRER_REWARDS = `${BASE_URL}/v1/referrer-rewards`;
export const REFERRER_REWARDS_BULK_UPDATE = REFERRER_REWARDS + '/update-status/bulk';

export const BANNERS_V1 = BASE_URL + "/v1/banners"
export const BANNERS_V1_URL = ({ id }) => BANNERS_V1 + `/${id}`;
export const BANNERS_CHANGE_VISIBILITY_V1 = BANNERS_V1 + '/change-visibility';

export const ACCOUNT_PAGE_BANNERS_V1 = BASE_URL + "v1/account-page-banner";
export const ACCOUNT_PAGE_BANNER_GET = ({ id }) => ACCOUNT_PAGE_BANNERS_V1 + `/${id}`;
export const ACCOUNT_PAGE_BANNER_UPDATE_PRIORITIES = ACCOUNT_PAGE_BANNERS_V1 + '/update-priorities';

export const POPUPS_V1_URL = BASE_URL + 'v1/popups';
export const POPUPS_GET_ALL_V1_URL = POPUPS_V1_URL + '/without-deleted-popup';
export const POPUPS_DELETE_V1 = ({ id }) => POPUPS_V1_URL + `/${id}`;
export const POPUPS_CHANGE_VISIBILITY_V1 = POPUPS_V1_URL + '/change-visibility';

export const AGENT_DASHBOARD_ACCESS_V1 = (agentId) => USERS_V1 + `/access/${agentId}`;

export const ORDER_MARK_AS_READ_PIPELINE_V1 = ORDER_PIPELINE_V1 + '/mark-as-read-pipeline';

export const PRODUCTS_V1_BY_PRODUCT_TAG_ID = `${PRODUCTS_V1}/by/product-tag`

export const SPECIAL_PRICE_PROMO_V1 = `${BASE_URL}v1/special-price-promo`;
export const SPECIAL_PRICE_PROMO_TYPE_V1 = (spPromoType) => SPECIAL_PRICE_PROMO_V1 + `?spPromoType=${spPromoType}`
export const SPECIAL_PRICE_PROMO_ID_V1 = (id) => `${SPECIAL_PRICE_PROMO_V1}/${id}`;
export const SPECIAL_PRICE_PROMO_UPDATE_ACTIVE_STATUS_V1 = (spPromoType) => `${SPECIAL_PRICE_PROMO_V1}/update-active-status` + `?spPromoType=${spPromoType}`;

export const WAREHOUSE_PLANOGRAM = BASE_URL + '/v1/warehouse-planogram';
export const WAREHOUSE_PLANOGRAM_TRANSFER_ALLOCATIONS = WAREHOUSE_PLANOGRAM + '/transfer-allocation-planograms';
export const WAREHOUSE_PLANOGRAM_TRANSFER_ALLOCATIONS_UPDATE = WAREHOUSE_PLANOGRAM + '/update-transfer-allocation-planograms';

export const GOODS_MOVEMENT = BASE_URL + '/v1/good-movement';

export const EXTERNAL_CHANNELS_V1 = BASE_URL + '/v1/external-channels';

export const PRODUCT_CATALOG_PROMOS_V1 = BASE_URL + '/v1/product-catalog-promos';
export const PRODUCT_CATALOG_PROMOS_V1_ID = (id) => PRODUCT_CATALOG_PROMOS_V1 + `/${id}`;
export const PRODUCT_CATALOG_PROMOS_BULK_UPLOAD_V1 = PRODUCT_CATALOG_PROMOS_V1 + '/bulk-upload';
export const PRODUCT_CATALOG_PROMOS_EXPORT_V1 = PRODUCT_CATALOG_PROMOS_V1 + '/export/product_catalog_promo.csv';
export const PRODUCT_CATALOG_PROMOS_SYNC_DATA = PRODUCT_CATALOG_PROMOS_V1 + '/sync';

export const RECIPIENT_INVENTORIES_V1 = BASE_URL + 'v1/recipient-inventories';
export const RECIPIENT_INVENTORIES_V1_ID = (id) => RECIPIENT_INVENTORIES_V1 + `/${id}`;
export const FORECAST_SAME_DAY_DELIVERY_V1 = BASE_URL + 'v1/forecast-same-day-delivery';
export const FORECAST_ITEM_RULE = BASE_URL + 'v1/forecast-item-rule';

export const COURIER_HOLIDAY_V1 = BASE_URL + 'v1/courier-holidays';
export const COURIER_HOLIDAY_BY_ID_V1 = (id) => COURIER_HOLIDAY_V1 + `/${id}`;

export const COURIER_KYC_V1 = BASE_URL + 'v1/courier-kyc';
export const COURIER_KYC_UPLOAD_V1 = (type) => COURIER_KYC_V1 + `/upload/${type}`;
export const COURIER_KYC_FEATURE_STATUS_FOR_SP_V1 = (spId) => COURIER_KYC_V1 + `/kyc-feature-status/${spId}`;
export const COURIER_KYC_EXPORT_V1 = COURIER_KYC_V1 + '/export';
export const COURIER_KYC_VERIFIES_V1 = COURIER_KYC_V1 + '/verifies';
export const COURIER_KYC_IMAGES_V1 = (id) => COURIER_KYC_V1 + `/${id}/images`;
export const COURIER_KYC_V1_ADMIN_USERS = COURIER_KYC_V1 + '/courier-kyc-config-users';

export const COURIER_ATTENDANCE_V1 = BASE_URL + 'v1/courier-attendances';
export const COURIER_ATTENDANCE_AVAILABILITY_V1 = COURIER_ATTENDANCE_V1 + '/availability';

export const REFUND_V1 = BASE_URL + '/v1/refunds';
export const REFUND_GET_ALL_METHOD_OPTIONS_V1 = REFUND_V1 + '/method-options';
export const REFUND_GET_ALL_STATUS_OPTIONS_V1 = REFUND_V1 + '/status-options';
export const REFUND_BANK_TRANSFER_EXPORT_V1 = REFUND_V1 + '/export/refund_list_bank_transfer.csv';
export const REFUND_BULK_UPDATE_BANK_TRANSFER_V1 = REFUND_V1 + '/bulk-update-bank-transfer-status';
export const REFUND_UPDATE_SEGARI_COIN_V1 = REFUND_V1 + '/update-segari-coin-status';
export const REFUND_DELETE_V1 = (refundId) => REFUND_V1 + `/${refundId}`;
export const REFUND_ADD_V1 = REFUND_V1 + '/add';
export const REFUND_GET_TEMPORARY_ELIGIBLE_METHODS = REFUND_V1 + '/get-temporary-eligible-methods';
export const ORDER_REFUND_HISTORY_V1 = (orderId) => `${REFUND_V1}/${orderId}/refund-history`;

export const INVENTORY_FULFILLMENT_DEFECTS_V1 = BASE_URL + 'v1/inventory_fulfillment_defects';
export const INVENTORY_FULFILLMENT_DEFECTS_QUANTITY_V1 = (id) => INVENTORY_FULFILLMENT_DEFECTS_V1 + `/quantity/${id}`
export const INVENTORY_FULFILLMENT_DEFECT_V1 = (id) => INVENTORY_FULFILLMENT_DEFECTS_V1 + `/${id}`

export const INVENTORY_PRODUCTION_RESTOCKS_V1 = BASE_URL + 'v1/inventory_production_restocks';
export const INVENTORY_PRODUCTION_RESTOCKS_QUANTITY_V1 = (id) => INVENTORY_PRODUCTION_RESTOCKS_V1 + `/quantity/${id}`
export const INVENTORY_PRODUCTION_RESTOCK_V1 = (id) => INVENTORY_PRODUCTION_RESTOCKS_V1 + `/${id}`

export const FLASH_SALE_WIDGET_V1 = BASE_URL + 'v1/flash-sale-widget-banners/'
export const FLASH_SALE_WIDGET_V1_CURR_IS_ACTIVE = FLASH_SALE_WIDGET_V1 + 'current-is-active/'
export const FLASH_SALE_WIDGET_V1_INT_TOOLS = FLASH_SALE_WIDGET_V1 + 'int-tools/'
export const FLASH_SALE_WIDGET_V1_INT_TOOLS_CURRENT = FLASH_SALE_WIDGET_V1_INT_TOOLS + 'current/'
export const FLASH_SALE_WIDGET_V1_INT_TOOLS_AWS_UPLOAD = FLASH_SALE_WIDGET_V1_INT_TOOLS + 'upload/'
export const FLASH_SALE_WIDGET_WAREHOUSE_GROUP_V1 = BASE_URL + 'v1/flash-sale-widget-warehouse-groups/'
export const FLASH_SALE_WIDGET_WAREHOUSE_GROUP_V1_INT_TOOLS = FLASH_SALE_WIDGET_WAREHOUSE_GROUP_V1 + 'int-tools/'

export const ORDER_QUEUE_CONFIGURATION_V1 = BASE_URL + 'v1/order-queue-configuration';
export const ORDER_QUEUE_CATEGORIES_CONFIGURATION_V1 = ORDER_QUEUE_CONFIGURATION_V1 + '/categories';
export const ORDER_QUEUE_DELIVERY_TYPE_CONFIGURATION = ORDER_QUEUE_CONFIGURATION_V1 + '/delivery-type';
export const ORDER_QUEUE_SHIPPING_POINTS_CONFIGURATION = ORDER_QUEUE_CONFIGURATION_V1 + '/shipping-points';
export const ORDER_QUEUE_SHIPPING_POINTS_AVAILABLE_CONFIGURATION = ORDER_QUEUE_CONFIGURATION_V1 + '/available-shipping-points';
export const ORDER_QUEUE_QUANTITY_THRESHOLD_CONFIGURATION = ORDER_QUEUE_CONFIGURATION_V1 + '/quantity-threshold';
export const ORDER_QUEUE_SKU_AVAILABILITY_CONFIGURATION = ORDER_QUEUE_CONFIGURATION_V1 + '/sku-availability';
export const ORDER_QUEUE_SDD = ORDER_QUEUE_CONFIGURATION_V1 + '/sdd';
export const ORDER_QUEUE_SDD_VARIANT = ORDER_QUEUE_SDD + '/variants';
export const ORDER_QUEUE_SDD_TIME_PRIORITIES = ORDER_QUEUE_CONFIGURATION_V1 + '/sdd/time-priorities';
export const ORDER_QUEUE_TIME_LIMIT_CONFIGURATION = BASE_URL + 'v1/order-queue-time-limit';
export const ORDER_QUEUE_TIME_LIMIT_DROPDOWN = ORDER_QUEUE_TIME_LIMIT_CONFIGURATION + '/schedules';
export const ORDER_QUEUE_TIME_LIMIT_DETAILS = ({ id }) => ORDER_QUEUE_TIME_LIMIT_CONFIGURATION + `/${id}/schedules/details`;
export const EDIT_ORDER_QUEUE_TIME_LIMIT_DETAILS = ORDER_QUEUE_TIME_LIMIT_CONFIGURATION + '/schedules/details';
export const PICKING_DASHBOARD = ORDER_PIPELINE_BASE_V2 + '/pick-list/dashboard';
export const PICKING_LOG = ORDER_PIPELINE_BASE_V2 + '/pick-list/log';
export const USER_USER_ROLE_DROPDOWN = USERS_V1 + '/role/dropdown';
export const USER_ROLE_SCOPE_DROPDOWN = USERS_V1 + '/role-scope/dropdown';
export const ORDER_PIPELINE_PICK_LIST_ORDER = ORDER_PIPELINE_BASE_V2 + '/pick-list-order';
export const ORDER_PIPELINE_PICK_LIST = ORDER_PIPELINE_BASE_V2 + '/pick-list';
export const ORDER_PIPELINE_PICK_LIST_ORDER_STATUS = ORDER_PIPELINE_PICK_LIST_ORDER + '/status';
export const ORDER_PIPELINE_PICK_LIST_ORDER_PICKER = ORDER_PIPELINE_PICK_LIST_ORDER + '/picker';
export const ORDER_PIPELINE_PICK_LIST_ORDER_STATUS_BY_PLID_OID = (pickListId, orderId) => `${ORDER_PIPELINE_PICK_LIST_ORDER_STATUS}/${pickListId}/${orderId}`;
export const ORDER_PIPELINE_PICK_LIST_PICKER = ORDER_PIPELINE_PICK_LIST + '/picker';

export const PICKING_DASHBOARD_CONTAINERS = ORDER_PIPELINE_BASE_V2 + '/picking-dashboard/containers';
export const PICKING_DASHBOARD_UNTAG_CONTAINERS = ORDER_PIPELINE_BASE_V2 + '/untagged-container';
export const PICKING_DASHBOARD_DETAIL = ORDER_PIPELINE_BASE_V2 + '/pick-list/detail';
export const PICKING_DASHBOARD_ORDERS = ORDER_PIPELINE_BASE_V2 + '/pick-list/orders';
export const PICKING_DASHBOARD_BY_ORDER_ID = ORDER_PIPELINE_BASE_V2 + '/pick-list/dashboard-by-order';
export const PICKING_DASHBOARD_PICKER = ORDER_PIPELINE_BASE_V2 + '/pick-list/picker-dashboard';
export const PICKING_DASHBOARD_DETAIL_ORDER_ID_ONLY = ORDER_PIPELINE_BASE_V2 + "/pick-list/detail-by-order-id";
export const PICKING_DASHBOARD_TOWER = ORDER_PIPELINE_BASE_V2 + '/container-tower/dashboard';
export const PICKING_DASHBOARD_TOWER_MERGE_CANDIDATES = ORDER_PIPELINE_BASE_V2 + '/container-tower/merge-candidates';
export const PICKING_DASHBOARD_TOWER_MERGE = ORDER_PIPELINE_BASE_V2 + '/merge-tower';
export const PICKING_DASHBOARD_TOWER_DISMANTLE = ({ id }) => ORDER_PIPELINE_BASE_V2 + `/dismantle-tower/${id}`;
export const PICKING_DASHBOARD_TOWER_DISMANTLE_PARTIAL = ORDER_PIPELINE_BASE_V2 + '/dismantle-tower-partial';
export const PICKING_DASHBOARD_TOWER_PRINT_URL = ORDER_PIPELINE_BASE_V2 + `/re-pick.pdf`
export const PICKING_DASHBOARD_OOS = ORDER_PIPELINE_BASE_V2 + '/oos/dashboard';

export const SEGARI_COIN_TRANSACTIONS_V1_URL = BASE_URL + '/v1/segari-coin-transactions';
export const SEGARI_COIN_TRANSACTIONS_CATEGORIES_V1_URL = SEGARI_COIN_TRANSACTIONS_V1_URL + '/categories';
export const SEGARI_COIN_TRANSACTIONS_BULK_UPDATE_V1_URL = SEGARI_COIN_TRANSACTIONS_V1_URL + '/bulk-add-upload';
export const SEGARI_COIN_TRANSACTIONS_EXPORT_V1_URL = SEGARI_COIN_TRANSACTIONS_V1_URL + '/export/segari_coin_transactions.csv';

export const UNIT_OF_MEASURES_V1_URL = BASE_URL + 'v1/unit-of-measures';
export const UNIT_OF_MEASURES_PRODUCT_SUGGESTION_V1 = ({ id }) => UNIT_OF_MEASURES_V1_URL + `/item-suggestion/product/${id}`;
export const UNIT_OF_MEASURES_PURCHASING_PRODUCT_SUGGESTION_V1 = ({ id }) => UNIT_OF_MEASURES_V1_URL + `/item-suggestion/purchasing_product/${id}`;
export const UNIT_OF_MEASURES_ALL_SUGGESTIONS_V1 = UNIT_OF_MEASURES_V1_URL + `/item-suggestions`;
export const UNIT_OF_MEASURES_DISTINCT_V1 = UNIT_OF_MEASURES_V1_URL + `/custom-units-distinct`;
export const BULK_UNIT_OF_MEASURES_V1_URL = UNIT_OF_MEASURES_V1_URL + `/create-bulk`;
export const DELETE_ITEM_UOM_V1 = ({ id }) => UNIT_OF_MEASURES_V1_URL + `/${id}`;

export const BULK_UPDATE_AGENT_V1 = BASE_URL + 'v1/agents/bulk-update';
export const OPERATION_LOGS_V1_URL = BASE_URL + '/v1/internal-tools-logs';
export const OPERATION_LOGS_PAGINATE_LIST_V1_URL = OPERATION_LOGS_V1_URL + '/paginate';
export const PRODUCT_LOGS_V1 = OPERATION_LOGS_V1_URL + '/create-update-product-log';
export const FRONTEND_CATEGORY_LOGS_V1 = OPERATION_LOGS_V1_URL + '/frontend-categories-log';
export const PRODUCT_RECOMMENDATION_CAROUSEL_LOGS_V1 = OPERATION_LOGS_V1_URL + '/manual-curation-log';

export const AGENT_TYPE_COMMISSION_GROUP_V1 = BASE_URL + 'v1/agent-type-commission-groups';
export const AGENT_TYPE_COMMISSION_GROUP_UPDATE = AGENT_TYPE_COMMISSION_GROUP_V1 + '/update';


export const CSV_V1_URL = BASE_URL + 'v1/csv';
export const CSV_V1_EXPORT = CSV_V1_URL + `/download/export.csv`;

export const INBOX_CAMPAIGN_V1_URL = BASE_URL + 'v1/inbox-campaign';
export const INBOX_CAMPAIGN_CREATE_UPDATE_V1 = INBOX_CAMPAIGN_V1_URL + '/create-update-campaign';
export const INBOX_CAMPAIGN_DETAIL_V1 = INBOX_CAMPAIGN_V1_URL + '/campaign-detail';
export const INBOX_CAMPAIGN_VALIDATE_V1 = INBOX_CAMPAIGN_V1_URL + '/validate-campaign';
export const INBOX_CAMPAIGN_LIST_V1 = INBOX_CAMPAIGN_V1_URL + '/list';
export const INBOX_CAMPAIGN_DELETE_V1 = ({ id }) => INBOX_CAMPAIGN_V1_URL + `/delete/${id}`;

// Delivery Order (SDD) Auto

// Cancellation
export const ORDER_DELIVERY_CANCELLATION = ORDER_DELIVERIES_V1 + '/cancellations/';
export const ORDER_DELIVERY_CANCELLATION_ORDER_ID = ORDER_DELIVERY_CANCELLATION + 'order-ids';
export const ORDER_DELIVERY_CANCELLATION_RESPONSE = ORDER_DELIVERY_CANCELLATION + 'response';
export const ORDER_DELIVERY_CANCELLATION_OVERVIEW = ORDER_DELIVERY_CANCELLATION + 'overview';
export const SDD_ORDER_DELIVERIES = BASE_URL + `v1/sdd-order-deliveries/`;
export const SDD_ORDER_DELIVERIES_GET_ACTIVE_AND_AVAILABLE_COURIER = ({ spId }) => SDD_ORDER_DELIVERIES + `shipping-points/${spId}/couriers/active-and-available`;
export const SDD_ORDER_DELIVERIES_GET_ALL_ACTIVE_AND_AVAILABLE_COURIER = SDD_ORDER_DELIVERIES + `couriers/all-active-and-available`;

export const SDD_AUTO = BASE_URL + 'v1/sdd-auto/';

// Dashboard
export const SDD_AUTO_DASHBOARD = SDD_AUTO + 'dashboard/';
export const SDD_AUTO_DASHBOARD_OVERVIEW = SDD_AUTO_DASHBOARD + 'overview';
export const SDD_AUTO_DASHBOARD_IDLE_AND_UNPICKED_UP = SDD_AUTO_DASHBOARD + 'idle-and-unpicked-up';
export const SDD_AUTO_DASHBOARD_DASHBOARD_OVERVIEW = SDD_AUTO_DASHBOARD + 'dashboard-overview';
export const SDD_AUTO_DASHBOARD_REASSIGN = SDD_AUTO_DASHBOARD + 'reassign';
export const SDD_AUTO_DASHBOARD_BULK_REASSIGN = SDD_AUTO_DASHBOARD + 'bulk-reassign';
export const SDD_AUTO_DASHBOARD_3PL_ORDER_NON_NEW_ADDRESS = SDD_AUTO_DASHBOARD + '3pl-order-non-new-address';
export const SDD_AUTO_DASHBOARD_IDLE_AND_UNPICKED_UP_EXPORT_3PL = SDD_AUTO_DASHBOARD_IDLE_AND_UNPICKED_UP + '/export/3pl';

// Metrics
export const SDD_AUTO_ASSIGNMENT = SDD_ORDER_DELIVERIES_V1 + '/auto-assignment/';
export const SDD_AUTO_ASSIGNMENT_METRICS = (spId) => SDD_AUTO_ASSIGNMENT + `${spId}/configs`;
export const SDD_AUTO_ASSIGNMENT_DEFAULT_METRICS = SDD_AUTO_ASSIGNMENT + 'default/configs';
export const SDD_AUTO_ASSIGNMENT_ADJUST_METRICS = (spId) => SDD_AUTO_ASSIGNMENT + `${spId}/adjust-config`;
export const SDD_AUTO_ASSIGNMENT_METRICS_LOGS = SDD_AUTO_ASSIGNMENT + 'config-logs';
export const SDD_AUTO_ASSIGNMENT_METRICS_LOGS_EXPORT = SDD_AUTO_ASSIGNMENT + 'export-config-logs';
export const SDD_AUTO_ASSIGNMENT_ADD_METRICS = (spId) => SDD_AUTO_ASSIGNMENT + `${spId}/add-config`

export const SDD_AUTO_ASSIGNMENT_CONFIG = `${BASE_URL}/v1/sdd-auto-assignment-configs`;
export const SDD_AUTO_ASSIGNMENT_CONFIG_GLOBAL = `${SDD_AUTO_ASSIGNMENT_CONFIG}/global`;

// Force Majeures
export const SDD_AUTO_FORCE_MAJEURES = SDD_AUTO_DASHBOARD + '/force-majeures';
export const SDD_AUTO_FORCE_MAJEURES_REQUEST_CONFIRMATION = SDD_AUTO_FORCE_MAJEURES + '/request-confirmation';
export const SDD_AUTO_FORCE_MAJEURES_BULK_REQUEST_CONFIRMATION = SDD_AUTO_FORCE_MAJEURES_REQUEST_CONFIRMATION + '/bulk';
export const SDD_AUTO_FORCE_MAJEURES_REQUEST_CONFIRMATION_WITH_ID = (id) => `${SDD_AUTO_FORCE_MAJEURES}/request-confirmation/${id}`;
export const SDD_AUTO_FORCE_MAJEURES_CANCEL_ORDER_WITH_ID = (id) => `${SDD_AUTO_FORCE_MAJEURES}/cancel-order/${id}`;
export const SDD_AUTO_FORCE_MAJEURES_CANCEL_ORDER = SDD_AUTO_FORCE_MAJEURES + '/cancel-order';
export const SDD_AUTO_FORCE_MAJEURES_ASSIGNMENT_STATUS = SDD_AUTO_FORCE_MAJEURES + '/assignment-status';
export const SDD_AUTO_FORCE_MAJEURES_CONFIRMATION_STATUS = SDD_AUTO_FORCE_MAJEURES + '/confirmation-status';

// End of Delivery Order (SDD) Auto

export const GENERAL_PICK_LIST_BASE_V1 = BASE_URL + 'v1/general-pick-lists';
export const GENERAL_PICK_LIST_V1_TYPE = GENERAL_PICK_LIST_BASE_V1 + '/type';
export const GENERAL_PICK_LIST_GET_PICKER = (pickListId) => {
    return GENERAL_PICK_LIST_BASE_V1 + `/${pickListId}/picker`
};
export const GENERAL_PICK_LIST_ASSIGN_PICKER = GENERAL_PICK_LIST_BASE_V1 + '/assign-to-picker';
export const GENERAL_PICK_LIST_CLOSE_PICK_LIST = GENERAL_PICK_LIST_BASE_V1 + '/close-picklist';
export const GENERAL_PRODUCTION_PICK_LIST = GENERAL_PICK_LIST_BASE_V1 + '/production';
export const GENERAL_PICK_LIST_REPLENISHMENT = GENERAL_PICK_LIST_BASE_V1 + '/replenishment';
export const GENERAL_PICK_LIST_TRANSFER_RM = GENERAL_PICK_LIST_BASE_V1 + '/transfer-rm';
export const GENERAL_PRODUCTION_PICK_LIST_CREATE = GENERAL_PRODUCTION_PICK_LIST + '/bulk-create-csv';
export const GENERAL_PRODUCTION_PICK_LIST_VERIFY = GENERAL_PRODUCTION_PICK_LIST + '/verify-csv';
export const GENERAL_PICK_LIST_REPLENISHMENT_CREATE = GENERAL_PICK_LIST_REPLENISHMENT + '/bulk-create-csv';
export const GENERAL_PICK_LIST_REPLENISHMENT_VERIFY = GENERAL_PICK_LIST_REPLENISHMENT + '/verify-csv';
export const GENERAL_PICK_LIST_TRANSFER_RM_VERIFY = GENERAL_PICK_LIST_TRANSFER_RM + '/verify-csv';
export const GENERAL_PICK_LIST_TRANSFER_RM_CREATE = GENERAL_PICK_LIST_TRANSFER_RM + '/bulk-create-csv';

export const GENERAL_PICK_LIST_BASE_V2 = BASE_URL + 'v2/general-pick-lists';
export const GENERAL_PICK_LIST_CREATE = GENERAL_PICK_LIST_BASE_V2 + '/create-from-csv';
export const GENERAL_PICK_LIST_VERIFY = GENERAL_PICK_LIST_BASE_V2 + '/verify-csv';
export const GENERAL_PICK_LIST_GET_BY_FILTER = GENERAL_PICK_LIST_BASE_V2 + '/';

export const GENERAL_PICK_LIST_ITEM_BULK_V1 = BASE_URL + 'v1/general-pick-list-item-bulk';

export const WAREHOUSE_ACTIVE_HOURS_V1_URL = BASE_URL + 'v1/warehouse-active-hours';
export const WAREHOUSE_DELIVERY_PROPORTION_V1_URL = BASE_URL + 'v1/warehouse-delivery-proportion';
export const UPDATE_WAREHOUSE_DELIVERY_PROPORTION_V1_URL = (id) => {
    return WAREHOUSE_DELIVERY_PROPORTION_V1_URL + `/${id}`
};

export const APPLICATION_VERSION = BASE_URL + '/v1/application-version';

export const ITEM_DIMENSIONS_V1 = BASE_URL + 'v1/item-dimensions';
export const PURCHASE_RETURN_V1 = BASE_URL + 'v1/purchase-returns';
export const PURCHASE_RETURN_UPDATE_V1 = (id) => {
    return PURCHASE_RETURN_V1 + `/${id}`
}
export const PURCHASE_RETURN_UPDATE_STATUS_V1 = (id) => {
    return PURCHASE_RETURN_V1 + `/status/${id}`
}
export const AGING_PLANOGRAM_DASHBOARD_BASE_V1 = BASE_URL + 'v1/aging-planogram-dashboard';
export const AGING_PLANOGRAM_DASHBOARD_GET = AGING_PLANOGRAM_DASHBOARD_BASE_V1 + '/';

export const WAREHOUSE_TRANSFERS_INBOUND_V1 = `${INBOUND_V1}/warehouse-transfer/`;
export const WAREHOUSE_TRANSFERS_INBOUND_V1_DOWNLOAD_CSV = (id) => `${INBOUND_V1}/download/${id}/inbounds.csv`
export const WAREHOUSE_TRANSFERS_INBOUND_V1_BULK_UPLOAD_CSV = (id) => `${INBOUND_V1}/upload/${id}`
export const WAREHOUSE_TRANSFERS_INBOUND_V1_CHECK_COMPLETABLE = (id) => `${WAREHOUSE_TRANSFERS_INBOUND_V1}/${id}/completable`
export const WAREHOUSE_TRANSFERS_INBOUND_V1_COMPLETE = (id) => `${WAREHOUSE_TRANSFERS_INBOUND_V1}/${id}/complete`

export const WAREHOUSE_TRANSFER_ALLOCATOR_V1 = BASE_URL + 'v1/warehouse-transfer-allocator';
export const WAREHOUSE_TRANSFER_ALLOCATOR_V1_LATEST_RM_ALLOCATOR_ID = WAREHOUSE_TRANSFER_ALLOCATOR_V1 + '/latest-rm-allocator-id';
export const WAREHOUSE_TRANSFER_ALLOCATOR_V1_ITEMS = WAREHOUSE_TRANSFER_ALLOCATOR_V1 + '/items';
export const WAREHOUSE_TRANSFER_ALLOCATOR_V1_RUN = WAREHOUSE_TRANSFER_ALLOCATOR_V1 + '/refresh';
export const WAREHOUSE_TRANSFER_ALLOCATOR_V1_TERMINATE = WAREHOUSE_TRANSFER_ALLOCATOR_V1 + '/callback/terminated';
export const WAREHOUSE_TRANSFER_ALLOCATOR_V1_REFRESH_RM = WAREHOUSE_TRANSFER_ALLOCATOR_V1 + '/refresh-rm';

export const THEMATIC_WIDGET_V1_BASE = BASE_URL + '/v1/internal-tools/thematic-widget';
export const THEMATIC_WIDGET_V1_WITH_ID = (id) => (THEMATIC_WIDGET_V1_BASE + `/${id}`);
export const THEMATIC_WIDGET_V1_CHANGE_LOG = (id) => (THEMATIC_WIDGET_V1_BASE + `/${id}/log`);
export const THEMATIC_WIDGET_V1_UPLOAD_BANNER = THEMATIC_WIDGET_V1_BASE + '/upload-banner';
export const THEMATIC_WIDGET_V1_GET_DROPDOWN = THEMATIC_WIDGET_V1_BASE + '/dropdown';

export const PERMANENT_BANNER_V1_BASE = BASE_URL + '/v1/internal-tools/permanent-banner';
export const PERMANENT_BANNER_V1_WITH_ID = (id) => (PERMANENT_BANNER_V1_BASE + `/${id}`);
export const PERMANENT_BANNER_V1_CHANGE_LOG = (id) => (PERMANENT_BANNER_V1_BASE + `/${id}/log`);
export const PERMANENT_BANNER_V1_GLOBAL_TITLE = PERMANENT_BANNER_V1_BASE + '/global';

export const HOMEPAGE_TAB_V1_BASE = BASE_URL + '/v1/internal-tools/homepage-tab';
export const HOMEPAGE_TAB_V1_WITH_ID = (id) => (HOMEPAGE_TAB_V1_BASE + `/${id}`);
export const HOMEPAGE_TAB_V1_CHANGE_LOG = (id) => (HOMEPAGE_TAB_V1_BASE + `/${id}/log`);

export const THEMATIC_BANNER_V1_URL = BASE_URL + '/v1/thematic-banner';

export const CAROUSEL_BANNER_V1_URL = BASE_URL + '/v1/carousel-banner';
export const CAROUSEL_BANNER_V1_EDIT = (id) => (CAROUSEL_BANNER_V1_URL + `/${id}`);
export const CAROUSEL_BANNER_V1_CHANGE_VISIBILITY = CAROUSEL_BANNER_V1_URL + '/change-visibility';
export const CAROUSEL_BANNER_V1_CHANGE_LOG = (id) => (CAROUSEL_BANNER_V1_URL + `/${id}/log`);

export const PRODUCT_SUGGESTIONS_V1_URL = BASE_URL + '/v1/product-suggestions';
export const PRODUCT_SUGGESTIONS_DOWNLOAD_V1_URL = PRODUCT_SUGGESTIONS_V1_URL + '/download';

export const ITEM_DRAFTS_V1 = BASE_URL + 'v1/product-drafts';

export const FORCE_MAJEURES_V1 = BASE_URL + '/v1/force-majeures';
export const FORCE_MAJEURES_V1_WITH_ID = (id) => `${FORCE_MAJEURES_V1}/${id}`;
export const FORCE_MAJEURES_V1_TYPES = FORCE_MAJEURES_V1 + '/types';
export const FORCE_MAJEURES_V1_SEVERITIES = (forceMajeureType) => `${FORCE_MAJEURES_V1}/severities?forceMajeureType=${forceMajeureType}`;
export const FORCE_MAJEURES_V1_TIME_RANGES = FORCE_MAJEURES_V1 + '/time-ranges';

export const MANPOWER_PRODUCTIVITY_DASHBOARD = BASE_URL + 'v1/manpower-productivity';
export const MANPOWER_PICKING_DASHBOARD = MANPOWER_PRODUCTIVITY_DASHBOARD + '/picking';
export const MANPOWER_CHECKING_DASHBOARD = MANPOWER_PRODUCTIVITY_DASHBOARD + '/checking';
export const MANPOWER_PICKER_ATTENDANCE = MANPOWER_PRODUCTIVITY_DASHBOARD + '/picker-attendance';
export const MANPOWER_PICKER_ATTENDANCE_DETAIL = MANPOWER_PRODUCTIVITY_DASHBOARD + '/detail-attendance';

export const INVENTORY_PLANOGRAM_RULES_V1_BASE = BASE_URL + 'v1/inventory-planogram-rules';
export const INVENTORY_PLANOGRAM_RULES_V1_WITH_ID = (id) => (INVENTORY_PLANOGRAM_RULES_V1_BASE + `/${id}`);

export const ITEM_LOCATION_ROUTE_V1 = BASE_URL + 'v1/item-location-route';
export const ITEM_LOCATION_ROUTE_V1_WAREHOUSE_PLANOGRAM = ITEM_LOCATION_ROUTE_V1 + '/planogram-dropdown';
export const ITEM_LOCATION_ROUTE_V1_ID = (itemLocationRouteId) => `${ITEM_LOCATION_ROUTE_V1}/${itemLocationRouteId}`;
export const ITEM_LOCATION_ROUTE_V1_VERIFY = ITEM_LOCATION_ROUTE_V1 + '/verify'
export const ITEM_LOCATION_ROUTE_V1_UPLOAD = ITEM_LOCATION_ROUTE_V1 + '/upload'
export const ITEM_LOCATION_ROUTE_V1_VERIFY_DELETE = ITEM_LOCATION_ROUTE_V1 + '/verify-delete'
export const ITEM_LOCATION_ROUTE_V1_DELETE_BY_IDS = ITEM_LOCATION_ROUTE_V1 + '/bulk-ids'

export const COD_PAYMENT = BASE_URL + 'v1/cod-payment';
export const COD_PAYMENT_IDS = COD_PAYMENT + '/ids';
export const COD_PAYMENT_COURIER_NAMES = COD_PAYMENT + '/courier-names';
export const COD_PAYMENT_PAYMENT_CONFIRMED_BY_NAMES = COD_PAYMENT + '/payment-confirmed-by-names';
export const COD_PAYMENT_PAYMENT_VERIFIED_BY_NAMES = COD_PAYMENT + '/payment-verified-by-names';
export const COD_PAYMENT_PAYMENT_CONFIRM = COD_PAYMENT + '/confirm';
export const COD_PAYMENT_EXPORT_CONFIRM = COD_PAYMENT + '/export-confirm';
export const COD_PAYMENT_BULK_CONFIRM = COD_PAYMENT + '/bulk-confirm';
export const COD_PAYMENT_PAYMENT_VERIFY = COD_PAYMENT + '/verify';
export const COD_PAYMENT_EXPORT_VERIFY = COD_PAYMENT + '/export-verify';
export const COD_PAYMENT_BULK_VERIFY = COD_PAYMENT + '/bulk-verify';

export const APOL_V1_BASE = BASE_URL + 'v1/auto-pol';
export const APOL_V1_GENERATE_POL = APOL_V1_BASE + '/generate-pol';
export const APOL_V1_ENABLED_WAREHOUSES = APOL_V1_BASE + '/warehouses'
export const APOL_V1_AUTORUN_BASE = APOL_V1_BASE + '/autorun'
export const APOL_V1_AUTORUN_GET_ACTIVE_WAREHOUSES = APOL_V1_AUTORUN_BASE + '/warehouses';
export const APOL_V1_AUTORUN_GET_PRODUCTS = APOL_V1_AUTORUN_BASE + '/products';
export const APOL_V1_AUTORUN_TOGGLE = APOL_V1_AUTORUN_BASE + '/toggle';

export const TRANSFER_ALLOCATION_V1 = BASE_URL + '/v1/transfer-allocation';

export const INTERNAL_TOOLS_LOG_BASE = BASE_URL + '/v1/internal-tools-logs';
export const INTERNAL_TOOLS_LOG_GET_PAYLOAD_DATA = INTERNAL_TOOLS_LOG_BASE + '/payload';

export const APOL_V1_GET_POL_REPORTS = APOL_V1_BASE + '/reports';
export const APOL_V1_GET_POL_REPORT = (filename) => `${APOL_V1_BASE}/report/${filename}`;

export const APOL_V1_APOL_COEFFICIENT = APOL_V1_BASE + '/stock-calculation-coefficients';

export const PROMO_LABELS_V1 = BASE_URL + "/v1/promo-labels"
export const PROMO_LABELS_CHANGE_ACTIVITY_V1 = PROMO_LABELS_V1 + '/change-activity';

export const COURIER_NOTIFICATION = BASE_URL + "v1/courier-notification";
export const COURIER_NOTIFICATION_SEARCH = COURIER_NOTIFICATION + "/search";

//Global configs
export const GLOBAL_CONFIG_V1_URL = BASE_URL + 'v1/global-configs';

export const PRODUCT_RECOMMENDATION_CAROUSEL = BASE_URL + 'v1/product-recommendation-carousel';
export const DELETE_PRODUCT_RECOMMENDATION_CAROUSEL = ({ id }) => BASE_URL + 'v1/product-recommendation-carousel/' + id;
export const EDIT_PRODUCT_RECOMMENDATION_CAROUSEL = (id) => PRODUCT_RECOMMENDATION_CAROUSEL + `/edit/${id}`;
export const CURRENT_ACTIVE_PRODUCT_RECOMMENDATION_CAROUEL = PRODUCT_RECOMMENDATION_CAROUSEL + '/customer/current-active';
export const DOWNLOAD_PRODUCT_RECOMMENDATION_CAROUSEL = PRODUCT_RECOMMENDATION_CAROUSEL + '/download';


export const PRODUCT_VARIANT_V1_URL = BASE_URL + "v1/product-variant";
export const PRODUCT_VARIANT_PRODUCT_GROUP_V1_URL = (id) => PRODUCT_VARIANT_V1_URL + "/product-group/" + id;
export const PRODUCT_VARIANT_VALIDATE_PRODUCT_VARIANTS_V1_URL = PRODUCT_VARIANT_V1_URL + "/validate/product-variants";

export const PRODUCT_GROUP_V1_URL = BASE_URL + "v1/product-group";
export const PRODUCT_GROUP_DELETE_V1_URL = PRODUCT_GROUP_V1_URL + "/delete";

export const AGENT_PRICE_GROUP_PRODUCT_BUNDLE = BASE_URL + "v1/agent-price-group-product-bundle"
export const AGENT_PRICE_GROUP_PRODUCT_BUNDLE_BY_APGS_PRODUCTS = AGENT_PRICE_GROUP_PRODUCT_BUNDLE + "/apgs-products"
export const AGENT_PRICE_GROUP_PRODUCT_BUNDLE_VALIDATE_CSV = AGENT_PRICE_GROUP_PRODUCT_BUNDLE + "/validate-csv"
export const AGENT_PRICE_GROUP_PRODUCT_BUNDLE_SUBMIT_QUEUE = AGENT_PRICE_GROUP_PRODUCT_BUNDLE + "/bulk-update-queue"
export const AGENT_PRICE_GROUP_PRODUCT_BUNDLE_BULK_LOGS = AGENT_PRICE_GROUP_PRODUCT_BUNDLE + "/log-history"

export const PRODUCT_BUNDLE_V1_URL = BASE_URL + "v1/product-bundle";
export const PRODUCT_BUNDLE_V1_URL_BUNDLED_PRODUCTS = PRODUCT_BUNDLE_V1_URL + "/bundled-products";


export const OOS_QUOTA_V1 = BASE_URL + 'v1/oos-quota';
export const OOS_QUOTA_QUANTITY_V1 = OOS_QUOTA_V1 + '/quantity';
export const OOS_QUOTA_DASHBOARD_V1 = OOS_QUOTA_V1 + '/dashboard';

export const OOS_QUOTA_HISTORY_V1 = OOS_QUOTA_V1 + '/history';
export const OOS_QUOTA_HISTORY_DETAIL_V1 = (id) => (OOS_QUOTA_HISTORY_V1 + `/${id}`);

export const FAILED_DELIVERY_REASON_V1 = BASE_URL + 'v1/failed-delivery-reason';
export const FAILED_DELIVERY_REASON_WITH_TRASH_V1 = FAILED_DELIVERY_REASON_V1 + '/with-trash';

export const USER_DEVICES_V1 = BASE_URL + 'v1/user-devices';
export const USER_DEVICES_DROPDOWN_V1 = USER_DEVICES_V1 + '/dropdown';

export const ORDER_BLACKLISTED_PARAM_V1 = BASE_URL + 'v1/order-blacklisted-params';

export const PAYMENT_METHOD_CONFIGURATION_V1 = BASE_URL + 'v1/payment-method-config'
export const PAYMENT_METHOD_CONFIGURATION_BY_LIMIT_AND_DELIVERY_SERVICE_V1 = PAYMENT_METHOD_CONFIGURATION_V1 + '/by-limit-delivery-service'

// Sales Forecast
export const SALES_FORECAST = BASE_URL + 'v1/sales-forecast'
export const SALES_FORECAST_PRODUCTION_TYPES = SALES_FORECAST + '/production-types';

// Forecast Baseline
export const FORECAST_BASELINE = BASE_URL + 'v1/forecast-baseline';
export const CALCULATE_MEDIAN_FORECAST_BASELINE = FORECAST_BASELINE + '/calculate';
export const PRODUCT_HISTORICAL_FORECAST_BASELINE = FORECAST_BASELINE + '/historical';
export const GENERATE_SALES_FORECAST_BASELINE = FORECAST_BASELINE + '/generate/sales-forecast';

// Production Max
export const PRODUCTION_MAX = BASE_URL + 'v1/production-max'
export const PRODUCTION_MAX_DETAIL = (id) => (PRODUCTION_MAX + `/${id}`);
export const PRODUCTION_MAX_BULK_UPLOAD = PRODUCTION_MAX + '/upload';

// Production Man Power
export const PRODUCTION_MANPOWER_ADJUSTMENT = BASE_URL + 'v1/manpower-adjustment-max'
export const PRODUCTION_MANPOWER_DETAIL = (id) => (PRODUCTION_MANPOWER_ADJUSTMENT + `/${id}`);

// Supply Schedule

export const SUPPLY_LEAD_TIME_V1 = BASE_URL + 'v1/supply-lead-time'
export const SUPPLY_LEAD_TIME_BULK_V1 = SUPPLY_LEAD_TIME_V1 + '/bulk'
export const SUPPLY_LEAD_TIME_BULK_PREVIEW_V1 = SUPPLY_LEAD_TIME_BULK_V1 + '/preview'
export const UPDATE_SUPPLY_LEAD_TIME_V1 = (id) => SUPPLY_LEAD_TIME_V1 + `/${id}`

export const SUPPLY_DELIVERY_SCHEDULE_V1 = BASE_URL + 'v1/supply-delivery-schedule'
export const SUPPLY_DELIVERY_SCHEDULE_CREATE_V1 = SUPPLY_DELIVERY_SCHEDULE_V1 + '/create'
export const SUPPLY_DELIVERY_SCHEDULE_BULK_V1 = SUPPLY_DELIVERY_SCHEDULE_V1 + '/bulk'
export const SUPPLY_DELIVERY_SCHEDULE_BULK_DELETE_V1 = SUPPLY_DELIVERY_SCHEDULE_V1 + '/bulk-delete'
export const SUPPLY_DELIVERY_SCHEDULE_BULK_PREVIEW_V1 = SUPPLY_DELIVERY_SCHEDULE_BULK_V1 + '/preview'
export const DELETE_SUPPLY_DELIVERY_SCHEDULES_V1 = (warehouseSupplyMappingId) => SUPPLY_DELIVERY_SCHEDULE_V1 + `/${warehouseSupplyMappingId}`
export const DELETE_SUPPLY_DELIVERY_SCHEDULES_SKU_LEVEL_V1 = (warehouseSupplyMappingId, productSKUId) => SUPPLY_DELIVERY_SCHEDULE_V1 + `/${warehouseSupplyMappingId}/${productSKUId}`
export const UPDATE_SUPPLY_DELIVERY_SCHEDULES_V1 = (warehouseSupplyMappingId) => SUPPLY_DELIVERY_SCHEDULE_V1 + `/${warehouseSupplyMappingId}`

//Internal User
export const INTERNAL_USER_V1 = BASE_URL + 'v1/internal-users';
export const CREATE_INTERNAL_USER_V1 = INTERNAL_USER_V1 + '/create';
export const UPDATE_INTERNAL_USER_V1 = INTERNAL_USER_V1 + '/update';

export const BULK_INTERNAL_USER_V1 = INTERNAL_USER_V1 + '/bulk';

// Category Keywords
export const CATEGORY_KEYWORDS_V1 = BASE_URL + '/v1/category-keywords';
export const CATEGORY_KEYWORD_LOGS_V1 = CATEGORY_KEYWORDS_V1 + '/log'
export const CATEGORY_KEYWORDS_V1_KEYWORD_ID = ({ id, categoryLevel }) => CATEGORY_KEYWORDS_V1 + `/keyword/${categoryLevel}/${id}`;
export const CATEGORY_KEYWORDS_V1_BULK_CREATE = CATEGORY_KEYWORDS_V1 + '/bulk-create';
export const CATEGORY_KEYWORDS_V1_BULK_DELETE = ({ keyword, categoryLevel }) => CATEGORY_KEYWORDS_V1 + `/bulk-delete/${categoryLevel}/${keyword}`

export const SDD_COURIER_FEE_RULE_V1 = BASE_URL + 'v1/sdd-courier-fee-rules';
export const SDD_COURIER_FEE_RULE_UPLOAD_V1 = SDD_COURIER_FEE_RULE_V1 + '/upload';
export const SDD_COURIER_FEE_RULE_EXPORT_V1 = SDD_COURIER_FEE_RULE_V1 + '/export';

// Order fees
export const ADDITIONAL_FEE_URL = BASE_URL + '/v1/fee';
export const ORDER_FEES_URL = (orderId) => ADDITIONAL_FEE_URL + `/${orderId}`;

export const V1_CUSTOMERS = 'v1/customers';

const CUSTOMERS_ADDRESSES = V1_CUSTOMERS + '/addresses'

export const CUSTOMER_ADDRESSES_BULK_UPDATE_REMINDER = BASE_URL + CUSTOMERS_ADDRESSES + '/bulk-upload-block-order-confirmation';

export const CUSTOMER_ADDRESSES_GET_BLOCKED = BASE_URL + CUSTOMERS_ADDRESSES + '/blocked-addresses';

export const CUSTOMER_ADDRESSES_SET_FALSE_BLOCKED = (customerAddressId) => `${BASE_URL}${CUSTOMERS_ADDRESSES}/set-blocked-order-confirmation-false/${customerAddressId}`
// Menu Page
export const MENU_PAGE = BASE_URL + '/v1/menu-page';

export const PAYMENT_CAP_CONFIG_V1 = BASE_URL + 'v1/payment-cap-config';
export const PAYMENT_CAP_CONFIG_V1_ID = (id) => PAYMENT_CAP_CONFIG_V1 + `/${id}`;
export const ORDER_PAYMENT_CAP_FAILED_ATTEMPT_LOG_V1 = BASE_URL + 'v1/order-payment-cap-failed-attempt-logs';

// Label Group
export const LABEL_GROUP = BASE_URL + '/v1/label-group'


// Expiration Date
export const EXPIRATION_DATE = BASE_URL + '/v1/products/expiration-date'

// Label Sequence
export const LABEL_SEQUENCE = BASE_URL + '/v1/label-sequence';
export const LABEL_OPTIONS = LABEL_SEQUENCE + '/label-options';
export const LABEL_SEQUENCE_DATA_BY_TAB_ID = (id) => (`${LABEL_SEQUENCE}/tab/${id}`);
export const LABEL_SEQUENCE_DATA_BY_FRONTEND_SUBCATEGORY_ID = (id) => (`${LABEL_SEQUENCE}/frontend-subcategory/${id}`);
export const LABEL_SEQUENCE_DATA_BY_THEMATIC_WIDGET = (id) => (`${LABEL_SEQUENCE}/thematic-widget/${id}`);

// Autocomplete Keyword
export const AUTOCOMPLETE_KEYWORD_INTERNAL_V1 = BASE_URL + '/v1/autocomplete-keyword/internal';
export const AUTOCOMPLETE_KEYWORD_CONFIG_V1 = BASE_URL + '/v1/autocomplete-keyword-config';
export const AUTOCOMPLETE_KEYWORD_LOG_V1 = BASE_URL + '/v1/autocomplete-keyword-log';

// Package
export const PACKAGE_V1 = BASE_URL + '/v1/packages';
export const PACKAGE_V1_WITH_ID = (id) => (PACKAGE_V1 + `/${id}`);
export const PACKAGE_TYPE_V1 = BASE_URL + '/v1/packages/types';

export const STOCK_GROUP_V1 = BASE_URL + '/v1/stock-groups';
export const STOCK_GROUP_MAPPING_V1 = STOCK_GROUP_V1 + '/mapping';
export const STOCK_GROUP_MAPPING_V1_WITH_ID = (id) => STOCK_GROUP_MAPPING_V1 + `/${id}`;
export const STOCK_GROUP_MAPPINGS_BULK_V1 = STOCK_GROUP_MAPPING_V1 + '/bulk';
export const STOCK_GROUP_MAPPINGS_BULK_PREVIEW_V1 = STOCK_GROUP_MAPPINGS_BULK_V1 + '/preview';

// Multi Batch Picklist
export const MULTI_BATCH_PICKLIST_V1 = BASE_URL + '/v1/multi-batch-pick-list'

export const CACHE_CRON = BASE_URL + '/v1/cache-cron';
export const CACHE_FUNCTION = (prefix, id) => (`${CACHE_CRON}/update-cache-function/${prefix}/${id}`);
export const CACHE_QUERY = CACHE_CRON + '/update-cache-query';
//Sticky Voucher
export const STICKY_VOUCHER_V1 = PROMO_V1 + '/sticky-voucher'
export const STICKY_VOUCHER_DATA = (id) => (`${STICKY_VOUCHER_V1}/${id}`);
export const STICKY_VOUCHER_LIST = `${STICKY_VOUCHER_V1}/all`

// Push Notifications
export const PUSH_NOTIFICATION_V1 = BASE_URL + '/v1/push-notifications';
export const UPLOAD_IMAGE_V1 = PUSH_NOTIFICATION_V1 + '/upload-image';


//Order Fraud Config
export const ORDER_FRAUD_CONFIG = BASE_URL + '/v1/order-fraud-configs'
export const ORDER_FRAUD_CONFIG_CHANGE_STATUS = (id) => ORDER_FRAUD_CONFIG + `/${id}`

// Special Keywords
export const SPECIAL_KEYWORDS_V1 = BASE_URL + '/v1/special-keywords';
export const SPECIAL_KEYWORD_LOGS_V1 = SPECIAL_KEYWORDS_V1 + '/log';
export const SPECIAL_KEYWORDS_V1_SPECIAL_KEYWORD_ID_V1 = ({ id }) => SPECIAL_KEYWORDS_V1 + `/special-keyword/${id}`;
export const SPECIAL_KEYWORDS_V1_BULK_CREATE = SPECIAL_KEYWORDS_V1 + '/bulk-create';
export const SPECIAL_KEYWORDS_V1_BULK_DELETE = ({ keyword }) => SPECIAL_KEYWORDS_V1 + `/bulk-delete/${keyword}`;
export const FRONTEND_CATEGORY_V1_SPECIAL = FRONTEND_CATEGORY_V1 + '/special';
export const PRODUCT_SPECIAL_KEYWORD_V1 = PRODUCTS_V1_1 + '/special-keyword';

// Order Complaint
export const ORDER_COMPLAINT_V1 = BASE_URL + 'v1/order-complaint';
export const ORDER_COMPLAINT_SIMULATION_CONFIG = ORDER_COMPLAINT_V1 + '/config';
export const ORDER_COMPLAINT_DETAIL_V1 = ({ id }) => ORDER_COMPLAINT_V1 + `/${id}`;
export const SAVE_ORDER_COMPLAINT_V1 = ({ id }) => ORDER_COMPLAINT_V1 + `/save/${id}`;

// AutoPOL Triggers
export const AUTO_PRODUCT_ORDER_LIMIT_TRIGGERS_V_1 = BASE_URL + 'v1/auto-product-order-limit-triggers';
export const AUTO_PRODUCT_ORDER_LIMIT_TRIGGER_NAMES_V_1 = AUTO_PRODUCT_ORDER_LIMIT_TRIGGERS_V_1 + '/trigger-names';
export const AUTO_PRODUCT_ORDER_LIMIT_TRIGGERS_V_1_WITH_ID = (id) => BASE_URL + `v1/auto-product-order-limit-triggers/${id}`;

// Fulfillment Dashboard
export const FULFILLMENT_DASHBOARD = ORDER_PIPELINE_BASE_V2 + '/fulfillment/dashboard';
export const RETURN_INBOUND_V1 = BASE_URL + 'v1/return-inbound';
export const ORDER_DETAIL_FULFILLMENT_DASHBOARD = FULFILLMENT_DASHBOARD + '/order-details';

//Multilevel Agent Commission
export const COMMISSION_SCHEME_V1 = BASE_URL + 'v1/commission-goal';
export const COMMISSION_SCHEME_V1_BASIC_SCHEME = COMMISSION_SCHEME_V1 + '/basic-scheme';

//MMT
export const FLEET_V1 = BASE_URL + 'v1/fleet';
export const FLEET_QR_PDF = ({ ids, printType }) => `${BASE_URL}web/inventory/midmile-transfer/fleet-qr.pdf?printType=${printType}&ids=${ids}`;

export const MIDMILE_TRANSFER_DASHBOARD = BASE_URL + 'v1/midmile-transfers/dashboard';
export const MIDMILE_TRANSFER_TRAVEL_DOCUMENT = (mtfId) => `${BASE_URL}web/inventory/midmile-transfer/${mtfId}/surat-jalan.pdf`;

//
export const WORKPLACE_DEPARTMENT = BASE_URL + 'v1/workplace-department';
export const WORKPLACE_DEPARTMENT_RELATION = (workplaceDepartmentType) => `${WORKPLACE_DEPARTMENT}/relation/${workplaceDepartmentType}`;
export const MANPOWER_DATABASE = BASE_URL + 'v1/manpower-database'
export const MANPOWER_DATABASE_EMPLOYMENT = BASE_URL + 'v1/manpower-database/employment';
export const MANPOWER_DATABASE_PERSONAL = BASE_URL + 'v1/manpower-database/personal';
export const WAREHOUSE_WORKPLACE_DEPARTMENT = BASE_URL + '/v1/warehouse-workplace-department';
export const WAREHOUSE_WORKPLACE_DEPARTMENT_ID = (workplaceDepartmentId) => `${BASE_URL}/v1/warehouse-workplace-department/${workplaceDepartmentId}`;

export const EMPLOYEE_RECORDS = BASE_URL + 'v1/employee-record';

export const GET_EMPLOYEE_RECORDS = `${EMPLOYEE_RECORDS}/internal-user-id`;

// Subscription
export const SUBSCRIPTION_V1 = BASE_URL + 'v1/subscriptions';
export const SUBSCRIPTION_V1_TIERS = SUBSCRIPTION_V1 + '/tiers';
export const SUBSCRIPTION_V1_VOUCHERS = SUBSCRIPTION_V1 + '/vouchers';
export const SUBSCRIPTION_V1_VOUCHERS_CODE = (promoCode) => SUBSCRIPTION_V1_VOUCHERS + `/${promoCode}`;
export const SUBSCRIPTION_V1_OPTION_SETTINGS = SUBSCRIPTION_V1 + '/internal-option-settings';
export const SUBSCRIPTION_V1_FREE_TRIAL_REFERENCE = SUBSCRIPTION_V1 + '/free-trial-reference';
export const SUBSCRIPTION_V1_TERMS_AND_CONDITIONS = SUBSCRIPTION_V1 + '/terms-and-conditions';
export const SUBSCRIPTION_V1_MIGRATE_RECURRING = SUBSCRIPTION_V1 + '/migrate-recurring';
export const SUBSCRIPTION_V1_INTERNAL_SUMMARIES = SUBSCRIPTION_V1 + '/internal-summaries';
export const SUBSCRIPTION_PAYMENT_METHOD_CONFIG_V1 = SUBSCRIPTION_V1 + '/payment-methods';
export const SUBSCRIPTION_PAYMENT_METHOD_CONFIG_V1_ID = (id) => SUBSCRIPTION_PAYMENT_METHOD_CONFIG_V1 + `/${id}`;
export const SUBSCRIPTION_PAYMENT_METHOD_CONFIG_INTERNAL_LOGS = INTERNAL_TOOLS_LOG_BASE + '/subscription-payment-method-config-log';
export const SUBSCRIPTION_V1_GLOBAL_SLOGAN = SUBSCRIPTION_V1 + '/global-slogan';

// Courier Incentive Config
export const COURIER_INCENTIVE_CONFIG_V1 = BASE_URL + 'v1/courier-incentive-config';
export const COURIER_INCENTIVE_CONFIG_NAME_DROPDOWN_V1 = COURIER_INCENTIVE_CONFIG_V1 + '/dropdown/config-names';
export const COURIER_INCENTIVE_CONFIG_BULK_CREATE_V1 = COURIER_INCENTIVE_CONFIG_V1 + '/upload';
export const COURIER_INCENTIVE_CONFIG_DELETE_V1 = ({ id }) => COURIER_INCENTIVE_CONFIG_V1 + `/delete/${id}`;
export const COURIER_INCENTIVE_CONFIG_EXPORT_V1 = COURIER_INCENTIVE_CONFIG_V1 + '/export';
export const COURIER_INCENTIVE_EXPECTED_FULFILLMENT_TIME_V1 = COURIER_INCENTIVE_CONFIG_V1 + '/expected-fulfillment-time';
export const COURIER_INCENTIVE_UPDATE_EXPECTED_FULFILLMENT_TIME_V1 = ({ time }) => COURIER_INCENTIVE_EXPECTED_FULFILLMENT_TIME_V1 + `/time-in-second/${time}`;

// Courier Fee
export const COURIER_FEE_V1 = BASE_URL + 'v1/courier-fee';
export const COURIER_FEE_DETAILED_BY_V1 = ({ id }) => COURIER_FEE_V1 + `/${id}`;

//Shipping Point Global Config
export const SHIPPING_POINT_GLOBAL_CONFIG_V1 = BASE_URL + 'v1/shipping-point-global-configs';
export const SHIPPING_POINT_GLOBAL_CONFIG_BY_SP_AND_CONFIGS_V1 = ({ shippingPointId, configs }) => SHIPPING_POINT_GLOBAL_CONFIG_V1 + `/sp/${shippingPointId}/keys/${configs}`;
export const SHIPPING_POINT_GLOBAL_CONFIG_UPDATE_BY_SP_AND_CONFIGS_V1 = ({ id }) => `${SHIPPING_POINT_GLOBAL_CONFIG_V1}/sp/${id}`;
export const SHIPPING_POINT_GLOBAL_CONFIG_LOG_V1 = ({ id }) => `${SHIPPING_POINT_GLOBAL_CONFIG_V1}/log/sp/${id}`;

// Product Bulking
export const PRODUCT_BULKING_V1 = BASE_URL + 'v1/product-bulkings';
export const PRODUCT_BULKING_V1_WITH_ID = ({ id }) => PRODUCT_BULKING_V1 + `/${id}`;
export const PRODUCT_BULKING_PRICE_DIFF = PRODUCT_BULKING_V1 + '/price-diff';
export const PRODUCT_BULKING_V1_BULK_UPLOAD = (action) => PRODUCT_BULKING_V1 + `/bulk-upload/${action}`;
export const PRODUCT_BULKING_V1_EXPORT_DATA = PRODUCT_BULKING_V1 + '/export-data';

// Rule Builder Dashboard
export const CUSTOMER_RULE_BUILDER = BASE_URL + 'v1/customer-rule-builder';
export const CUSTOMER_RULE_BUILDER_CAMPAIGNS = CUSTOMER_RULE_BUILDER + '/campaigns';
export const CUSTOMER_RULE_BUILDER_CAMPAIGN = (id) => CUSTOMER_RULE_BUILDER_CAMPAIGNS + `/${id}`;
export const CUSTOMER_RULE_BUILDER_CAMPAIGN_RULESET = (id) => CUSTOMER_RULE_BUILDER_CAMPAIGN(id) + '/rulesets'
export const CUSTOMER_RULE_BUILDER_ENABLE_CAMPAIGN = (id) => CUSTOMER_RULE_BUILDER_CAMPAIGN(id) + '/update-enable-status'
export const CUSTOMER_RULE_BUILDER_COPY_CAMPAIGN = (id) => CUSTOMER_RULE_BUILDER_CAMPAIGN(id) + '/copy'
export const CUSTOMER_RULE_BUILDER_RULESET = CUSTOMER_RULE_BUILDER_CAMPAIGNS + '/rulesets'
export const CUSTOMER_RULE_BUILDER_RULESET_ENVIRONMENT = CUSTOMER_RULE_BUILDER_RULESET + '/environment'
export const CUSTOMER_RULE_BUILDER_AUDIENCE = CUSTOMER_RULE_BUILDER + '/audiences'
export const CUSTOMER_RULE_BUILDER_AUDIENCE_MEMBER = (id) => CUSTOMER_RULE_BUILDER_AUDIENCE + `/${id}/members`
export const CUSTOMER_RULE_BUILDER_AUDIENCE_DOWNLOAD = (id) => CUSTOMER_RULE_BUILDER_AUDIENCE + `/${id}/download`
export const CUSTOMER_RULE_BUILDER_EFFECTS = CUSTOMER_RULE_BUILDER + '/effects'
export const CUSTOMER_RULE_BUILDER_CUSTOMER_PROFILES = CUSTOMER_RULE_BUILDER + '/customer-profiles'
export const CUSTOMER_RULE_BUILDER_CUSTOMER_PROFILE_DETAILS = (id)=> CUSTOMER_RULE_BUILDER_CUSTOMER_PROFILES + `/${id}`

// Raw Material Request
export const RAW_MATERIAL_REQUEST = BASE_URL + 'v1/raw-material-request';

//PRODUCT TAG GROUP
export const PRODUCTION_TAG_GROUP =  BASE_URL + 'v1/production-tag-group'


// WAREHOUSE TAG GROUP
export const WAREHOUSE_TAG_GROUP =  BASE_URL + 'v1/warehouse-tag-group'

// Warehouse Global Config
export const WAREHOUSE_GLOBAL_CONFIG_V1 = BASE_URL + 'v1/warehouse-global-configs';
export const WAREHOUSE_PACKER_VALIDATION_CONFIG = WAREHOUSE_GLOBAL_CONFIG_V1 + '/packer-validation';


// QUALITY CONTROL PRODUCTS
export const QUALITY_CONTROL_PRODUCT_V1 = BASE_URL + 'v1/quality-control-product'
export const QUALITY_CONTROL_PRODUCT_V1_CUSTOMER_FEEDBACK = BASE_URL + 'v1/quality-control-product/customer-feedback'
export const QUALITY_CONTROL_PRODUCT_V1_COMPLAINT_UPLOAD = BASE_URL + 'v1/quality-control-product/complaint-upload'

// SUPPLIER PROMO CONFIRMATIONS
export const SUPPLIER_PROMO_CONFIRMATION_V1 = BASE_URL + 'v1/suppliers/promo-confirmation'
export const SUPPLIER_PROMO_CONFIRMATION_VERSIONS = SUPPLIER_PROMO_CONFIRMATION_V1 + '/versions';
export const SUPPLIER_PROMO_CONFIRMATION_APPROVED = SUPPLIER_PROMO_CONFIRMATION_V1 + '/approved-list';
export const SUPPLIER_PROMO_CONFIRMATION_ID_VERSIONS = (id) => (`${SUPPLIER_PROMO_CONFIRMATION_V1}/documentId/${id}`);
export const SUPPLIER_PROMO_CONFIRMATION_APPROVAL = (id) => SUPPLIER_PROMO_CONFIRMATION_V1 + `/approve/${id}`;
export const SUPPLIER_PROMO_CONFIRMATION_STATUS_UPDATE = (id, status) => SUPPLIER_PROMO_CONFIRMATION_V1 + `/${id}/status?status=${status}`;
export const SUPPLIER_PROMO_CONFIRMATION_DELETE = (id) => SUPPLIER_PROMO_CONFIRMATION_V1 + `/delete/${id}`;
export const SUPPLIER_PROMO_CONFIRMATION_SELL_OUT = SUPPLIER_PROMO_CONFIRMATION_V1 + '/sell-out';
export const SUPPLIER_PROMO_CONFIRMATION_INVOICE = SUPPLIER_PROMO_CONFIRMATION_V1 + '/invoice';
export const SUPPLIER_PROMO_CONFIRMATION_INVOICE_ID = (id) => (`${SUPPLIER_PROMO_CONFIRMATION_INVOICE}/${id}`);
export const SUPPLIER_PROMO_CONFIRMATION_INVOICE_PDF = (id) => (`${SUPPLIER_PROMO_CONFIRMATION_V1}/invoice.pdf?id=${id}`);
export const SUPPLIER_PROMO_CONFIRMATION_PDF = (id) => (`${SUPPLIER_PROMO_CONFIRMATION_V1}/supplier_promo_confirmation.pdf?id=${id}`);

// SHIPMENT
export const SHIPMENT_V1 = BASE_URL + 'v1/shipment';
export const SHIPMENT_BY_ID_V1 = (id) => BASE_URL + `/v1/shipment/${id}`;
export const SHIPMENT_ORDERS_BY_SHIPMENT_ID_V1 = (id) => SHIPMENT_BY_ID_V1(id) + '/orders';
export const WAREHOUSE_TRANSFERS_BY_SHIPMENT_ID_V1 = (id) => SHIPMENT_BY_ID_V1(id) + '/warehouse-transfer';
export const SHIPMENT_GENERAL_PICKLIST_IS_OPEN_V1 = SHIPMENT_V1 + '/general-pick-list/is-open';
export const SHIPMENT_ORDER_BY_ID_V1 = (id) => SHIPMENT_V1 + `/order/${id}`;
export const SHIPMENT_WAREHOUSE_GROUPS_BY_SHIPMENT_ID_V1 = (id) => SHIPMENT_BY_ID_V1(id) + `/warehouse-group`;
export const SHIPMENT_WAREHOUSE_GROUP_BY_ID_V1 = (id) => SHIPMENT_V1 + `/warehouse-group/${id}`;
export const SHIPMENT_ORDER_GENERAL_PICKLIST_BY_ID_V1 = (id) => SHIPMENT_ORDER_BY_ID_V1(id) + '/general-pick-list';
export const SHIPMENT_ORDER__EXPORT_GENERAL_PICKLIST_BY_ID_V1 = (id) => SHIPMENT_ORDER_BY_ID_V1(id) + '/general-pick-list/export';
