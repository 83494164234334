import React from 'react';
import styles from "./style.module.scss";
import "./ShipmentPage.css"
import CustomTable from '../../../../components/CustomTable/CustomTable.js';
import { SHIPMENT_TABLE_COLUMNS } from './ShipmentTableColumns.js';

const _ = require('lodash');
const ShipmentTable = (props) => {
    const { shipmentList, setSelectedShipmentId, setSelectedShipmentName } = props;

    return <div className={styles.tableTest}>
        <CustomTable
            columns ={SHIPMENT_TABLE_COLUMNS({setSelectedShipmentId: setSelectedShipmentId, setSelectedShipmentName: setSelectedShipmentName})}
            data={shipmentList}
        />
    </div>
}

export default ShipmentTable;