import React, { useEffect } from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./style.module.scss";

import { IoIosInformationCircleOutline } from "react-icons/io";
import moment from 'moment';
import { TIMEZONE } from '../../../../constants/time.js';
import PaginationBootstrap from '../../../../components/Pagination/PaginationBootstrap.js';
import { getRunStatusWidget } from './Components/RunStatusWidget.js';

const _ = require('lodash');
const ShipmentDetailOrdersListSection = (props) => {
    const { show, setSelectedShipmentOrderId, shipmentOrders, fetchShipmentOrders, pageData } = props;

    useEffect(() => {
        fetchShipmentOrders(1);
    }, [show])

    const generateRows = () => {
        return shipmentOrders.map(so => {
            return ShipmentDetailOrderRow(so, setSelectedShipmentOrderId);
        })
    }

    return (
        <div className='overflow-auto' style={{maxHeight: '60vh'}}>
            <table className={styles.borderlessTable}>
                <thead>
                    <tr>
                        <th>WAKTU MULAI</th>
                        <th>RUN STATUS</th>
                        <th style={{width: '12%'}}>JUMLAH REQUEST</th>
                        <th style={{width: '12%'}}>JUMLAH TERALOKASI</th>
                        <th>PICKLIST STATUS 
                            <OverlayTrigger
                                    placement="bottom"
                                    overlay={(props) => {
                                        return <Tooltip id="button-tooltip" {...props}>
                                            Picklist dengan status ongoing dan open akan terhitung <span style={{color: '#FF6868'}}>belum selesai</span>, status completed dan closed akan terhitung <span style={{color: '#86D293'}}>selesai</span>
                                        </Tooltip>
                                    }}
                            >
                                <IoIosInformationCircleOutline size={18} className='ml-1 mb-1'/>
                            </OverlayTrigger>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { generateRows() }
                </tbody>
            </table>
            <div className="d-flex justify-content-center mt-3">
                <PaginationBootstrap
                    currentPage={pageData.currentPage}
                    itemsCount={pageData.totalElements}
                    itemsPerPage={pageData.size}
                    setCurrentPage={fetchShipmentOrders}
                    alwaysShown={false}
                    styled={true}
                />
            </div>
        </div>
    )
}

const ShipmentDetailOrderRow = (shipmentOrder, setSelectedShipmentOrderId) => {
    const getPicklistQuantityWidgets = (openPicklistQuantity, closedPicklistQuantity) => {
        return <div>
            <div className= 'py-1 px-2 mr-1' style={{borderRadius: '4px', color: '#005A00', backgroundColor: '#F2F7F2', display: 'inline-block'}}>
                {closedPicklistQuantity}
            </div>
            <div className= 'py-1 px-2' style={{borderRadius: '4px', color: '#E65C5C', backgroundColor: '#FFEDED', display: 'inline-block'}}>
                {openPicklistQuantity}
            </div>
        </div>
    }

    const toFormattedNumber = (number) => {
        const num = number;

        return num.toLocaleString();
    }

    return <tr className={styles.hoverable} onClick={(e) => { setSelectedShipmentOrderId(shipmentOrder.id) }}>
        <td>
            <div> { moment(shipmentOrder.runStartAt).tz(TIMEZONE).format('DD/MM/YYYY') + ' pada ' + moment(shipmentOrder.runStartAt).tz(TIMEZONE).format('HH:mm')} </div>
            { <div className={styles.subText}>Durasi: {shipmentOrder.duration >= 1 ? shipmentOrder.duration : '< 1'} menit </div> }
        </td>
        <td>
            { getRunStatusWidget(shipmentOrder.status) }
        </td>
        <td>
            { toFormattedNumber(shipmentOrder.requestedQuantity) }
            <div className={styles.subText}>Quantity</div>
        </td>
        <td>
            { toFormattedNumber(shipmentOrder.allocatedQuantity) }
            <div className={styles.subText}>Quantity</div>
        </td>
        <td className='py-1'>
            { getPicklistQuantityWidgets(shipmentOrder.openPickListQuantity, shipmentOrder.closedPickListQuantity) }
            <div className={styles.subText}>Picklist</div>
        </td>
    </tr>
} 

export default ShipmentDetailOrdersListSection;