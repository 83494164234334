import React, { useState, useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import styles from "./style.module.scss";
import * as shipmentApi from "../../../../apis/shipment.js";
import ShipmentDetailNavTab, { NAV_ITEM_IDS } from './ShipmentDetailNavTab.js';
import ShipmentDetailOrdersListSection from './ShipmentDetailOrdersListSection.js';
import { FaPlay } from 'react-icons/fa';
import RunShipmentModal from './RunShipmentModal.js';
import ShipmentDetailWarehouseGroupConfigurationSection from './ShipmentDetailWarehouseGroupConfigurationSection.js';
import ShipmentOrderDetailPage from './ShipmentOrderDetailPage.js';
import { IoCopyOutline } from "react-icons/io5";

const ITEMS_PER_PAGE = 10;

const _ = require('lodash');
const ShipmentDetailPage = (props) => {
    const { displayAlert, selectedShipmentId, setSelectedShipmentId, selectedShipmentName } = props;
    const [shipmentData, setShipmentData] = useState(null);
    const [ activeNavItemId, setActiveNavItemId] = useState(NAV_ITEM_IDS.RUNS);
    const [ showRunShipmentModal, setShowRunShipmentModal] = useState(false);
    const [ shipmentOrders, setShipmentOrders ] = useState([]);
    const [ shipmentOrdersPageData, setShipmentOrdersPageData ] = useState({
        totalPages: 1,
        totalElements: 0,
        currentPage: 0,
        size: ITEMS_PER_PAGE,
    });
    const [ selectedShipmentOrderId, setSelectedShipmentOrderId ] = useState(null);
    
    const fetchShipmentOrders = async (page) => {
        const pageWithOffset = page - 1;
        const params = {
            page: pageWithOffset,
        }
        const shipmentOrdersData = (await shipmentApi.getShipmentOrdersByShipmentId(selectedShipmentId, params)).data.data;

        setShipmentOrdersPageData({
            ...shipmentOrdersPageData,
            currentPage: page,
            totalPages: shipmentOrdersData.totalPages,
            totalElements: shipmentOrdersData.totalElements,
        })

        setShipmentOrders(shipmentOrdersData.data);
    };

    useEffect(() => {
        const fetchShipmentDetails = async () => {
            if (_.isNil(selectedShipmentId)) return;

            try {
                const data = (await shipmentApi.getShipmentById(selectedShipmentId)).data.data;

                setShipmentData(data);
            } catch (e) {
                displayAlert({message: e.message, type: 'error'});
            }
        }

        fetchShipmentDetails();
    }, [selectedShipmentId]);

    const closeRunShipmentModalAndRefreshShipmentOrders = () => {
        setShowRunShipmentModal(false);
        fetchShipmentOrders(1);
    }

    const Title = () => {
        return (
            <div className={styles.title}>{selectedShipmentName}</div>
        );
    }

    const Navigator = () => {
        return (
            <div className={styles.navigator}> <div className={styles.navigatorLink} onClick={(target) => { setSelectedShipmentId(null) }}> Semua Shipment</div> &nbsp; / &nbsp; {selectedShipmentName}</div>
        );
    }

    const ShipmentInformation = () => {
        return (
            <div className='ml-3 mb-3'>
                <Row>
                    <Col md={2} style={{borderRight: '1px solid #A8AAAD'}}>
                        <Row style={{fontSize: '14px', color: '#767676', fontWeight: '500'}}>
                            JADWAL
                        </Row>
                        <Row style={{fontSize: '14px', color: '#050406'}}>
                            On Demand
                        </Row>
                    </Col>
                    <Col md={2} style={{borderRight: '1px solid #A8AAAD'}}>
                        <div className='pl-2'>
                            <Row style={{fontSize: '14px', color: '#767676', fontWeight: '500'}}>
                                SHIPMENT ID
                            </Row>
                            <Row style={{fontSize: '14px', color: '#050406'}}>
                                { shipmentData?.id }
                                { !!(shipmentData?.id) && <div className={[styles.hoverable, 'align-top ml-1'].join(' ')} style={{display: 'inline', margin: '0', padding: '0', border: '0', minHeight: '0', minWidth: '0'}} onClick={() => { navigator.clipboard.writeText(`${shipmentData.id}`)}}> <IoCopyOutline size='0.875rem'/> </div> }
                            </Row>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className='pl-2'>
                            <Row style={{fontSize: '14px', color: '#767676', fontWeight: '500'}}>
                                WAREHOUSE ASAL
                            </Row>
                            <Row style={{fontSize: '14px', color: '#050406'}}>
                                { shipmentData?.warehouseSource }
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    if (!!selectedShipmentOrderId) {
        return <ShipmentOrderDetailPage displayAlert={displayAlert} selectedShipmentName={selectedShipmentName} setSelectedShipmentId={setSelectedShipmentId} setSelectedShipmentOrderId={setSelectedShipmentOrderId} shipmentData={shipmentData} selectedShipmentOrderId={selectedShipmentOrderId}/>
    }

    let shipmentDetailBody;

    if (activeNavItemId === NAV_ITEM_IDS.RUNS) {
        shipmentDetailBody = <ShipmentDetailOrdersListSection selectedShipmentId={selectedShipmentId} shipmentOrders={shipmentOrders} fetchShipmentOrders={fetchShipmentOrders} pageData={shipmentOrdersPageData} setSelectedShipmentOrderId={setSelectedShipmentOrderId}/>
    } else if (activeNavItemId === NAV_ITEM_IDS.CONFIGURATION) {
        shipmentDetailBody = <ShipmentDetailWarehouseGroupConfigurationSection selectedShipmentId={selectedShipmentId} displayAlert={displayAlert} shipmentData={shipmentData}/>
    }

    return (
        <div className={"w-100 h-100 overflow-auto"}>
            <Navigator/>
            <Row className='justify-content-between'>
                <Col>
                    <Title/>
                </Col>
                <Col sm={2} className='align-content-center text-center'>
                    <button
                        className={['btn', styles.btnOutlineOk].join(' ')}
                        style={{display: 'flex', alignItems: 'center', margin: '0 auto', padding: '2px 16px'}}
                        type='button'
                        onClick={(e) => {
                            setShowRunShipmentModal(!showRunShipmentModal);
                        }}
                    >
                        <FaPlay style={{fontSize: '0.8rem', marginTop: '0', marginBottom: '0', marginRight: '0.4em'}}/>
                        <div className='text-center' style={{fontSize: '0.875rem', display: 'inline-block'}}>Run</div>
                    </button>
                </Col>
            </Row>
            <ShipmentInformation/>
            <ShipmentDetailNavTab activeNavItemId={activeNavItemId} setActiveNavItemId={setActiveNavItemId}/>
            { shipmentDetailBody }
            <RunShipmentModal isShow={showRunShipmentModal} selectedShipmentId={selectedShipmentId} selectedShipmentName={selectedShipmentName} onClose={() => setShowRunShipmentModal(false)} closeModalAndRefreshOrders={closeRunShipmentModalAndRefreshShipmentOrders} displayAlert={displayAlert}/>
        </div>
    );
}

export default ShipmentDetailPage;