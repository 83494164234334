import customAxios from "./axios";
import {
    WAREHOUSE_PLANOGRAM,
    WAREHOUSE_PLANOGRAM_TRANSFER_ALLOCATIONS,
    WAREHOUSE_PLANOGRAM_TRANSFER_ALLOCATIONS_UPDATE
} from "../constants/api";
import fileDownload from "js-file-download";
import * as qs from "query-string";

export const fetchPlanogramOptions = (planogramType, name, page, size, warehouseIds) => {
    return customAxios.get(`${WAREHOUSE_PLANOGRAM}?planogramTypes=${planogramType}&sort=name&fetchType=SPECIFIC${warehouseIds ? '&warehouseIds=' + warehouseIds : ''}`);
}

export const fetchPlanogramByNames = (planogramNames) => {
    return customAxios.get(`${WAREHOUSE_PLANOGRAM}?fetchType=SPECIFIC&names=${planogramNames.join(',')}`);
}

export const fetchPlanograms = (params) => {
    return customAxios.get(WAREHOUSE_PLANOGRAM, { params });
}

export const fetchPlanogramByAlias = (warehouseIds, planogramNames) => {
    return customAxios.get(`${WAREHOUSE_PLANOGRAM}?fetchType=ALIAS&warehouseIds=${warehouseIds.join(',')}&names=${planogramNames.join(',')}`);
}

export const fetchPlanogramAliasMapByWarehouseId = (warehouseId) => {
    return customAxios.get(`${WAREHOUSE_PLANOGRAM}/aliases/${warehouseId}`);
}

export const getPlanogramAliasByWarehouses = (warehouseIds) => {
    return customAxios.get(`${WAREHOUSE_PLANOGRAM}/aliases?warehouseIds=${warehouseIds.join(',')}`);
}

export const fetchCompletePlanogram = (ids, warehouseIds, functionalities = []) => {
    return customAxios.get(`${WAREHOUSE_PLANOGRAM}?ids=${ids.join(',')}&warehouseIds=${warehouseIds.join(',')}&functionalities=${functionalities.join(',')}&fetchType=COMPLETE`);
}

export const postPlanogram = (planogram) => {
    return customAxios.post(`${WAREHOUSE_PLANOGRAM}`, planogram);
}

export const postBulkPlanogram = (planograms) => {
    return customAxios.post(`${WAREHOUSE_PLANOGRAM}/bulk`, planograms);
}

export const fetchFlatPlanogram = id => {
    return customAxios.get(`${WAREHOUSE_PLANOGRAM}?ids=${id}&fetchType=FLAT`);
}

export const deletePlanogram = (id, isDeleteInventory) => {
    return customAxios.delete(`${WAREHOUSE_PLANOGRAM}/${id}`, {params: {isDeleteInventory}});
}

export const bulkSoftDeletePlanogram = (body) => {
    return customAxios.delete(`${WAREHOUSE_PLANOGRAM}/bulk-soft-delete`, {
        data: body
    });
}

export const fetchItems = (body) => {
    return customAxios.post(`${WAREHOUSE_PLANOGRAM}/get-items`, body);
}

export const fetchFflItemsByWarehouseIds = (warehouseIds) => {
    return customAxios.get(`${WAREHOUSE_PLANOGRAM}/get-ffl-items?warehouseIds=${warehouseIds.join(',')}`);
}

export const fetchTransferHistory = (body) => {
    return customAxios.post(`${WAREHOUSE_PLANOGRAM}/get-item-movement-history`, body);
}

export const postItemTransfer = (body) => {
    return customAxios.post(`${WAREHOUSE_PLANOGRAM}/item-transfers`, body);
}

export const postItemAdjustment = (body) => {
    return customAxios.post(`${WAREHOUSE_PLANOGRAM}/item-adjustments`, body);
}

export const fetchPlanogramQRPDF = (params) => {
    return customAxios.get(`${WAREHOUSE_PLANOGRAM}/qr`,
        {responseType: 'blob', params})
        .then(response => {
            fileDownload(response.data, "planogram_qr.pdf")
        });
}

export const fetchPlanogramFunctionality = () => {
    return customAxios.get(`${WAREHOUSE_PLANOGRAM}/funtionality`);
}
export const bulkCsvCreate = (body) => {
    return customAxios.post(`${WAREHOUSE_PLANOGRAM}/csv-bulk-create`, body);
}

export const bulkPlanogramMovementCreate = (body) => {
    return customAxios.post(`${WAREHOUSE_PLANOGRAM}/bulk-item-transfers`, body, {
        validateStatus: (status) => {
            return status === 200 || status === 504; // Resolve only if the status code is 200 or 504
        }
    });
}

export const bulkPlanogramMovementSessionCreate = (body) => {
    return customAxios.post(`${WAREHOUSE_PLANOGRAM}/bulk-item-transfers/session`, body);
}

export const getWarehouseLevelPlanogram = (warehouseId) => {
    return customAxios.get(`${WAREHOUSE_PLANOGRAM}/get-warehouse-level?warehouseId=${warehouseId}`);
}

export const fetchPlanogramTypeMapByWarehouseId = (warehouseId) => {
    return customAxios.get(`${WAREHOUSE_PLANOGRAM}/type/${warehouseId}`);
}

export const getSimplePlanogramItemRules = (params) => {
    return customAxios.get(`${WAREHOUSE_PLANOGRAM}/simple-item-rules`, {
        params
    })
}

export const getDetailedPlanogramItemRules = ({ ...params }) => {
    return customAxios.get(`${WAREHOUSE_PLANOGRAM}/item-rules`, {
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, {
                arrayFormat: 'repeat',
            })
        },
    });
}

export const getDetailedPlanogramItemRulesV2 = ({ ...params }) => {
    return customAxios.get(`${WAREHOUSE_PLANOGRAM}/item-rules-v2`, {params});
}

export const getPlanogramItemQuantity = (params) => {
    return customAxios.get(`${WAREHOUSE_PLANOGRAM}/inventory`,{
        params
    })
}

export const getPlanogramItemQuantityPost = (body) => {
    return customAxios.post(`${WAREHOUSE_PLANOGRAM}/inventory`, body)
}

export const postNewPlanogramItemRules = (body) => {
    return customAxios.post(`${WAREHOUSE_PLANOGRAM}/item-rules`, body);
}

export const deleteExistingPlanogramItemRules = (body) => {
    return customAxios.delete(`${WAREHOUSE_PLANOGRAM}/item-rules`, {data: body});
}

export const patchExistingPlanogramItemRule = (planogramId, itemRuleType) => {
    return customAxios.patch(`${WAREHOUSE_PLANOGRAM}/item-rules?planogramId=${planogramId}&itemRuleType=${itemRuleType}`);
}

export const putPlanogramItemRule = (body) => {
    return customAxios.put(`${WAREHOUSE_PLANOGRAM}/item-rules`, body);
}

export const fetchPlanogramItemTypes = (warehouseId) => {
    return customAxios.get(`${WAREHOUSE_PLANOGRAM}/type/${warehouseId}`);
}

export const deleteAllPlanogramItemRule = (planogramId, itemRuleType) => {
    return customAxios.delete(`${WAREHOUSE_PLANOGRAM}/item-rules/${planogramId}/${itemRuleType}`);
}

export const fetchTransferAllocationPlanograms = (params) => {
    return customAxios.get(WAREHOUSE_PLANOGRAM_TRANSFER_ALLOCATIONS, {params});
}

export function updateTransferAllocationPlanograms(body) {
    return customAxios.post(WAREHOUSE_PLANOGRAM_TRANSFER_ALLOCATIONS_UPDATE, body);
}

export const fetchAllSession = (params) => {
    return customAxios.get(`${WAREHOUSE_PLANOGRAM}/sessions/dropdown`, {params})
}

export const fetchPlanogramMovement = (params) => {
    return customAxios.get(`${WAREHOUSE_PLANOGRAM}/movement-dashboard`,{params})
}

export const bulkCsvCreatePriority = (body) => {
    return customAxios.post(`${WAREHOUSE_PLANOGRAM}/csv-bulk-update-planogram-priority`, body);
}

export const validateCSVPlanogramPriority = (body) => {
    return customAxios.post(`${WAREHOUSE_PLANOGRAM}/validate-planogram-priority`, body);
}
