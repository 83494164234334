/* eslint-disable */
import React, {lazy, Suspense, useEffect} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './inputdate.css';
import * as serviceWorker from './serviceWorker';
import {
    Router,
    Switch,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import NotifBar from "./components/NotifBar";
import "moment-timezone"
import Route from './sentryRoute';
import { history } from "./utils/history";
// react-dates dependencies
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './sentry';
import "react-datepicker/dist/react-datepicker.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import initializeSentry from "./sentry";

const CreateAgentsPage = lazy(() => import("./pages/agents/createAgentsPage"));
const CreateOrUpdateProductsPage = lazy(() => import( "./pages/products/createOrUpdateProductsPage"));
const CreateOrUpdateProductLogPage = lazy(() => import( "./pages/products/OperationLog/createOrUpdateProductLogPage"));
const FindProductsPage = lazy(() => import( "./pages/products/findProductsPage"));
const HelpPage = lazy(() => import( "./pages/help/HelpPage"));
import 'bootstrap/dist/css/bootstrap.min.css';
const ListOrderProductsPage = lazy(() => import( "./pages/order_products/listOrderProductsPage"));
const OrderProductSummaryPage = lazy(() => import( "./pages/order_products/orderProductSummaryPage"));
const UpdateOrderProductsPage = lazy(() => import( "./pages/order_products/updateOrderProductsPage"));
const UpdateAgentsPage = lazy(() => import( "./pages/agents/UpdateAgentsPage"));
const ListAgentsPage = lazy(() => import( "./pages/agents/listAgentsPage"));
const CreatePriceGroupsPage = lazy(() => import( "./pages/price_groups/CreatePriceGroupsPage"));
const UpdatePriceGroupsPage = lazy(() => import( "./pages/price_groups/UpdatePriceGroupsPage"));
const ListPriceGroupsPage = lazy(() => import( "./pages/price_groups/ListPriceGroupsPage"));
const ConfigurePriceGroupProductPage = lazy(() => import( './pages/price_group_products/ConfigurePriceGroupProductPage/ConfigurePriceGroupProductPage'));
const CreatePriceGroupProductsPage = lazy(() => import( "./pages/price_group_products/CreatePriceGroupProductPage/createPriceGroupProductsPage"));
const UpdatePriceGroupProductsPage = lazy(() => import( "./pages/price_group_products/UpdatePriceGroupProductPage/updatePriceGroupProductsPage"));
const ListPriceGroupProductsPage = lazy(() => import( "./pages/price_group_products/listPriceGroupProductsPage"));
const DeletePriceGroupProductsPage = lazy(() => import( "./pages/price_group_products/DeletePriceGroupProductPage/deletePriceGroupProductsPage"));
import ListProductsPage from "./pages/products/listProductsPage";
const CreateOrUpdateItemsPage = lazy(() => import( "./pages/items/CreateOrUpdateItemsPage"));
const ListProductsWithPricePage = lazy(() => import( "./pages/products/listProductsWithPricePage"));
const CreateAgentSlugsPage = lazy(() => import( "./pages/agent_slugs/createAgentSlugsPage"));
const ListAgentSlugsPage = lazy(() => import( "./pages/agent_slugs/listAgentSlugsPage"));
const DeleteAgentSlugsPage = lazy(() => import( "./pages/agent_slugs/deleteAgentSlugsPage"));
const ManageOrderPage = lazy(() => import( "./pages/orders/ManageOrderPage"));
const ManageAgentCommissionPage = lazy(() => import( "./pages/agents/ManageAgentCommissionPage"));
const OrderDetailPage = lazy(() => import( "./pages/orders/OrderDetailPage/OrderDetailPage"));
const ManageOrderBlacklistParamPage = lazy(() => import( "./pages/orders/OrderBlacklistParamPage/ManageOrderBlacklistParamPage"));
const ManageAddressReminderPage = lazy(() => import( "./pages/orders/ManageAddressReminder/ManageAddressReminderPage"));
const CreatePromosPage = lazy(() => import( "./pages/promos/CreatePromosPage"));
const UpdatePromosPage = lazy(() => import( "./pages/promos/UpdatePromosPage"));
const ListPromosPage = lazy(() => import( "./pages/promos/ListPromosPage"));
const PromoBlacklistPage = lazy(() => import( "./pages/promos/PromoBlacklistPage"));
const CopyPriceGroupProductsPage = lazy(() => import( "./pages/price_group_products/CopyPriceGroupProductsPage/CopyPriceGroupProductsPage"));
const ListOrderPageV2 = lazy(() => import( "./pages/orders/ListOrderPageV2"));
const CreateUserPage = lazy(() => import( "./pages/users/CreateUserPage"));
const ListUserPage = lazy(() => import( "./pages/users/ListUsersPage/ListUsersPage"));
const UpdateUserPage = lazy(() => import( "./pages/users/UpdateUserPage/UpdateUserPage"));
import AuthCheck from "./components/AuthCheck";
const LoginPage = lazy(() => import( "./pages/auth/LoginPage"));
const LogoutPage = lazy(() => import( "./pages/auth/LogoutPage"));
const OrderSummaryPage = lazy(() => import( "./pages/agents/OrderSummaryPage"));
const ListWarehousesPage = lazy(() => import( "./pages/warehouses/ListWarehousesPage"));
const CreateWarehousesPage = lazy(() => import( "./pages/warehouses/CreateWarehousesPage"));
const UpdateWarehousePage = lazy(() => import( "./pages/warehouses/UpdateWarehousesPage"));
const ManageInventoryCheckPage = lazy(() => import( "./pages/inventory_checks/ManageInventoryCheckPage"));
const ListPurchasingProductsPage = lazy(() => import( "./pages/purchasing_products/ListPurchasingProductsPage"));
const CreatePurchasingProductsPage = lazy(() => import( "./pages/purchasing_products/CreatePurchasingProductsPage"));
const UpdatePurchasingProductsPage = lazy(() => import( "./pages/purchasing_products/UpdatePurchasingProductsPage"));
// const ListProductPurchasingProductsPage = lazy(() => import( "./pages/product_purchasing_products/ListProductPurchasingProductsPage"));
// const CreateProductPurchasingProductsPage = lazy(() => import( "./pages/product_purchasing_products/CreateProductPurchasingProductsPage"));
// const UpdateProductPurchasingProductsPage = lazy(() => import( "./pages/product_purchasing_products/UpdateProductPurchasingProducts"));
// const DeleteProductPurchasingProductsPage = lazy(() => import( "./pages/product_purchasing_products/DeleteProductPurchasingProduct"));
const VersionPage = lazy(() => import( "./pages/VersionPage"));
const DeprecatedPage = lazy(() => import( "./pages/DeprecatedPage"));
const ListOrderProductScanV2Page = lazy(() => import( "./pages/order_product_scans/ListOrderProductScanV2Page"));
const AgentFeatureFlagsPage = lazy(() => import( './pages/agents/agentFeatureFlagsPage'));
const ListTemplatePage = lazy(() => import( "./pages/whatsapp/ListTemplatePage/ListWhatsappTemplatePage"));
const NotifyDeliveryInvoicePage = lazy(() => import( "./pages/whatsapp/NotifyDeliveryInvoicePage"));
const ListRolePage = lazy(() => import( "./pages/roles/ListRolePage"));
const ListScopePage = lazy(() => import( "./pages/scopes/ListScopePage"));
const OutboundLogPage = lazy(() => import( "./pages/whatsapp/OutboundLogPage"));
const AgentWorkdayListPage = lazy(() => import( "./pages/agent_workdays/AgentWorkdayListPage"));
const ProductOrderLimitsPage = lazy(() => import( './pages/products/productOrderLimitsPage'));
const BulkAddFreebiePage = lazy(() => import( './pages/order_products/bulkAddFreebiePage'));
const ProfilePage = lazy(() => import( "./pages/auth/ProfilePage"));
import QualitySurveyPage from "./pages/whatsapp/QualitySurveyPage";
import CreateOrUpdatePurchaseOrderPage from "./pages/purchase_orders/CreateOrUpdatePurchaseOrderPage/CreateOrUpdatePurchaseOrderPage";
const ListPurchaseOrderPage = lazy(() => import( "./pages/purchase_orders/ListPurchaseOrderPage"));
const ListInboundPage = lazy(() => import( "./pages/inbounds/ListInboundPage"));
const BulkUploadInboundPage = lazy(() => import( "./pages/inbounds/bulk_upload/BulkUploadInboundPage"));
const ListSupplierPage = lazy(() => import( "./pages/suppliers/ListSupplierPage"));
const ListSupplierOnboardingPage = lazy(() => import( "./pages/suppliers_onboarding/ListSupplierOnboardingPage"));
const WarehouseManagementPage = lazy(() => import( './pages/suppliers_onboarding/WarehouseManagementPage'));
const CreateInternalShipmentPage = lazy(() => import( "./pages/internal_shipments/CreateInternalShipment"));
const OutboundPage = lazy(() => import( './pages/outbounds/OutboundPage'));
const QualityControlMetricsPage = lazy(() => import( './pages/quality_control_metrics/QualityControlMetricsPage'));
const PurchasingAnalyticsMetricsPage = lazy(() => import( './pages/purchasing_analytics_metrics/PurchasingAnalyticsMetricsPage'));
const CreateShippingPointPage = lazy(() => import( './pages/shipping_points/CreateShippingPointPage'));
const UpdateShippingPointPage = lazy(() => import( './pages/shipping_points/UpdateShippingPointPage'));
const ListShippingPointsPage = lazy(() => import( './pages/shipping_points/ListShippingPointsPage'));
import CheckLocationCoveredByShippingPoint from './pages/shipping_points/CheckLocationCoveredByShippingPoint'

const CheckLocationCoveredByGeofencing = lazy(() => import( './pages/geofencing/CheckLocationCoveredByGeofencing'));

const ListGrPaymentPage = lazy(() => import( "./pages/gr_payments/ListGrPaymentPage"));
const InventoryDashboardPage = lazy(() => import( "./pages/inventory_dashboard/InventoryDashboardPageV2"));
const InventoryPlanogramDashboardV3Page = lazy(() => import( "./pages/inventory_dashboard/InventoryPlanogramDashboardV3Page"));
const ListInventoryAdjustmentPage = lazy(() => import( "./pages/inventory_adjustment/ListInventoryAdjustmentPageV2"));
const ListInventoryAdjustmentDocumentSchedulerPage = lazy(() => import( './pages/inventory_adjustment_document_scheduler/ListInventoryAdjustmentDocumentSchedulerPage'));
const ListRecipientInventoryPage = lazy(() => import( "./pages/recipient_inventory/ListRecipientInventoryPage"));
const ListWarehouseTransferPage = lazy(() => import( "./pages/warehouse_transfers/ListWarehouseTransferPage"));
import WarehouseTransferDashboard from "./pages/warehouse_transfer_dashboard/warehouse_transfer_page";
const CancelUnpaidOrdersPage = lazy(() => import( './pages/orders/CancelUnpaidOrders/CancelUnpaidOrdersPage'));
const CommissionGroupPage = lazy(() => import( './pages/agents/CommissionGroup/CommissionGroupPage'));
const CreateCommissionGroupPage = lazy(() => import( './pages/agents/CommissionGroup/CreateCommissionGroup'));
const CommissionSchemePage = lazy(() => import( './pages/agents/CommissionScheme/CommissionSchemePage'));
const ListUpdateAgentChangeLogPage = lazy(() => import( "./pages/agents/ChangeLog/ListUpdateAgentChangeLogPage"));

const ListAgentTransactionPage = lazy(() => import( "./pages/agent_transaction/ListAgentTransactionPage"));
const AgentBalanceDashboardPage = lazy(() => import( "./pages/agent_balance/AgentBalanceDashboardPage"));
const AgentBalanceDetailPage = lazy(() => import( "./pages/agent_balance/AgentBalanceDetailPage"));
const WarehousePlanogramPage = lazy(() => import( "./pages/warehouse_planograms/WarehousePlanogramPage"));

const OrderQueueHomePage = lazy(() => import( "./pages/order_queue_configuration/OrderQueueHomePage"));
const FulfillmentOrderQueueSDD = lazy(() => import( "./pages/fulfillment/order_queues/sdd/FulfillmentOrderQueueDashboardSDD"));
const OrderTimeLimitPage = lazy(() => import( "./pages/fulfillment/order_queues/sdd/order_time_limit/OrderTimeLimitPage.js"));
const FulfillmentPackage = lazy(() => import( "./pages/fulfillment/packages/FulfillmentPackagePage"));
const ExternalOrderInputPage = lazy(() => import("./pages/fulfillment/external_order_input/ExternalOrderInputPage.js"))
const PackerValidationConfig = lazy(() => import( "./pages/fulfillment/packer_validation/PackerValidationConfig.js"));

const ReferralUpdateValuePage = lazy(() => import( "./pages/referral/ReferralUpdateValuePage"));
import ManageReferralBannerPage from "./pages/referral/ManageReferralBannerPage";
const ManageProgramBannerPage = lazy(() => import( "./pages/referral/ManageProgramBannerPage"));

const SalesForecastListPage = lazy(() => import( "./pages/sales_forecast/SalesForecastListPage"));
const ForecastBaselineListPage = lazy(() => import( "./pages/sales_forecast_baseline/ForecastBaselineListPage"));
const ProductionMaximumListPage = lazy(() => import( "./pages/production_maximum/ProductionMaximumListPage.js"));
const ProductionManpowerAdjustmentListPage = lazy(() => import( "./pages/production_manpower_adjustment/ProductionManpowerAdjustment.js"));

// deprecate change to freebie promo
import ManageFreebieProductsPage from "./pages/freebie_products/ManageFreebieProductsPage";

const ManageFreebiePromoPage = lazy(() => import( './pages/freebie_promo/ManageFreebiePromoPage'));
const ManageSelectableFreebiePromoPage = lazy(() => import( './pages/selectable_freebie_promo/ManageSelectableFreebiePromoPage'));

const ManagePromoEventsPage = lazy(() => import( "./pages/promo_events/ManagePromoEventsPage"));

const CreateProductTagsPage = lazy(() => import( "./pages/product_tags/CreateProductTagsPage"));
const ProductTagsPage = lazy(() => import( "./pages/product_tags/ProductTagsPage"));
const UpdateProductTagsPage = lazy(() => import( "./pages/product_tags/UpdateProductTagsPage"));
const DeleteProductTagsPage = lazy(() => import( "./pages/product_tags/DeleteProductTagsPage"));

const CreateFrontendSubcategoriesPage = lazy(() => import( "./pages/frontend_subcategories/CreateFrontendSubcategoriesPage"));
const ListFrontendSubcategoriesPage = lazy(() => import( "./pages/frontend_subcategories/ListFrontendSubcategoriesPage"));
const UpdateFrontendSubcategoriesPage = lazy(() => import( "./pages/frontend_subcategories/UpdateFrontendSubcategoriesPage"));
const DeleteFrontendSubcategoriesPage = lazy(() => import( "./pages/frontend_subcategories/DeleteFrontendSubcategoriesPage"));
const LogFrontendSubcategoriesPage = lazy(() => import( "./pages/frontend_subcategories/LogFrontendSubcategoriesPage"));

const CreateFrontendCategoriesPage = lazy(() => import( "./pages/frontend_categories/CreateFrontendCategoriesPage"));
import ListFrontendCategoriesPage from "./pages/frontend_categories/ListFrontendCategoriesPage";
const UpdateFrontendCategoriesPage = lazy(() => import( "./pages/frontend_categories/UpdateFrontendCategoriesPage"));
const DeleteFrontendCategoriesPage = lazy(() => import( "./pages/frontend_categories/DeleteFrontendCategoriesPage"));
import SortFrontendCategoriesPage from "./pages/frontend_categories/SortFrontendCategoriesPage";
const LogFrontendCategoriesPage = lazy(() => import( './pages/frontend_categories/LogFrontendCategoriesPage'));

const ListCDGPage = lazy(() => import( './pages/category_display_group/ListCDGPage'));
const CreateCDGPage = lazy(() => import( './pages/category_display_group/CreateCDGPage'));
const CreateCDGPageCLP = lazy(() => import( './pages/category_display_group/CreateCDGPageCLP'));
const CreateCDGPageDirectoryCLP = lazy(() => import( './pages/category_display_group/CreateCDGPageDirectoryCLP'));
const EditCDGPage = lazy(() => import( './pages/category_display_group/EditCDGPage'));
import EditCDGPageForCLPandDirectoryCLP from './pages/category_display_group/EditCDGPageForCLPandDirectoryCLP';
const LogCDGPage = lazy(() => import( './pages/category_display_group/LogCDGPage'));

import { setMapReady } from "./redux/actions/gmaps";
const NotifyOutOfStockPage = lazy(() => import( './pages/orders/NotifyOutOfStockPage/NotifyOutOfStockPage'));
const CourierListPage = lazy(() => import( "./pages/courier/CourierListPage"));
const CourierDashboardPage = lazy(() => import( "./pages/courier/CourierDashboardPage"));
const CourierFeeListPage = lazy(() => import( "./pages/courier/CourierFeeListPage"));
const CourierCheckListPage = lazy(() => import( "./pages/courier/CourierCheckListPage"));
const CourierKycListPage = lazy(()=> import("./pages/courier/courier_kyc/CourierKycListPage"));
const CourierUpdateHistoryPage = lazy(() => import( "./pages/courier/CourierUpdateHistoryPage"));
const CourierPunishmentPage = lazy(() => import( "./pages/courier/courier_punishment/CourierPunishmentPage"));
const CourierPunishmentTypePage = lazy(() => import( "./pages/courier/courier_punishment/CourierPunishmentTypePage"));


const ConversionPage = lazy(() => import( "./pages/conversion/ConversionPage"));
const ConversionTemplatePage = lazy(() => import( "./pages/conversion_template/ConversionTemplatePage"));
const OrderLimitNextDayPage = lazy(() => import( "./pages/orders/OrderLimitPage")
    .then((module) => ({default:module.OrderLimitNextDayPage})));
const OrderLimitSameDayPage = lazy(() => import( "./pages/orders/OrderLimitPage")
    .then((module) => ({default:module.OrderLimitSameDayPage})));
const ProductKeywordsPage = lazy(() => import( './pages/products/productKeywordsPage'));
const OrderDeliveryPage = lazy(() => import( "./pages/orders/OrderDeliveryPage"));
const OrderPipelineDashboardPage = lazy(() => import( "./pages/order_pipelines/OrderPipelineDashboardPage"));
const OrderPipelineDashboardV22Page = lazy(() => import( "./pages/order_pipelines/OrderPipelineDashboardV22Page"));
const OrderPipelineLogPage = lazy(() => import( "./pages/order_pipelines/OrderPipelineLogPage"));
const OrderPipelineLogV22Page = lazy(() => import( "./pages/order_pipelines/OrderPipelineLogV22Page"));
const WarehouseOutboundCheckpointPage = lazy(() => import( "./pages/order_pipelines/WarehouseOutboundCheckpointPage"));
const RecalculateOrderPipelinesPage = lazy(() => import( "./pages/order_pipelines/RecalculateOrderPipelinesPage"));
// Deprecate ShopeepayList
// const ShopeepayListPage = lazy(() => import( "./pages/shopeepay/ShopeepayListPage"));

const TotalPriceLimitWhiteListPage = lazy(() => import( "./pages/total_price_limit_whitelist/TotalPriceLimitWhiteListPage"));
const AddressVerificationPage = lazy(() => import( "./pages/orders/AddressVerificationPage/AddressVerificationPage"));
const ManageBannerPage = lazy(() => import( './pages/banner/ManageBannerPage'));
const ManageAccountPageBannerPage = lazy(() => import('./pages/account_page_banner/ManageAccountPageBannerPage'));

const AutomatedFlashSalePage = lazy(() => import( './pages/automated_flash_sale/AutomatedFlashSalePage'));
const PopupsPage = lazy(() => import( './pages/popups/PopupsPage'));
import ManageNegativePricesPage from './pages/negative_prices/ManageNegativePricesPage';
const CreateManualCatalogPromoPage = lazy(() => import( './pages/manual_catalog_promo/CreateManualCatalogPromoPage'));
const ConfigureManualCatalogPromoPage = lazy(() => import( './pages/manual_catalog_promo/ConfigureManualCatalogPromoPage'));
const ManageHargaGilaPage = lazy(() => import( './pages/harga_gila/ManageHargaGilaPage'));
const FulfillmentPickingDashboardHome = lazy(() => import( './pages/order_pipelines/FullFillmentPickingDashboardPage/FulfillmentPickingDashboardHome'));
const FulfillmentPickingDashboard = lazy(() => import( './pages/order_pipelines/FullFillmentPickingDashboardPage/FulfillmentPickingDashboard'));
const FulfillmentPickingOrder = lazy(() => import( './pages/order_pipelines/FullFillmentPickingDashboardPage/FulfillmentPickingOrder'));
const FulfillmentPickingContainer = lazy(() => import( './pages/order_pipelines/FullFillmentPickingDashboardPage/FulfillmentPickingContainer'));
const FulfillmentPickingDetail = lazy(() => import( './pages/order_pipelines/FullFillmentPickingDashboardPage/FulfillmentPickingDetail'));
const FulfillmentPickingTimestamp = lazy(() => import( './pages/order_pipelines/FullFillmentPickingDashboardPage/FulfillmentPickingTimestamp'));
const FulfillmentPickingEdit = lazy(() => import( './pages/order_pipelines/FullFillmentPickingDashboardPage/FulfillmentPickingEdit'));
const ListForecastSDDPage = lazy(() => import( './pages/forecast_sdd/ListForecastSDDPage'));
const CourierFeeHistoryPage = lazy(() => import( "./pages/courier/CourierFeeHistoryPage"));

const RefundListPage = lazy(() => import( './pages/orders/RefundListPage/RefundListPage'));
import FlashSaleWidgetManagementPage from './pages/homepage_management/FlashSaleWidgetManagementPage';
const ThematicWidgetManagementPage = lazy(() => import( './pages/homepage_management/ThematicWidgetManagementPage'));
const ThematicBannerManagementPage = lazy(() => import( './pages/homepage_management/ThematicBanner/ManagementPage'));
const PermanentBannerManagementPage = lazy(() => import( './pages/homepage_management/PermanentBanner/PermanentBannerManagementPage'));
const ThematicBannerEditPage = lazy(() => import( './pages/homepage_management/ThematicBanner/BannerEdit'));
const SegariCoinTransactionsList = lazy(() => import( './pages/segari_coin_transaction/SegariCoinTransactionsList'));
const CreateSegariCoinTransaction = lazy(() => import( './pages/segari_coin_transaction/CreateSegariCoinTransaction'));
const FulfillmentPickingRepick = lazy(() => import( './pages/order_pipelines/FullFillmentPickingDashboardPage/FulfillmentPickingRepick'));
const FulfillmentPickingOOS = lazy(() => import( './pages/order_pipelines/FullFillmentPickingDashboardPage/FulfillmentPickingOOS'));
const FulfillmentOOSQuota = lazy(() => import( "./pages/order_pipelines/FullFillmentPickingDashboardPage/FulfillmentOOSQuota"));
const FulfillmentPickingPickerDashboard = lazy(() => import( './pages/order_pipelines/FullFillmentPickingDashboardPage/FulfillmentPickingPickerDashboard'));
const ViewPromosPage = lazy(() => import( './pages/promos/ViewPromosPage'));
const ListInventoryFulfillmentDefectPage = lazy(() => import( './pages/inventory_fulfillment_defect/ListInventoryFulfillmentDefectPage'));
const PricingSchedulerPage = lazy(() => import( './pages/price_group_products/PricingScheduler/PricingSchedulerPage'));
const ListInventoryProductionRestockPage = lazy(() => import( './pages/inventory_production_restock/ListInventoryProductionRestockPage'));

const BulkUpdatePage = lazy(() => import( "./pages/agents/BulkUpdate/BulkUpdatePage"));
const ListOperationLogPage = lazy(() => import( "./pages/agents/OperationLog/ListOperationLogPage"));
const SDDAutoMetricsPage = lazy(() => import( './pages/orders/SDDRevampDashboard/SDDAutoMetric/SDDAutoMetricsPage'));
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
const ListOperationLogAgentTypeCommissionPage = lazy(() => import( "./pages/agents/CommissionScheme/OperationLog/ListOperationLogPage"));
const ListOperationLogCommissionGroupPage = lazy(() => import( "./pages/agents/CommissionGroup/OperationLog/ListOperationLogPage"));
const UpdateWarehousesOperatingTimePage = lazy(() => import( './pages/warehouses/UpdateWarehousesOperatingTimePage'));
const WarehouseHandoverBlackListPage = lazy(() => import( './pages/warehouses/WarehouseHandoverBlacklistPage'));
const WarehouseDefaultDeliveryProportionPage = lazy(() => import( './pages/warehouses/WarehouseDeliveryProportionPage.js'));

const GeneralPickListPage = lazy(() => import( './pages/general_picklist/GeneralPickListPage'));
const MultiBatchPickListPage = lazy(() => import( './pages/multibatch_picklist/MultiBatchPicklistPage'));
const SeoProductPage = lazy(() => import( './pages/seo_url_map/SeoProductPage'));
const SeoCategoryPage = lazy(() => import( './pages/seo_url_map/SeoCategoryPage'));
const SeoSubCategoryPage = lazy(() => import( './pages/seo_url_map/SeoSubCategoryPage'));
const WarehousePlanogramItemRulesPage = lazy(() => import( "./pages/warehouse_planogram_item_rules/WarehousePlanogramItemRulesPage"));
const ListItemDimensionPage = lazy(() => import( './pages/item_dimension/ListItemDimensionPage'));
const PurchaseReturnRequestPage = lazy(() => import( "./pages/purchase_return/PurchaseReturnRequestPage/PurchaseReturnRequestPage"));

const CampaignConfigurationPage = lazy(() => import( "./pages/inbox_campaign/CampaignConfigurationPage"));
const CampaignDetailPage = lazy(() => import( "./pages/inbox_campaign/CampaignDetailPage"));
const CreateOrEditCampaignPage = lazy(() => import( "./pages/inbox_campaign/CreateOrEditCampaignPage"));
const AgingPlanogramDashboard = lazy(() => import( './pages/aging_planogram_dashboard/AgingPlanogramDashboard'));
const SddAutoDashboardMainPage = lazy(() => import( "./pages/orders/SDDRevampDashboard/SddAutoDashboard/SddAutoDashboardMainPage"));
const SddOrderDeliveryRevampPage = lazy(() => import( "./pages/orders/SDDRevampDashboard/OrderDelivery/SddOrderDeliveryRevampPage"));
const SddOrderDeliveryDetailPage = lazy(() => import( "./pages/orders/SDDRevampDashboard/OrderDelivery/SddOrderDeliveryDetailPage"));
const DistrictsSlaPage = lazy(() => import( "./pages/orders/SDDRevampDashboard/DistrictsSla/DistrictsSlaPage"));
const PurchaseUoMListPage = lazy(() => import( './pages/products/PurchaseUoM/PurchaseUoMListPage'));
const PurchaseUoMCreatePage = lazy(() => import( './pages/products/PurchaseUoM/PurchaseUoMCreatePage'));
const ForceMajeurePage = lazy(() => import( './pages/force_majeures/ForceMajeurePage'));
const ForceMajeuresListPage = lazy(() => import( './pages/force_majeures/ForceMajeuresListPage'));
const ShippingPointGlobalConfigPage = lazy(() => import('./pages/shipping_points/shipping_point_global_config/ShippingPointGlobalConfigPage.js'));
import ProductListPage from "./pages/product_list/ProductListPage";
const ManageRecipePage = lazy(() => import( './pages/recipe_management/ManageRecipePage'));
const ManageLandingPage = lazy(() => import( './pages/landing_page_management/ManageLandingPage'));
const CreateUpdateLandingPage = lazy(() => import( './pages/landing_page_management/CreateUpdateLandingPage'));
const ManageCuratedRecipePage = lazy(() => import( './pages/recipe_management/curated/ManageCuratedRecipePage'));
const UpdateRecipeDetailAssetPage = lazy(() => import( './pages/recipe_management/detail/UpdateAsset'));
const ListRecipeSuggestionsPage = lazy(() => import( './pages/recipe_management/ListRecipeSuggestionsPage'));
const ListPurchaseReturnPage = lazy(() => import( "./pages/purchase_return/PurchaseReturnPage/ListPage/ListPurchaseReturnPage"));
const PurchaseReturnDetailPage = lazy(() => import( "./pages/purchase_return/PurchaseReturnPage/DetailPage/PurchaseReturnDetailPage"));
import UniversalBarcodeForm from "./pages/products/component/UniversalBarcodeForm";
const UniversalBarcodeCSVUploadForm = lazy(() => import( "./pages/items/components/UniversalBarcodeForm/UniversalBarcodeCSVUploadForm"));

const ManpowerDashboardHome = lazy(() => import( './pages/manpower/ManpowerDashboardPage/ManpowerDashboardHome'));
const ManpowerProductivityPage = lazy(() => import( './pages/manpower/ManpowerDashboardPage/ManpowerProductivityPage'));
const ManpowerPickingPage = lazy(() => import( './pages/manpower/ManpowerDashboardPage/ManpowerPickingPage'));
const ManpowerCheckingPage = lazy(() => import( './pages/manpower/ManpowerDashboardPage/ManpowerCheckingPage'));
const WarehousePlanogramWhitelistPage = lazy(() => import( './pages/warehouses/WarehousePlanogramWhitelistPage'));

const ListProductSuggestionPage = lazy(() => import( './pages/product_suggestions/ListProductSuggestionsPage'));
const ForceMajeureImpactedOrders = lazy(() => import( './pages/orders/SDDRevampDashboard/ForceMajeure/ForceMajeureImpactedOrders'));
const InventoryServicePlanogramRulesPage = lazy(() => import( './pages/inventory_service_planogram_rules/InventoryServicePlanogramRulesPage'));
const CODPaymentPage = lazy(() => import( './pages/orders/CODPaymentPage'));
const GoodsMovementDashboardv2Page = lazy(() => import( "./pages/goods_movement_dashboard/GoodsMovementDashboardv2Page"));
const DropOrderMetricsPage = lazy(() => import( "./apis/dropOrderMetricsPage"));
const AutoPolPage = lazy(() => import( "./pages/stock_availability/auto_pol_page"));

const ItemLocationRoutePage = lazy(() => import( './pages/item_location_routes/ItemLocationRoutePage'));
const GeneralPickListItemBulkPage = lazy(() => import( './pages/general_pick_list_item_bulk/GeneralPickListItemBulkPage'));

const ManagePromoLabelPage = lazy(() => import( "./pages/promo_label/ManagePromoLabelPage"));
const CrossUpSellingProductCurationPage = lazy(() => import( "./pages/cross_up_selling_products/CrossUpSellingProductCurationPage"));
const CrossUpSellingProductCurationCreatePage = lazy(() => import( "./pages/cross_up_selling_products/CrossUpSellingProductCurationCreatePage"));
const CrossUpSellingProductCurationLogPage = lazy(() => import( "./pages/cross_up_selling_products/CrossUpSellingProductCurationLogPage"));

const ListProductLabelsPage = lazy(() => import( './pages/product-labels/ListProductLabelsPage'));
const CreateProductLabelsPage = lazy(() => import( './pages/product-labels/CreateProductLabelsPage'));
const UpdateProductLabelsPage = lazy(() => import( './pages/product-labels/UpdateProductLabelsPage'));
const LogProductLabelsPage = lazy(() => import( './pages/product-labels/LogProductLabelsPage'));
import ProductTagInfo from './pages/product_tags/ProductTagInfo';
const LabelGroupListPage = lazy(() => import( "./pages/label_group/LabelGroupListPage"));
const AutomateNotificationPage = lazy(() => import( "./pages/orders/SDDRevampDashboard/AutomateNotification/AutomateNotificationPage"));
const SddCourierFeeConfigPage = lazy(() => import( "./pages/orders/SDDRevampDashboard/SddCourierFeeConfig/SddCourierFeeConfigPage"));
const CourierAvailabilityPage = lazy(() => import( "./pages/orders/SDDRevampDashboard/CourierAttendance/CourierAvailabilityPage"));
const ListStockOpnameSchedulerPage = lazy(() => import("./pages/inventory_adjustment_scheduler/ListStockOpnameSchedulerPage"));
const ProductBundlePage = lazy(() => import( './pages/product_bundle/ProductBundlePage'));
const ProductVariantPage = lazy(() => import( "./pages/product_variant/ProductVariantPage"));
const CreateProductVariantPage = lazy(() => import( "./pages/product_variant/CreateProductVariantPage"));
const BubulPricingPage = lazy(() => import( "./pages/bubul_pricing/BubulPricingPage"));
const BulkUploadBubulPage = lazy(() => import("./pages/bubul_pricing/BulkUploadBubulPage"));
const HomepageTabManagementPage = lazy(() => import( './pages/homepage_management/HomepageTab/HomepageTabManagementPage'));
import CreateOrUpdateInternalUserPage from "./pages/users/CreateOrUpdateInternalUserPage/CreateOrUpdateInternalUserPage";
const CourierLiveLocationPage = lazy(() => import( './pages/orders/OrderDeliveryPage/CourierLiveLocationPage'));
const ManageOrderFraudPageConfig = lazy(() => import("./pages/orders/OrderFraudPageConfig/ManageOrderFraudPageConfig"));
import ManpowerDatabase from "./pages/manpower/ManpowerDatabase";
const ManagePaymentMethodPage = lazy(() => import( "./pages/payment_method_configuration/ManagePaymentMethodPage"));
const categoryKeywordsPage = lazy(() => import( './pages/categories/categoryKeywordsPage'));
const CreateItemDraftLandingPage = lazy(() => import( "./pages/item_draft/CreateItemDraftLandingPage"));
const InventoryAdjustmentDocumentSecretCodePage = lazy(() => import("./pages/inventory_adjustment_document_secret_code/InventoryAdjustmentDocumentSecretCodePage"));
const TransferAllocatorDashboardPage = lazy(() => import("./pages/warehouse_transfer_allocator/TransferAllocatorDashboardPage"));
const HomepageTabEditPage = lazy(() => import("./pages/homepage_management/HomepageTab/HomepageTabEditPage"));

const SupplyLeadTimeCreatePage = lazy(() => import('./pages/supply_schedule/supply_lead_time/SupplyLeadTimeCreatePage'));
const SupplyLeadTimeListAndUpdatePage = lazy(() => import('./pages/supply_schedule/supply_lead_time/SupplyLeadTimeListAndUpdatePage'));
const SupplyDeliveryScheduleCreatePage = lazy(() => import('./pages/supply_schedule/supply_delivery_days/SupplyDeliveryScheduleCreatePage'));
const SupplyDeliveryScheduleListAndUpdatePage = lazy(() => import('./pages/supply_schedule/supply_delivery_days/SupplyDeliveryScheduleListAndUpdatePage'));

const StockGroupReadMePage = lazy(() => import('./pages/stock_availability/read_me/StockAvailabilityReadMePage'));
const StockGroupMappingsPage = lazy(() => import('./pages/stock_availability/stock_group_mappings/StockGroupMappingsPage'));

const RawMaterialRequestListPage = lazy(() => import('./pages/raw_material_request/RawMaterialRequestListPage.js'));

const AutoPolTriggerConfigurationsPage = lazy(() => import('./pages/stock_availability/autopol_trigger_configurations/AutoPolTriggerConfigurationsPage'));

const QrLabelPage = lazy(() => import( './pages/qr_label/QrLabelPage'));
const ManagePaymentCapConfigPage = lazy(() => import( './pages/payment_cap_config/ManagePaymentCapConfigPage'));
const SearchAutocompletePage = lazy(() => import('./pages/search/searchAutocompletePage'));
const ConfigurePromoQuotaPage = lazy(() => import( './pages/promo_quota/ConfigurePromoQuotaPage'));

const ManageReferralDataAndVoucherDelivery = lazy(() => import('./pages/referral/ManageReferralDataAndVoucherDelivery'));
const ReferralDataLogPage = lazy(() => import('./pages/referral/ReferralDataLogPage'));

import RecipeTagsPage from "./pages/recipe_tags/RecipeTagsPage";
import CreateRecipeTagsPage from "./pages/recipe_tags/CreateRecipeTagsPage";
import DeleteRecipeTagsPage from "./pages/recipe_tags/DeleteRecipeTagsPage";
import RecipeTagInfo from './pages/recipe_tags/RecipeTagInfo';
import UpdateRecipeTagsPage from './pages/recipe_tags/UpdateRecipeTagsPage';
const ForecastItemRuleListPage = lazy(() => import("./pages/forecast_item_rule/ForecastItemRuleListPage"));

const CarouselBannerManagementPage = lazy(() => import( './pages/homepage_management/CarouselBanner/CarouselBannerManagementPage'));
const CarouselBannerCreateAndUpdatePage = lazy(() => import( './pages/homepage_management/CarouselBanner/CarouselBannerCreateAndUpdatePage'));
const PushNotificationImageUploaderPage = lazy(() => import('./pages/push_notifications/PushNotificationImageUploaderPage'));

const ManageStickyVoucherPage = lazy(() => import( './pages/sticky_voucher/ManageStickyVoucherPage'));

const specialKeywordsPage = lazy(() => import( './pages/special_keywords/specialKeywordsPage'));
const ManageSubscriptionTierPage = lazy(() => import( './pages/subscription/ManageSubscriptionTierPage'));
const ManageSubscriptionVoucherPage = lazy(() => import( './pages/subscription/ManageSubscriptionVoucherPage'));
const ManageSubscriptionOptionSettingPage = lazy(() => import( './pages/subscription/ManageSubscriptionOptionSettingPage'));
const ManageSubscriptionTermsAndConditionsPage = lazy(() => import( './pages/subscription/ManageSubscriptionTermsAndConditionsPage'));
const ManageSubscriptionPaymentMethodConfigPage = lazy(() => import( './pages/subscription/ManageSubscriptionPaymentMethodConfigPage'));
const ManageSubscriptionGlobalSloganPage = lazy(() => import( './pages/subscription/ManageSubscriptionGlobalSloganPage'));

const FulfillmentDashboard = lazy(() => import('./pages/fulfillment/dashboard/FulfillmentDashboard'));
const ReturnInboundDashboard = lazy(() => import('./pages/fulfillment/return_inbound_dashboard/ReturnInboundDashboard'));
const QcCustomerFeedBack = lazy(() => import('./pages/quality_control/customer_feedback/CustomerFeedbackDashboard'));
const QcComplaintsAndOtherFindings = lazy(() => import('./pages/quality_control/complaints_and_other_findings/ComplaintsAndOtherFindingsDashboard'));

const MidmileTransferMasterDataPage = lazy(() => import( './pages/midmile_transfer_masterdata/MidmileTransferMasterDataPage.js'));
const MidmileTransferDashboardPage = lazy(() => import( './pages/midmile_transfer_dashboard/MidmileTransferDashboardPage.js'));

const ProductBulkingPage = lazy(() => import('./pages/product_bulkings/ProductBulkingPage'));

const ManageCampaignPage = lazy(() => import( './pages/customer_rule_builder/ManageCampaignPage'));
const RuleBuilderCampaignDetailPage = lazy(() => import( './pages/customer_rule_builder/RuleBuilderCampaignDetailPage'));
const RuleBuilderAudienceDetailPage = lazy(() => import( './pages/customer_rule_builder/RuleBuilderAudienceDetailPage'));
const ManageAudiencePage = lazy(() => import( './pages/customer_rule_builder/ManageAudiencePage'));
const ManageCustomerProfilePage = lazy(() => import( './pages/customer_rule_builder/ManageCustomerProfilePage'));
const RuleBuilderCustomerProfileDetailPage = lazy(() => import( './pages/customer_rule_builder/RuleBuilderCustomerProfileDetailPage'));

const SupplierPromoConfirmationDashboardPage = lazy(() => import( './pages/rafaksi/supplier_promo_confirmation/SupplierPromoConfirmationDashboard'));
const CreateUpdateSupplierPromoConfirmationPage = lazy(() => import( './pages/rafaksi/supplier_promo_confirmation/CreateUpdateSupplierPromoConfirmation'));
const SellOutSupplierPromoConfirmationDashboardPage = lazy(() => import( './pages/rafaksi/sellout/SellOutSupplierPromoConfirmationDashboard'));
const InvoiceSupplierPromoConfirmationDashboardPage = lazy(() => import( './pages/rafaksi/invoice/InvoiceSupplierPromoConfirmationDashboard'));
const CreateSupplierPromoConfirmationInvoicePage = lazy(() => import( './pages/rafaksi/invoice/CreateSupplierPromoConfirmationInvoice'));

function App() {
    useEffect(() =>{
        initializeSentry()
    },[])

    return (
        <Suspense fallback={<div>Loading</div>}>
        <Router history={history}>
            <ReactNotifications />
            <NotifBar />
            <Switch>
                <Route path="/help" component={HelpPage} />
                <Route path="/profile" component={ProfilePage} />
                <Route path="/products/create-or-update" component={CreateOrUpdateProductsPage} />
                <Route path="/products/find" component={FindProductsPage} />

            {/*Agents*/}
                <Route path="/agents/create" component={CreateAgentsPage} />
                <Route path="/agents/update" component={UpdateAgentsPage} />
                <Route path="/agents/list" component={ListAgentsPage} />
                <Route path="/agents/commissions" component={ManageAgentCommissionPage} />
                <Route path="/agents/commission-groups" component={CommissionGroupPage}/>
                <Route path="/agents/commission-groups-logs" component={ListOperationLogCommissionGroupPage}/>
                <Route path="/agents/commission-groups-create" component={CreateCommissionGroupPage}/>
                <Route path="/agents/commission-schemes" component={CommissionSchemePage}/>
                <Route path="/agents/commission-schemes-logs" component={ListOperationLogAgentTypeCommissionPage}/>
                <Route path="/agents/order-summary" component={OrderSummaryPage} />
                <Route path="/agents/feature-flags" component={AgentFeatureFlagsPage} />
                <Route path="/agents/workdays" component={AgentWorkdayListPage} />
                <Route path="/agents/bulk-update" component={BulkUpdatePage} />
                <Route path="/agents/bulk-update-logs" component={ListOperationLogPage} />
                <Route path="/agents/change-logs/:id" component={ListUpdateAgentChangeLogPage} />

                {/* Agent Transaction */}
                <Route path="/agents/transactions" component={ListAgentTransactionPage}/>

                {/* Agent Balance */}
                <Route path="/agents/balances/detail" component={AgentBalanceDetailPage}/>
                <Route path="/agents/balances" component={AgentBalanceDashboardPage}/>

                {/*Agent Slugs*/}
                <Route path="/agents/slugs/create" component={CreateAgentSlugsPage} />
                <Route path="/agents/slugs/list" component={ListAgentSlugsPage} />
                <Route path="/agents/slugs/delete" component={DeleteAgentSlugsPage} />

                {/*Orders*/}
                <Route path="/orders/list" component={ListOrderPageV2} />
                {/*replaced with /orders/list termination date May 30, 2020 */}
                <Route path="/legacy/orders/list" component={DeprecatedPage} />
                <Route path='/orders/manage' component={ManageOrderPage} />
                <Route path="/orders/confirm" component={DeprecatedPage} />
                <Route path='/orders/cancel-unpaid-orders' component={CancelUnpaidOrdersPage} />
                <Route path='/orders/notify-out-of-stock' component={NotifyOutOfStockPage} />
                <Route path='/orders/limit/same-day' component={OrderLimitSameDayPage} />
                <Route path='/orders/limit' component={OrderLimitNextDayPage} />
                <Route path='/orders/order-delivery/live-courier' component={CourierLiveLocationPage} />
                <Route path='/orders/order-delivery' component={OrderDeliveryPage} />
                <Route path='/orders/address-verification' component={AddressVerificationPage} />
                <Route path='/orders/refunds' component={RefundListPage} />
                <Route path='/orders/cod-payment' component={CODPaymentPage} />
                <Route path='/orders/drop-order-metrics' component={DropOrderMetricsPage} />
                <Route path='/orders/blacklist-params' component={ManageOrderBlacklistParamPage} />
                <Route path='/orders/address-reminder' component={ManageAddressReminderPage} />
                <Route path='/orders/fraud-prevention-config' component={ManageOrderFraudPageConfig} />
                <Route path='/orders/:id' component={OrderDetailPage} />

                <Route path="/order-products/list" component={ListOrderProductsPage} />
                <Route path="/order-products/update" component={UpdateOrderProductsPage} />
                <Route path="/order-products/summary" component={OrderProductSummaryPage} />
                <Route path="/order-products/bulk-add-freebie" component={BulkAddFreebiePage} />

                {/*Price Groups*/}
                <Route path="/price-groups/create" component={CreatePriceGroupsPage} />
                <Route path="/price-groups/update" component={UpdatePriceGroupsPage} />
                <Route path="/price-groups/list" component={ListPriceGroupsPage} />

                {/*Price Group Products*/}
                <Route path="/price-group-products/configure" component={ConfigurePriceGroupProductPage} />
                <Route path="/price-group-products/create" component={CreatePriceGroupProductsPage} />
                <Route path="/price-group-products/update" component={UpdatePriceGroupProductsPage} />
                <Route path="/price-group-products/list" component={ListPriceGroupProductsPage} />
                <Route path="/price-group-products/delete" component={DeletePriceGroupProductsPage} />
                <Route path='/price-group-products/copy' component={CopyPriceGroupProductsPage} />
                <Route path='/price-group-products/pricing-scheduler' component={PricingSchedulerPage} />

                <Route path='/product-catalog-promos/automated' component={AutomatedFlashSalePage} />
                <Route path='/product-catalog-promos/create' component={CreateManualCatalogPromoPage} />
                <Route path='/product-catalog-promos/configure' component={ConfigureManualCatalogPromoPage} />

                {/*Products*/}
                <Route path="/products/create-or-update-log" component={CreateOrUpdateProductLogPage}/>
                <Route path="/products/list" component={ProductListPage} />
                <Route path="/products/price/list" component={ListProductsWithPricePage} />
                <Route path={'/products/order-limits/triggers'} component={AutoPolTriggerConfigurationsPage}/>
                <Route path="/products/order-limits" component={ProductOrderLimitsPage} />
                <Route path="/products/keywords" component={ProductKeywordsPage} />
                <Route path="/products/universal-barcodes" component={UniversalBarcodeCSVUploadForm}/>
                <Route path={'/products/qr-label'} component={QrLabelPage} />
                <Route path={'/products/auto-pol'} component={AutoPolPage}/>
                <Route path="/promo-labels" component={ManagePromoLabelPage}/>
                <Route path={"/products/label-group"} component={LabelGroupListPage}/>
                <Route path={'/promo-quota/configure'} component={ConfigurePromoQuotaPage}/>

                {/*Purchase UoM*/}
                <Route path="/products/purchase-uom/list" component={PurchaseUoMListPage} />
                <Route path="/products/purchase-uom/create" component={PurchaseUoMCreatePage} />

                {/*Items*/}
                <Route path="/items/create-or-update" component={CreateOrUpdateItemsPage}/>
                <Route path="/items/bundle" component={ProductBundlePage}/>

                {/*Purchasing Product*/}
                <Route path="/purchasing-products/list" component={ListPurchasingProductsPage} />
                <Route path="/purchasing-products/create" component={CreatePurchasingProductsPage} />
                <Route path="/purchasing-products/update" component={UpdatePurchasingProductsPage} />

                {/*Purchase Order*/}
                <Route path="/purchase-orders/list" component={ListPurchaseOrderPage} />
                <Route path="/purchase-orders/details/:id" component={CreateOrUpdatePurchaseOrderPage} />
                <Route path="/purchase-orders/create" component={CreateOrUpdatePurchaseOrderPage} />

                {/*Purchase Return*/}
                <Route path="/purchase-return-requests/list" component={PurchaseReturnRequestPage} />
                <Route path="/purchase-returns/list" component={ListPurchaseReturnPage} />
                <Route path="/purchase-returns/details/:id" component={PurchaseReturnDetailPage} />

                {/*Internal Shipments*/}
                <Route path="/internal-shipments/create" component={CreateInternalShipmentPage} />

                {/*Product to Purchasing Product Mapping*/}
                {/* Deprecated
                <Route path="/product-purchasing-products/list" component={ListProductPurchasingProductsPage} />
                <Route path="/product-purchasing-products/create" component={CreateProductPurchasingProductsPage} />
                <Route path="/product-purchasing-products/update" component={UpdateProductPurchasingProductsPage} />
                <Route path="/product-purchasing-products/delete" component={DeleteProductPurchasingProductsPage} />
                */}

                {/*Product Tag*/}
                <Route path="/product-tags/list" component={ProductTagsPage} />
                <Route path="/product-tags/create" component={CreateProductTagsPage} />
                <Route path="/product-tags/update" component={UpdateProductTagsPage} />
                <Route path="/product-tags/delete" component={DeleteProductTagsPage} />
                <Route path="/product-tags/info" component={ProductTagInfo}/>

                {/*Frontend Subcategory*/}
                <Route path="/frontend-subcategories/create" component={CreateFrontendSubcategoriesPage} />
                <Route path="/frontend-subcategories/list" component={ListFrontendSubcategoriesPage} />
                <Route path="/frontend-subcategories/update" component={UpdateFrontendSubcategoriesPage} />
                <Route path="/frontend-subcategories/delete" component={DeleteFrontendSubcategoriesPage} />
                <Route path="/frontend-subcategories/log" component={LogFrontendSubcategoriesPage} />

                {/*Frontend Category*/}
                <Route path="/frontend-categories/create" component={CreateFrontendCategoriesPage} />
                {/* <Route path="/frontend-categories/list" component={ListFrontendCategoriesPage} /> */}
                <Route path="/frontend-categories/update" component={UpdateFrontendCategoriesPage} />
                <Route path="/frontend-categories/delete" component={DeleteFrontendCategoriesPage} />
                {/* component below is deprecated due to new feature: Category Display Group */}
                {/* <Route path="/frontend-categories/sort" component={SortFrontendCategoriesPage} /> */}
                <Route path="/frontend-categories/log" component={LogFrontendCategoriesPage} />

                {/*Frontend Category Group*/}
                <Route path="/category-display-group" component={ListCDGPage} />
                <Route path="/create-category-display-group" component={CreateCDGPage} />
                <Route path="/create-category-display-group-category-landing-page" component={CreateCDGPageCLP} />
                <Route path="/create-category-display-group-directory-clp" component={CreateCDGPageDirectoryCLP} />
                <Route path="/edit-category-display-group" component={EditCDGPage} />
                <Route path="/edit-category-display-group-for-clp-and-directory-clp" component={EditCDGPageForCLPandDirectoryCLP} />

                {/*Promo*/}
                <Route path="/promos/list" component={ListPromosPage} />
                <Route path="/promos/create" component={CreatePromosPage} />
                <Route path="/promos/update" component={UpdatePromosPage} />
                <Route path="/promos/blacklist" component={PromoBlacklistPage} />
                <Route path="/promos/view" component={ViewPromosPage} />
                <Route path="/promos/sticky-voucher" component={ManageStickyVoucherPage} />

                <Route path="/promo-events/manage" component={ManagePromoEventsPage} />

                {/*User*/}
                <Route path='/users/list' component={ListUserPage} />

                {/* Deprecated, moved to /users/internal*/}
                {/* <Route path='/users/create' component={CreateUserPage} /> */}
                {/* <Route path='/users/update' component={UpdateUserPage} /> */}

                <Route path={'/roles/list'} component={ListRolePage} />

                <Route path={'/scopes/list'} component={ListScopePage} />

                <Route path={'/users/internal'} component={CreateOrUpdateInternalUserPage}/>
                {/*Shipping Point*/}
                <Route path="/shipping-point/create" component={CreateShippingPointPage} />
                <Route path="/shipping-point/update" component={UpdateShippingPointPage} />
                <Route path="/shipping-point/list" component={ListShippingPointsPage} />
                <Route path="/shipping-point/force-majeure/list" component={ForceMajeuresListPage}/>
                <Route path="/shipping-point/force-majeure" component={ForceMajeurePage} />
                <Route path="/shipping-point/global-config" component={ShippingPointGlobalConfigPage} />

                {/*Geofencing */}
                <Route path="/geofencing/check-location" component={CheckLocationCoveredByGeofencing} />

                {/*Warehouse*/}
                <Route path="/warehouses/list" component={ListWarehousesPage} />
                <Route path="/warehouses/create" component={CreateWarehousesPage} />
                <Route path="/warehouses/update" component={UpdateWarehousePage} />

                <Route path="/order-product-scans-v2" component={ListOrderProductScanV2Page} />
                <Route path="/warehouse-operating-time" component={UpdateWarehousesOperatingTimePage} />
                <Route path="/warehouse-handover-blacklist" component={WarehouseHandoverBlackListPage} />
                <Route path="/warehouse-default-delivery-sdd-ndd-proportion" component={WarehouseDefaultDeliveryProportionPage} />

                <Route path="/warehouse-planogram-whitelist" component={WarehousePlanogramWhitelistPage} />
                <Route path={"/manpower/database"} component={ManpowerDatabase}/>

                {/*Order Pipeline*/}
                <Route path="/order-pipelines/dashboard" component={OrderPipelineDashboardPage} />
                <Route path="/order-pipelines-v22/dashboard" component={OrderPipelineDashboardV22Page} />
                <Route path="/order-pipelines/log" component={OrderPipelineLogPage} />
                <Route path="/order-pipelines-v22/log" component={OrderPipelineLogV22Page} />
                <Route path="/order-pipelines/outbound-checkpoint" component={WarehouseOutboundCheckpointPage} />
                <Route path="/order-pipelines/recalculate" component={RecalculateOrderPipelinesPage} />

                <Route path="/fulfillment/picking" component={FulfillmentPickingDashboardHome}/>
                <Route path="/fulfillment/picking-dashboard" component={FulfillmentPickingDashboard} />
                <Route path="/fulfillment/picking-orders" component={FulfillmentPickingOrder} />
                <Route path="/fulfillment/picking-containers" component={FulfillmentPickingContainer} />
                <Route path="/fulfillment/picking-details" component={FulfillmentPickingDetail} />
                <Route path="/fulfillment/picking-timestamp" component={FulfillmentPickingTimestamp} />
                <Route path="/fulfillment/picking-edit" component={FulfillmentPickingEdit} />
                <Route path="/fulfillment/picker-dashboard" component={FulfillmentPickingPickerDashboard} />
                <Route path="/fulfillment/picking-repick" component={FulfillmentPickingRepick} />
                <Route path="/fulfillment/picking-oos" component={FulfillmentPickingOOS} />
                <Route path="/fulfillment/oos-quota" component={FulfillmentOOSQuota} />
                {/*Freebie Promo*/}
                <Route path="/freebie-promos" component={ManageFreebiePromoPage} />
                <Route path="/selectable-freebie-promos" component={ManageSelectableFreebiePromoPage} />

                {/*Negative Price*/}
                {/* <Route path="/negative-price" component={ManageNegativePricesPage} /> */}

                {/*Harga Gila*/}
                <Route path="/harga-gila" component={ManageHargaGilaPage} />

                {/*Inventory Management*/}
                <Route path={"/inventory-check"} component={ManageInventoryCheckPage} />

                <Route path={"/gr-payments"} component={ListGrPaymentPage} />

                <Route path={"/inbounds/list"} component={ListInboundPage} />
                <Route path={"/inbounds/:id"} component={BulkUploadInboundPage} />

                <Route path={"/outbounds"} component={OutboundPage} />

                <Route path={"/suppliers/list"} component={ListSupplierPage} />
                <Route path={"/suppliers-onboarding"} component={ListSupplierOnboardingPage} />
                <Route path={"/suppliers-onboarding-warehouse-management"} component={WarehouseManagementPage} />

                <Route path={"/quality-control-metrics"} component={QualityControlMetricsPage} />

                <Route path={"/conversion-template"} component={ConversionTemplatePage} />
                <Route path={"/conversion"} component={ConversionPage} />

                <Route path={"/purchasing-analytics-metrics"} component={PurchasingAnalyticsMetricsPage} />

                <Route path={'/warehouse-transfers'} component={ListWarehouseTransferPage} />
                <Route path={"/categories"} component={WarehouseTransferDashboard}/>
                <Route path={'/warehouse-planograms'} component={WarehousePlanogramPage}/>
                <Route path={'/warehouse-planogram-item-rules'} component={WarehousePlanogramItemRulesPage}/>
                <Route path={'/inventory-adjustment'} component={ListInventoryAdjustmentPage} />
                <Route path={"/inventory-adjustment-document-scheduler"} component={ListInventoryAdjustmentDocumentSchedulerPage} />
                <Route path={"/handover-inventory"} component={ListRecipientInventoryPage} />
                <Route path={"/defect-fc"} component={ListInventoryFulfillmentDefectPage} />
                <Route path={"/demand-stock-pc"} component={ListInventoryProductionRestockPage}/>
                <Route path={"/transfer-stock-level"} component={ListForecastSDDPage} />
                <Route path={"/forecast-item-rules/"} component={ForecastItemRuleListPage} />

                <Route path={'/inventory-dashboard/planogram'} component={InventoryPlanogramDashboardV3Page} />
                <Route path={'/inventory-dashboard'} component={InventoryDashboardPage} />

                <Route path={'/general-picklist'} component={GeneralPickListPage} />
                <Route path={'/multi-batch-picklist'} component={MultiBatchPickListPage} />
                <Route path={'/aging-planogram-dashboard'} component={AgingPlanogramDashboard} />
                <Route path={"/gramasi-item"} component={ListItemDimensionPage} />
                <Route path={'/inventory-planogram-rules'} component={InventoryServicePlanogramRulesPage} />
                <Route path={"/goods-movement-dashboard"} component={GoodsMovementDashboardv2Page} />
                <Route path={"/item-location-route"} component={ItemLocationRoutePage} />
                <Route path={"/item-master-data-sku-bulk"} component={GeneralPickListItemBulkPage} />
                <Route path={"/stock-opname-scheduler"} component={ListStockOpnameSchedulerPage} />
                <Route path={"/stock-opname-document-secret-code"} component={InventoryAdjustmentDocumentSecretCodePage}/>
                <Route path={"/transfer-allocator-dashboard"} component={TransferAllocatorDashboardPage} />

                {/* Courier */}
                <Route path={"/couriers/dashboard"} component={CourierDashboardPage}/>
                <Route path={"/couriers/:id/dashboard"} component={CourierDashboardPage}/>
                <Route exact path={"/couriers/:id/history"} component={CourierUpdateHistoryPage}/>
                <Route exact path={"/couriers/fees/history/:id"} component={CourierFeeHistoryPage}/>
                <Route path={"/couriers/fees"} component={CourierFeeListPage} />
                <Route path={"/couriers/check"} component={CourierCheckListPage} />
                <Route path={"/couriers/kyc"} component={CourierKycListPage} />
                <Route path={"/couriers"} component={CourierListPage} />

                {/*Whatsapp Page*/}
                <Route path={'/whatsapp/templates'} component={ListTemplatePage} />
                <Route path={'/whatsapp/notify-delivery-invoice'} component={NotifyDeliveryInvoicePage} />
                <Route path={'/whatsapp/outbound-log'} component={OutboundLogPage} />
                <Route path='/login' component={LoginPage} />
                <Route path='/logout' component={LogoutPage} />
                <Route path='/version' component={VersionPage} />

                {/* Whitelist */}
                <Route path={"/price-limit-whitelist"} component={TotalPriceLimitWhiteListPage} />

                {/* */}
                {/*<Route path={'/shopeepay/list'} component={ShopeepayListPage}/>*/}

                {/*Banner*/}
                <Route path={'/manage-banners'} component={ManageBannerPage}/>

                {/*Account Page Banner*/}
                <Route path={'/manage-account-page-banners'} component={ManageAccountPageBannerPage}/>

                {/*Popup*/}
                <Route path={'/popups'} component={PopupsPage}/>

                {/*Referral*/}
                <Route path={'/referral/update-value'} component={ReferralUpdateValuePage}/>
                <Route path={'/referral/update-banner'} component={ManageReferralBannerPage}/>
                <Route path={'/referral/manage-programs'} component={ManageProgramBannerPage}/>
                <Route path={'/referral/manage-referral-data-voucher-delivery'} component={ManageReferralDataAndVoucherDelivery}/>
                <Route path={'/referral-data/log'} component={ReferralDataLogPage}/>

                {/*Homepage Management*/}
                <Route path={'/homepage-management/thematic-widget'} component={ThematicWidgetManagementPage}/>
                <Route path={'/homepage-management/thematic-banner/:position'} component={ThematicBannerEditPage}/>
                <Route path={'/homepage-management/thematic-banner'} component={ThematicBannerManagementPage}/>
                <Route path={'/homepage-management/permanent-banner'} component={PermanentBannerManagementPage}/>
                <Route path={'/homepage-management/3-tabs/edit/:id'} component={HomepageTabEditPage}/>
                <Route path={'/homepage-management/3-tabs'} component={HomepageTabManagementPage}/>
                <Route path={'/homepage-management/infinite-scroll-carousel-banner'} component={CarouselBannerManagementPage}/>
                <Route path={'/homepage-management/carousel-banner-create'} component={CarouselBannerCreateAndUpdatePage}/>
                <Route path={'/homepage-management/carousel-banner-edit/:bannerId'} component={CarouselBannerCreateAndUpdatePage}/>
                <Route path={'/homepage-management/carousel-banner-view/:bannerId'} component={CarouselBannerCreateAndUpdatePage}/>

                {/*Fulfillment Configuration*/}
                <Route path={'/order-queue-configuration'} component={OrderQueueHomePage} />
                <Route path="/fulfillment/process-configuration/order-queue" component={FulfillmentOrderQueueSDD} />
                <Route path="/fulfillment/process-configuration/packer-validation" component={PackerValidationConfig} />
                <Route path="/fulfillment/packages" component={FulfillmentPackage} />
                <Route path="/fulfillment/external-order-input" component={ExternalOrderInputPage} />

                {/* Segari Coin Transaction */}
                <Route path={'/segari-coin-transaction/list'} component={SegariCoinTransactionsList}/>
                <Route path={'/segari-coin-transaction/create'} component={CreateSegariCoinTransaction}/>

                {/* Seo Page Management */}
                <Route path={'/seo-page/category'} component={SeoCategoryPage}/>
                <Route path={'/seo-page/sub-category'} component={SeoSubCategoryPage}/>
                <Route path={'/seo-page/product'} component={SeoProductPage}/>
                {/* Web-Only Campaign */}
                <Route path={'/campaign/create-or-edit'} component={CreateOrEditCampaignPage}/>
                <Route path={'/campaign/configuration'} component={CampaignConfigurationPage}/>
                <Route path={'/campaign/detail/:campaignId'} component={CampaignDetailPage} />

                {/* SDD Auto */}
                <Route path={'/sdd-auto-dashboard/order-deliveries/:id'} component={SddOrderDeliveryDetailPage}/>
                <Route path={'/sdd-auto-dashboard/order-deliveries'} component={SddOrderDeliveryRevampPage}/>
                <Route path={'/sdd-auto-dashboard/assignment-metrics'} component={SDDAutoMetricsPage}/>
                <Route path={'/sdd-auto-dashboard/reassignment-cancellations'} component={SddAutoDashboardMainPage}/>
                <Route path={'/sdd-auto-dashboard/force-majeure/impacted-orders'} component={ForceMajeureImpactedOrders}/>
                <Route path={"/sdd-auto-dashboard/punishment-module/type"} component={CourierPunishmentTypePage} />
                <Route path={"/sdd-auto-dashboard/punishment-module"} component={CourierPunishmentPage} />
                <Route path={"/sdd-auto-dashboard/districts-sla"} component={DistrictsSlaPage} />
                <Route path={"/sdd-auto-dashboard/automate-notification"} component={AutomateNotificationPage} />
                <Route path={"/sdd-auto-dashboard/sdd-courier-fee-config"} component={SddCourierFeeConfigPage} />
                <Route path={"/sdd-auto-dashboard/courier-availability"} component={CourierAvailabilityPage} />

                {/*Product Suggestions*/}
                <Route path={'/product-suggestions/list'} component={ListProductSuggestionPage}/>

                {/* Recipe Management */}
                <Route path={'/recipe/create-or-update'} component={ManageRecipePage}/>
                <Route path={'/recipe/curated'} component={ManageCuratedRecipePage}/>
                <Route path={'/recipe/detail/update-asset'} component={UpdateRecipeDetailAssetPage}/>
                <Route path={'/recipe/suggestions/list'} component={ListRecipeSuggestionsPage}/>

                {/* Recipe Tag Management */}
                <Route path={'/recipe/tags/create'} component={CreateRecipeTagsPage}/>
                <Route path='/recipe/tags/delete' component={DeleteRecipeTagsPage} />
                <Route path='/recipe/tags/info' component={RecipeTagInfo} />
                <Route path='/recipe/tags/update' component={UpdateRecipeTagsPage} />
                <Route path={'/recipe/tags'} component={RecipeTagsPage}/>


                {/* Landing Page Management */}
                <Route path={'/landing-page/list'} component={ManageLandingPage}></Route>
                <Route path={'/landing-page/create-update/:id'} component={CreateUpdateLandingPage}></Route>
                <Route path={'/landing-page/create-update/'} component={CreateUpdateLandingPage}></Route>
                <Route path={'/landing-page/duplicate/:duplicateId'} component={CreateUpdateLandingPage}></Route>
                
                {/* Cross/Up-selling Product Widget */}
                <Route path={'/cross-up-selling/manage-product-curation'} component={CrossUpSellingProductCurationPage}/>
                <Route path={'/cross-up-selling/create'} component={CrossUpSellingProductCurationCreatePage}/>
                <Route path={'/cross-up-selling/edit/:groupId'} component={CrossUpSellingProductCurationCreatePage}/>
                <Route path={'/cross-up-selling/log'} component={CrossUpSellingProductCurationLogPage}/>

                {/* Manpower Dashboard */}
                <Route path={'/fulfillment/manpower'} component={ManpowerDashboardHome}/>
                <Route path={'/fulfillment/manpower-productivity'} component={ManpowerProductivityPage}/>
                <Route path={'/fulfillment/manpower-picking'} component={ManpowerPickingPage}/>
                <Route path={'/fulfillment/manpower-checking'} component={ManpowerCheckingPage}/>

                {/*Product Labels*/}
                <Route path={'/product-labels/list'} component={ListProductLabelsPage} />
                <Route path={'/product-labels/create'} component={CreateProductLabelsPage} />
                <Route path={'/product-labels/update'} component={UpdateProductLabelsPage} />
                <Route path={'/product-labels/log'} component={LogProductLabelsPage} />

                {/*Product Variant*/}
                <Route path={'/product-variant/manage/:id'} component={CreateProductVariantPage}/>
                <Route path={'/product-variant/manage'} component={CreateProductVariantPage}/>
                <Route path={'/product-variant'} component={ProductVariantPage}/>

                {/*Bubul Pricing*/}
                <Route path={'/bubul-pricing/bulk-upload'} component={BulkUploadBubulPage}/>
                <Route path={'/bubul-pricing'} component={BubulPricingPage}/>

                {/* Sales Forecast PPIC */}
                <Route path={'/sales-forecast/list'} component={SalesForecastListPage} />
                <Route path={'/forecast-baseline/list'} component={ForecastBaselineListPage} />

                <Route path={'/production-maximum'} component={ProductionMaximumListPage} />
                <Route path={'/production-manpower-adjustment'} component={ProductionManpowerAdjustmentListPage} />
                
                {/* Supply Schedule PPIC */}
                <Route path={'/supply-lead-time/create'} component={SupplyLeadTimeCreatePage} />
                <Route path={'/supply-lead-time/list'} component={SupplyLeadTimeListAndUpdatePage} />
                <Route path={'/supply-delivery-schedule/create'} component={SupplyDeliveryScheduleCreatePage} />
                <Route path={'/supply-delivery-schedule/list'} component={SupplyDeliveryScheduleListAndUpdatePage} />

                {/* Supply Schedule PPIC */}
                <Route path={'/stock-availability/stock-group/read-me'} component={StockGroupReadMePage} />
                <Route path={'/stock-availability/stock-group/mappings'} component={StockGroupMappingsPage} />

                {/* Raw Material Request */}
                <Route path={'/raw-material-request/list'} component={RawMaterialRequestListPage} />

                {/*Category*/}
                <Route path="/category/keywords" component={categoryKeywordsPage} />

                {/*Payment Method Configuration*/}
                <Route path={'/payment-method-configuration/manage-availability'} component={ManagePaymentMethodPage}/>

                {/* Item Draft */}
                <Route path={'/item-draft/configure'} component={CreateItemDraftLandingPage} />

                {/*Payment Cap Config*/}
                <Route path="/payment-cap-config" component={ManagePaymentCapConfigPage} />

                {/*Search Autocomplete*/}
                <Route path={'/search-autocomplete'} component={SearchAutocompletePage}/>

                {/*Push Notification*/}
                <Route path={'/push-notifications/image-uploader'} component={PushNotificationImageUploaderPage}/>

                {/*Special*/}
                <Route path="/special/keywords" component={specialKeywordsPage} />

                {/*Subscriptions*/}
                <Route path="/subscription/tiers" component={ManageSubscriptionTierPage} />
                <Route path="/subscription/vouchers" component={ManageSubscriptionVoucherPage} />
                <Route path="/subscription/option-settings" component={ManageSubscriptionOptionSettingPage} />
                <Route path="/subscription/terms-and-conditions" component={ManageSubscriptionTermsAndConditionsPage} />
                <Route path="/subscription/payment-methods" component={ManageSubscriptionPaymentMethodConfigPage} />
                <Route path="/subscription/global-slogan" component={ManageSubscriptionGlobalSloganPage} />

                {/*Order Fulfillment*/}
                <Route path={'/fulfillment/dashboard'} component={FulfillmentDashboard}/>
                <Route path={'/fulfillment/return-inbound/dashboard'} component={ReturnInboundDashboard}/>

                {/*Midmile Transfer App*/}
                <Route path={'/midmile-transfer/master-data'} component={MidmileTransferMasterDataPage} />
                <Route path={'/midmile-transfer/dashboard'} component={MidmileTransferDashboardPage} />

                {/*Product Bulking*/}
                <Route path={'/product-bulking'} component={ProductBulkingPage}/>

                {/*Customer Rule Builder*/}
                <Route path={'/customer-rule-builder/campaigns/:campaignId/rules'} component={RuleBuilderCampaignDetailPage} />
                <Route path={'/customer-rule-builder/campaigns/:campaignId'} component={RuleBuilderCampaignDetailPage} />
                <Route path={'/customer-rule-builder/campaigns'} component={ManageCampaignPage} />
                <Route path={'/customer-rule-builder/audiences/:audienceId'} component={RuleBuilderAudienceDetailPage} />
                <Route path={'/customer-rule-builder/audiences'} component={ManageAudiencePage} />
                <Route path={'/customer-rule-builder/customer-profiles/:integrationId'} component={RuleBuilderCustomerProfileDetailPage} />
                <Route path={'/customer-rule-builder/customer-profiles'} component={ManageCustomerProfilePage} />
                <Route path={'/customer-rule-builder'} component={ManageCampaignPage} />

                <Route path={'/quality-control/customer-rating-feedback'} component={QcCustomerFeedBack}/>
                <Route path={'/quality-control/complaints-and-other-findings'} component={QcComplaintsAndOtherFindings}/>

                {/* Rafaksi */}
                <Route path={'/rafaksi/manage-supplier-promo-confirmation/:documentId/:documentVersion'} component={CreateUpdateSupplierPromoConfirmationPage} />
                <Route path={'/rafaksi/manage-supplier-promo-confirmation/:documentId'} component={CreateUpdateSupplierPromoConfirmationPage} />
                <Route path={'/rafaksi/supplier-promo-confirmation'} component={SupplierPromoConfirmationDashboardPage} />
                <Route path={'/rafaksi/manage-supplier-promo-confirmation'} component={CreateUpdateSupplierPromoConfirmationPage} />
                <Route path={'/rafaksi/sell-out'} component={SellOutSupplierPromoConfirmationDashboardPage} />
                <Route path={'/rafaksi/invoices'} component={InvoiceSupplierPromoConfirmationDashboardPage} />
                <Route path={'/rafaksi/create-invoice'} component={CreateSupplierPromoConfirmationInvoicePage} />
                <Route component={PageNotFound} />
            </Switch>
        </Router>
        </Suspense>
    );
}

function PageNotFound() {
    return <p>Page not Found!</p>
}

const loadgmaps = function () {
    window.onMapReady = function () {
        store.dispatch(setMapReady({ mapReady: true }))
    }

    const script = document.getElementById('googleMaps')
    script.async = true
    script.defer = true
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_API_KEY}&libraries=places&callback=onMapReady&map_ids=d37a665b83c8be30&language=id`
}
window.onload = loadgmaps()

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ReactNotifications />
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();