import React, { useState, useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import styles from "./style.module.scss";
import "./style.module.scss"
import * as shipmentApi from "../../../../apis/shipment.js";
import ShipmentDetailWarehouseGroupConfigurationModal from './ShipmentDetailWarehouseGroupConfigurationModal.js';
import { FaTrash } from 'react-icons/fa';
import { Plus } from 'react-bootstrap-icons';

const _ = require('lodash');
const ShipmentDetailWarehouseGroupConfigurationSection = (props) => {
    const { selectedShipmentId, shipmentData, displayAlert } = props;
    const [ shipmentWarehouseGroups, setShipmentWarehouseGroups ] = useState([]);
    const [ showWarehouseGroupConfigurationModal, setShowWarehouseGroupConfigurationModal ] = useState(false);
    
    useEffect(() => {
        fetchShipmentWarehouseGroups(selectedShipmentId);
    }, [selectedShipmentId]);


    const fetchShipmentWarehouseGroups = async () => {
        try {
            const warehouseGroupData = (await shipmentApi.getShipmentWarehouseGroups(selectedShipmentId)).data.data;

            setShipmentWarehouseGroups(warehouseGroupData);
        } catch (e) {
            displayAlert({ message: e.message, type: 'error'});
        }
    }

    const deleteShipmentWarehouseGroup = async (warehouseGroupId) => {
        try {
            shipmentApi.deleteShipmentWarehouseGroup(warehouseGroupId);
        } catch (e) {
            displayAlert({ message: e.message, type: 'error'});
        }

        fetchShipmentWarehouseGroups(selectedShipmentId);
    }

    const renderWarehouseGroupRow = (data) => {
        return <Row className='ml-1 border rounded pt-4 pl-4 p-3 mb-2'>
            <Col sm={2}>
                <Row className='text-secondary mb-2' style={{fontSize: '12px'}}>
                    <b> ID </b>
                </Row>
                <Row style={{fontSize: '12px'}}>
                    { data.id }
                </Row>
            </Col>
            <Col sm={3}>
                <Row className='text-secondary mb-2' style={{fontSize: '12px'}}>
                    <b> ZONA PICKING </b>
                </Row>
                <Row style={{fontSize: '12px'}}>
                    { data.zone }
                </Row>
            </Col>
            <Col sm={6}>
                <Row className='text-secondary mb-2' style={{fontSize: '12px'}}>
                    <b> WAREHOUSE TUJUAN </b>
                </Row>
                <Row style={{fontSize: '12px'}}>
                    { data.warehouses }
                </Row>
            </Col>
            <Col sm={1}>
                <Row>
                    <button type='button' className='btn btn-outline-danger' onClick={(target) => deleteShipmentWarehouseGroup(data.id)}>
                        <FaTrash/>
                    </button>
                </Row>
            </Col>
        </Row>
    }

    const toggleShipmentDetailWarehouseGroupConfigurationModal = () => {
        setShowWarehouseGroupConfigurationModal(!showWarehouseGroupConfigurationModal);
    }
    
    return (
        <>
            <Row>
                <Col sm={8}>
                    <Row className='mb-4'>
                        <Col>
                            <div className='mt-4' style={{fontSize: '1.125rem'}}>
                                <b>Pengaturan Warehouse Group</b>
                            </div>
                            <div className='mt-1' style={{fontSize: '0.875rem'}}>
                                Mengatur grup warehouse, jika memilih pembuatan secara manual, warehouse yang belum memiliki group tidak akan memiliki picklist.
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {
                                shipmentWarehouseGroups.map((e) => renderWarehouseGroupRow(e))
                            }
                        </Col>
                    </Row>
                    <Row className='pb-3'>
                        <Col>
                            <button type={'button'} className={`btn ${styles.btnOk} mt-4 ml-1 d-flex align-items-center`} onClick={(target) => toggleShipmentDetailWarehouseGroupConfigurationModal()}> <Plus className='mr-1' size={'1.25rem'}/> Tambah</button>
                        </Col>
                    </Row>

                    <ShipmentDetailWarehouseGroupConfigurationModal isShow={showWarehouseGroupConfigurationModal} onClose={toggleShipmentDetailWarehouseGroupConfigurationModal} selectedShipmentId={selectedShipmentId} displayAlert={displayAlert} fetchShipmentWarehouseGroups={fetchShipmentWarehouseGroups} warehouseSourceId={shipmentData.warehouseSourceId} />
                </Col>
                <Col sm={4} className='pl-5'>
                    <div className='mt-4' style={{fontSize: '1.125rem'}}>
                        <b>Dokumentasi</b>
                    </div>
                    <div className='mt-1' style={{fontSize: '0.75rem'}}>
                        Mengenai informasi dan cara penggunaan produk, dapat dipelajari lebih lanjut melalui <a href='https://doc.clickup.com/3688247/p/h/3ghtq-85856/55292fc418fb83d'>dokumentasi ini.</a>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default ShipmentDetailWarehouseGroupConfigurationSection;