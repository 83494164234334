import React from "react";
import Select, { components } from "react-select";

const MenuList = ({ children, ...props }) => {
    return (
      <components.MenuList {...props}>
        {Array.isArray(children) && !!props.selectProps.maxOptions
          ? children.slice(0, props.selectProps?.maxOptions)
          : children}
      </components.MenuList>
    );
  };

const ReactSelectForm = ({
  customStyles,
  defaultValue,
  fieldName,
  isClearable,
  isDisabled,
  isMulti,
  onChangeFunction,
  options,
  placeholder,
  setFieldLabel,
  setFieldValue,
  value,
  maxOptions,
  customIcon,
  hideOnSelect = true,
  menuPosition
}) => {

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {customIcon ? customIcon : <components.DownChevron />}
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <Select
      onChange={(current, action) => {
        if (onChangeFunction) onChangeFunction(current?.value, fieldName);
        if (setFieldValue) {
          if (!current) {
            setFieldValue(fieldName, "");
            return;
          }
          if (!Array.isArray(current)) {
            setFieldValue(fieldName, current.value);
          } else {
            let currentList = [];
            current.forEach((c) => currentList.push(c?.value));
            setFieldValue(fieldName, currentList);
          }
        }
        if (setFieldLabel) {
          const labels = new Set();
          current.forEach((c) => labels.add(c?.label));
          setFieldLabel(labels);
        }
      }}
      styles={{
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        ...customStyles,
      }}
      value={value}
      placeholder={placeholder}
      components={{
        DropdownIndicator,
        MenuList,
        IndicatorSeparator: () => null,
      }}
      options={options}
      isClearable={isClearable ?? false}
      isMulti={isMulti ?? false}
      isDisabled={isDisabled ?? false}
      defaultValue={defaultValue}
      maxOptions={maxOptions}
      hideSelectedOptions={hideOnSelect}
      closeMenuOnSelect={hideOnSelect}
      menuPosition={menuPosition}
    />
  );
};

export default ReactSelectForm;
